/* Breadcrumb link styles */
#pmContent {
    ul.breadcrumbs {
        li {
            display: block;
            float: left;
            font-size: 0.857rem;

            a {
                color: $colour-gray-dark;
                text-decoration: underline;
            }

            .crumb-arrow {
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 6px;
                border-color: $colour-transparent $colour-transparent $colour-transparent $colour-gray-dark;
                line-height: 0;
                _border-color: $colour-white $colour-white $colour-white $colour-gray-dark;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
                float: left;
                display: block;
                margin: 7px 5px 5px;
            }
        }

        margin: 30px 0 80px;
    }
}
