// Import bootstrap grid
$screen-sm-min: 1px; // Disable responsive grid
$screen-md-min: 1px; // Disable responsive grid
$screen-lg-min: 1px; // Disable responsive grid

:not(.pm-ignore-styles) > {
    @import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_grid";

    // Overwrite bootstrap grid selectors
    [class*="col-"] {
        box-sizing: border-box;
    }

    .container {
        padding: 0 15px;
        margin: 0 auto;

        &-dashboard {
            width: 910px !important;
        }
    }

    .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }
}
