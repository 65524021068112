#pmContent {
  .status-bubble {
    line-height: 3.571rem;
    margin: 0 15px 0 0;

    > span {
      border-radius: 100px;
      display: inline-block;
      height: 0.857rem;
      line-height: 1;
      padding: 5px 12px;
      font-size: 0.857rem;
      color: $colour-white;

      &.tertiary {
        background: $colour-gray;
        color: $colour-tundora;
      }

      &.warning {
        background: $colour-yellow;
      }

      &.success {
        background: $colour-fern-2;
      }
    }
  }
}
