#pmContent {
    /*
     * iSteven provided css for directive lib
     * https://github.com/isteven/angular-multi-select/blob/master/isteven-multi-select.css
     */

    [isteven-multi-select] {
        margin-top: 0;
        overflow: visible;

        &.ng-invalid.ng-dirty {
            .multiSelect > button {
                border: 1px solid $colour-chestnut !important;
            }
        }
    }

    /* ! vertical layout */
    .multiSelect .vertical {
        float: none;
    }

    .multiSelect label {
        width: auto !important;
        display: inline-block;
    }

    /* ! horizontal layout */
    .multiSelect .horizontal:not(.multiSelectGroup) {
        float: left;
    }

    /* ! create a "row" */
    .multiSelect .line {
        padding: 2px 0px 4px 0px;
        max-height: 30px;
        overflow: hidden;
        box-sizing: content-box;
    }

    /* ! create a "column" */
    .multiSelect .acol {
        display: inline-block;
        min-width: 12px;
    }

    /* ! */
    .multiSelect .inlineBlock {
        display: inline-block;
    }

    /* the multiselect button */
    .multiSelect > button:not(.pm-ignore-styles) {
        @extend .clearfix;

        @extend select;

        text-align: left;
        position: relative;
        height: auto;
        padding-left: 8px;
        padding-right: 18px;

        i {
            font-size: 0.714rem;
            position: absolute;
            right: 7px;
            top: 50%;
            margin-top: -5px;
        }
    }

    .multiselect {
        position: relative;
    }

    /* button: disabled */
    .multiSelect > button:disabled {
        background-image: linear-gradient(#fff, #fff);
        border: 1px solid #ddd;
        color: #999;
    }

    /* button: clicked */
    .multiSelect .buttonClicked {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    /* labels on the button */
    .multiSelect .buttonLabel {
        display: inline-block;
        padding: 0 !important;
        line-height: 1.429rem;
        margin-left: -4px;
    }

    /* downward pointing arrow */
    .multiSelect .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0px 0px 1px 12px  !important;
        vertical-align: middle;
        border-top: 4px solid #333;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-bottom: 0 dotted;
    }

    /* the main checkboxes and helper layer */
    .multiSelect .checkboxLayer {
        background-color: #fff;
        position: absolute;
        z-index: 999;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        min-width: 278px;
        display: none !important;
    }

    /* container of helper elements */
    .multiSelect .helperContainer {
        border-bottom: 1px solid #ddd;
        padding: 8px 8px 0px 8px;
    }

    /* helper buttons (select all, none, reset); */
    .multiSelect .helperButton {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        display: inline;
        text-align: center;
        cursor: pointer;
        border: 1px solid #ccc;
        height: 26px;
        font-size: 0.929rem;
        border-radius: 2px;
        color: #666;
        background-color: #f1f1f1;
        line-height: 1.6;
        margin: 0px 0px 8px 0px;
    }

    .multiSelect .helperButton.reset {
        float: right;
    }

    .multiSelect .helperButton:not(.reset) {
        margin-right: 4px;
    }

    /* clear button */
    .multiSelect .clearButton {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: absolute;
        text-align: center;
        cursor: pointer;
        border: 1px solid #ccc;
        height: 22px;
        width: 22px;
        font-size: 0.929rem;
        border-radius: 2px;
        background-color: #f1f1f1;
        margin-top: 2px;
        right: 4px;
        top: 4px;
        display: inline-block;
        overflow: hidden;
        line-height: 0.714rem;

        i {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            color: #666;
            margin-top: 1px;
            position: relative;
            display: inline-block;
            font: normal normal normal 14px / 1 FontAwesome;
            font-size: 0.714rem;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f00d";
        }
    }

    /* filter */
    div.input .multiSelect input[type="text"].inputFilter {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 2px;
        border: 1px solid #ccc;
        height: 26px;
        font-size: 13px !important;
        line-height: 1.857rem !important;
        width: 100%;
        padding-left: 7px;
        -webkit-box-sizing: border-box;

        /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;

        /* Firefox, other Gecko */
        box-sizing: border-box;

        /* Opera/IE 8+ */
        color: #666 !important;
        margin: 0px 0px 8px 0px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    /* helper elements on hover & focus */
    .multiSelect .clearButton:hover,
    .multiSelect .helperButton:hover {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border: 1px solid #ccc;
        background-color: #f4f4f4;

        &:before {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            color: #999;
        }
    }

    .multiSelect .helperButton:hover {
        color: #999;
    }

    .multiSelect .helperButton:disabled {
        color: #ccc;
        border: 1px solid #ddd;
    }

    .multiSelect .clearButton:focus,
    .multiSelect .helperButton:focus,
    .multiSelect .inputFilter:focus {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border: 1px solid #66AFE9 !important;
        outline: 0;
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.065), 0 0 5px rgba(102, 175, 233, 0.6) !important;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.065), 0 0 5px rgba(102, 175, 233, 0.6) !important;
    }

    /* container of multi select items */
    .multiSelect .checkBoxContainer {
        display: block;
        padding: 8px;
        overflow-y: scroll;
        max-height: 300px;
    }

    /* ! to show / hide the checkbox layer above */
    .multiSelect .show {
        display: block !important;
    }

    /* item labels */
    .multiSelect .multiSelectItem {
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        display: block;
        padding: 3px;
        color: #666;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        position: relative;
        min-width: 278px;
        min-height: 32px;
    }

    /* Styling on selected items */
    .multiSelect .multiSelectItem:not(.multiSelectGroup).selected {
        background-image: linear-gradient(#e9e9e9, #f1f1f1);
        color: #555;
        cursor: pointer;
        border-top: 1px solid #e4e4e4;
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #d9d9d9;
    }

    .acol label span {
        // form.base div.input .multiSelect .multiSelectItem .acol label {
        font-style: normal !important;
        color: #666;
        font-size: 0.929rem;
        line-height: 1.071rem;
        display: inline-block;
        padding-right: 30px;
        margin: 0px !important;
        font-weight: normal !important;
        line-height: normal !important;
    }

    /* item labels focus on mouse hover */
    .multiSelect .multiSelectItem:hover,
    .multiSelect .multiSelectGroup:hover {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-image: linear-gradient(#D5D5D5, #B9B9B9) !important;

        // color: #fff !important;
        cursor: pointer;
        border: 1px solid #ccc !important;
    }

    /* item labels focus using keyboard */
    .multiSelect .multiSelectFocus {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-image: linear-gradient(#D5D5D5, #B9B9B9) !important;

        //  color: #fff !important;
        cursor: pointer;
        border: 1px solid #DDDDDD !important;
    }

    /* change mouse pointer into the pointing finger */
    .multiSelect .multiSelectItem span:hover,
    .multiSelect .multiSelectGroup span:hover {
        cursor: pointer;
    }

    /* ! group labels */
    .multiSelect .multiSelectGroup {
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        background-image: linear-gradient(#fff, #fff) !important;
        display: block;
        clear: both;
    }

    /* right-align the tick mark (&#10004;) */
    .multiSelect .tickMark {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 0.786rem;
    }

    /* hide the original HTML checkbox away */
    .multiSelect .checkbox {
        color: #ddd !important;
        position: absolute;
        left: -9999px;
        cursor: pointer;
    }

    /* checkboxes currently disabled */
    .multiSelect .disabled,
    .multiSelect .disabled:hover,
    .multiSelect .disabled label input:hover ~ span {
        color: #c4c4c4 !important;
        cursor: not-allowed !important;
    }

    /* If you use images in button / checkbox label, you might want to change the image style here. */
    .multiSelect img {
        vertical-align: middle;
        margin-bottom: 0px;
        max-height: 22px;
        max-width: 22px;
    }
}
