#pmContent {
    table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 20px;
        font-size: 0.929rem;

        tr {
            border: 1px solid $colour-gray-light-1;
        }

        // @todo fix issue about table animation flicker borders getting black after animation.
        // issue: https://projects.twogether.io/issues/40605
        //    tr:nth-child(even) {
        //      background-color: $colour-table-row-odd;
        //    }

        tr:last-child {
            border-bottom: 1px solid $colour-gray-light-1;
        }

        td, th {
            border-left: 1px solid $colour-gray-light-1;
            border-right: 1px solid $colour-gray-light-1;
            padding: 0 10px;
            height: 48px;
            vertical-align: middle;
            text-align: left;
        }

        th {
            border-bottom: 1px solid $colour-gray-light-1;
            cursor: pointer;

            &.not-sortable {
                cursor: default;
            }

            .icon-arrow-up,
            .icon-arrow-down {
                float: right;
                margin-top: 3px;
            }
        }

        td.actions {
            &.one-small {
                width: 32px;
            }

            &.two-small {
                width: 65px;
            }
        }

        td.actions a {
            white-space: nowrap;
        }

        .before-info {
            border-right: 0px;
        }

        .info-cell {
            width: 26px;
            padding-left: 0px !important;
            padding-right: 10px !important;
            border-left: 0px;
        }

        .col-actions {
            width: 130px;

            a {
                color: $colour-white;
            }
        }

        .col-no-overflow {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .table-divider {
            background: #fff;
            border-top: 1px solid $colour-white;
            border-bottom: 1px solid $colour-white;
            width: 10px;
        }

        .button {
            font-weight: normal;
        }

        a {
            color: $colour-gray-dark;
            text-decoration: none;
            font-weight: bold;
        }

        /**
         * Specific table styles
         */
        &.list-table {
            clear: both;

            .col-name {
                width: 30%;
            }

            .col-leads, .col-broadcasts {
                width: 20%;
            }

            .list-table-data-with-tip {
                display: inline-block;
                margin-left: -5px;
                padding: 5px;
                cursor: default;
            }
        }

        &.user-list-table {
            .col-email {
                width: 35%;
            }
        }

        &.outer-borders-table {
            table-layout: auto;
            border: 1px solid $colour-gray-light-1;

            thead {
                tr {
                    background: $colour-table-row-odd;

                    th, td {
                        border: 0;
                        font-size: 1.143rem;
                    }
                }
            }

            tr {
                border: 0;

                td {
                    border: 0;
                }
            }
        }

        &.row-spacing {
            border-collapse: collapse;

            tbody tr {
                td {
                    padding-top: 5px;

                    &:first-child:before {
                        content: " ";
                        background: #fff;
                        position: absolute;
                        width: calc(100% + 1px);
                        height: 5px;
                        margin: -5px 0 0 -1px;
                        border-bottom: 1px solid $colour-gray-light-1;
                        z-index: 1;
                    }
                }
            }

            th, td {
                /*
                      border-left: 0 none;
                      &:first-child {
                        border-left: inherit;
                      }
                */
            }

            td {
                border-top: 1px solid $colour-gray-light-1;
                border-bottom: 1px solid $colour-gray-light-1;
                background-color: $colour-concrete;
            }
        }

        &.drag-n-drop {
            tbody td:first-child {
                padding-left: 0;

                .drag-spot {
                    line-height: 3.429rem;
                }
            }
        }

        &.site-settings-table {
            &.regular-cursor {
                th {
                    cursor: auto;
                }
            }
        }

        &.auto-layout {
            table-layout: auto;

            td[head-class="col-actions"] {
                white-space: nowrap;
            }
        }

        &.table-striped {
            tbody > tr {
                border: none;
            }

            tr:nth-child(even) {
                background-color: $colour-table-row-odd;
            }

            tr:last-child {
                border-bottom: 1px solid $colour-gray-light-1;
            }
        }
    }
}

.identifier-dashboard-table {
    tr {
        td {
            &:nth-child(1) {
                min-width: 225px !important;
                max-width: 225px !important;
            }
        }
    }
}

.identifier-reports-table {
    tr {
        td {
            overflow: hidden;
            position: relative;

            &:nth-child(1) {
                min-width: 225px !important;
                max-width: 225px !important;
            }

            &:nth-child(2) {
                min-width: 120px !important;
                max-width: 120px !important;
            }

            &:nth-child(3) {
                min-width: 120px !important;
                max-width: 120px !important;
            }

            &:nth-child(4) {
                min-width: 200px !important;
                max-width: 200px !important;
            }

            span {
                width: 100%;
                padding: 10px 0 !important;
                overflow: hidden;
                display: inline-block;
                padding: 10px;

                // vertical-align: middle;

            }
        }
    }
}

#pmContent table.system-languages-table {
    td {
        height: auto;
        vertical-align: top;
        padding: 11px 10px;

        .input {
            margin: -6px 0;
            padding: 0;

            .pm-input-icon {
              top: 8px;
            }
        }

        .tgl-btn {
          margin-bottom: 0;
        }

        .system-languages-table-na {
          padding-top: 2px;
          font-weight: bold;
          font-size: 1rem;
        }

        &.system-languages-table-name {
          padding-top: 12px;
        }

        &.system-languages-table-platform {
          width: 45%;
        }
    }
}

#pmContent table.currencies-table {
    &.table-striped tr:last-child {
        border: none;
    }
    tr {
        border: none;

        th {
            border-top: 1px solid $colour-gray-light-1;
        }

        &.error {
            td {
                padding-bottom: 40px;
            }
        }

        td {
            border: none;
            position: relative;
            padding-top: 10px;
            height: auto;

            .error-message {
                &.position-below {
                    position: absolute;
                    z-index: 1;
                }
            }

            &.currencyCountryCell {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .currencyCountry {
                    width: 80%;
                    float: left;
                    padding: 0;

                    [isteven-multi-select] {
                        margin-top: -6px;

                        .multiSelect > button:not(.pm-ignore-styles) {
                            line-height: 1.357rem;
                            min-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

#pmContent div.linear-table {
    margin-top: -9px;
    position: relative;

    .sv-placeholder {
        background-color: $colour-white !important;
        border: 1px dashed $colour-gray-light-1 !important;
        opacity: 1 !important;
        visibility: visible !important;
        margin-bottom: 10px;

        > * {
            visibility: hidden !important;
        }

        .top-line {
            margin: -1px 0;
        }
    }

    .line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        height: 45px;
        background-color: $colour-concrete;

        &.header {
            background-color: $colour-grey-green-light-two;
            margin-bottom: 10px;
        }

        .column {
            flex-grow: 3;
            flex-basis: 30%;
            border-right: 1px #d8d8d8 solid;
            height: 45px;
            line-height: 3.214rem;
            margin: auto;
            padding-left: 20px;

            &.middle {
                flex-basis: 60%;
            }

            &.full {
                flex-basis: 90%;
            }

            &.last {
                flex-grow: 1;
                flex-basis: 10%;
                border: none;
                width: 10%;
                text-align: center;
                padding: 0;

                &.one-action {
                    flex: 0 0 50px;
                }

                &.two-actions {
                    flex: 0 0 75px;
                }
            }

            &.ellipsis {
                position: relative;

                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    right: 20px;
                    left: 20px;
                    white-space: nowrap;
                }
            }

            a {
                color: $colour-gray-dark;
                text-decoration: none;

                &.secondary {
                    color: #FFFFFF;
                }
            }

            .drag-spot-neighbor {
                display: inline-block;
                position: absolute;
                padding-left: 20px;
            }

            .drag-spot {
                display: inline-block;
                height: 100%;
                width: 20px;
                font-size: 0.714rem;
                background-color: $colour-gray;
                position: relative;
                text-align: center;
                line-height: 3.214rem;
                cursor: -webkit-grab;
                cursor: -moz-grab;
                margin-left: -20px;
                top: -1px;

                &:after {
                    content: "\e622";
                    font-family: 'partnermarketing';
                    display: inline-block;
                    vertical-align: middle;
                }

                &.disabled {
                    width: 0;

                    &:after {
                        content: "";
                        display: none;
                    }
                }
            }
        }
    }
}

#pmContent div.client-sortable-custom-table {
  div.linear-table {
    .line {
      .column {
        a.drag-spot-neighbor {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 110px;
        }
      }
    }
  }
}

/**
 * Need to refactor this
 */

@mixin bootstrap-table-styling {
    .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid $colour-gray-light-1;
    }

    .table > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $colour-gray-light-1;
    }

    .table > caption + thead > tr:first-child > th,
    .table > colgroup + thead > tr:first-child > th,
    .table > thead:first-child > tr:first-child > th,
    .table > caption + thead > tr:first-child > td,
    .table > colgroup + thead > tr:first-child > td,
    .table > thead:first-child > tr:first-child > td {
        border-top: 0;
    }

    .table > tbody + tbody {
        border-top: 2px solid $colour-gray-light-1;
    }

    .table .table {
        background-color: $colour-white;
    }

    .table-condensed > thead > tr > th,
    .table-condensed > tbody > tr > th,
    .table-condensed > tfoot > tr > th,
    .table-condensed > thead > tr > td,
    .table-condensed > tbody > tr > td,
    .table-condensed > tfoot > tr > td {
        padding: 5px;
    }

    .table-bordered {
        border: 1px solid $colour-gray-light-1;
    }

    .table-bordered > thead > tr > th,
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > td {
        border: 1px solid $colour-gray-light-1;
    }

    .table-bordered > thead > tr > th,
    .table-bordered > thead > tr > td {
        border-bottom-width: 2px;
    }

    .table-striped > tbody > tr:nth-of-type(even) {
        background-color: $colour-alabaster;
    }

    .table-striped-not > tbody > tr:nth-of-type(odd) {
        background-color: $colour-transparent;
    }

    .table-striped-not > tbody > tr:nth-child(even) {
        background-color: $colour-transparent;
    }

    .table-hover > tbody > tr:hover {
        background-color: $colour-wild-sand;
    }

    table col[class*="col-"] {
        position: static;
        display: table-column;
        float: none;
    }

    table td[class*="col-"],
    table th[class*="col-"] {
        position: static;
        display: table-cell;
        float: none;
    }

    .table > thead > tr > td.active,
    .table > tbody > tr > td.active,
    .table > tfoot > tr > td.active,
    .table > thead > tr > th.active,
    .table > tbody > tr > th.active,
    .table > tfoot > tr > th.active,
    .table > thead > tr.active > td,
    .table > tbody > tr.active > td,
    .table > tfoot > tr.active > td,
    .table > thead > tr.active > th,
    .table > tbody > tr.active > th,
    .table > tfoot > tr.active > th {
        background-color: $colour-wild-sand;
    }

    .table-hover > tbody > tr > td.active:hover,
    .table-hover > tbody > tr > th.active:hover,
    .table-hover > tbody > tr.active:hover > td,
    .table-hover > tbody > tr:hover > .active,
    .table-hover > tbody > tr.active:hover > th {
        background-color: $colour-mercury;
    }

    .table > thead > tr > td.success,
    .table > tbody > tr > td.success,
    .table > tfoot > tr > td.success,
    .table > thead > tr > th.success,
    .table > tbody > tr > th.success,
    .table > tfoot > tr > th.success,
    .table > thead > tr.success > td,
    .table > tbody > tr.success > td,
    .table > tfoot > tr.success > td,
    .table > thead > tr.success > th,
    .table > tbody > tr.success > th,
    .table > tfoot > tr.success > th {
        background-color: $colour-peppermint;
    }

    .table-hover > tbody > tr > td.success:hover,
    .table-hover > tbody > tr > th.success:hover,
    .table-hover > tbody > tr.success:hover > td,
    .table-hover > tbody > tr:hover > .success,
    .table-hover > tbody > tr.success:hover > th {
        background-color: $colour-zanah;
    }

    .table > thead > tr > td.info,
    .table > tbody > tr > td.info,
    .table > tfoot > tr > td.info,
    .table > thead > tr > th.info,
    .table > tbody > tr > th.info,
    .table > tfoot > tr > th.info,
    .table > thead > tr.info > td,
    .table > tbody > tr.info > td,
    .table > tfoot > tr.info > td,
    .table > thead > tr.info > th,
    .table > tbody > tr.info > th,
    .table > tfoot > tr.info > th {
        background-color: $colour-link-water;
    }

    .table-hover > tbody > tr > td.info:hover,
    .table-hover > tbody > tr > th.info:hover,
    .table-hover > tbody > tr.info:hover > td,
    .table-hover > tbody > tr:hover > .info,
    .table-hover > tbody > tr.info:hover > th {
        background-color: $colour-mint-tulip;
    }

    .table > thead > tr > td.warning,
    .table > tbody > tr > td.warning,
    .table > tfoot > tr > td.warning,
    .table > thead > tr > th.warning,
    .table > tbody > tr > th.warning,
    .table > tfoot > tr > th.warning,
    .table > thead > tr.warning > td,
    .table > tbody > tr.warning > td,
    .table > tfoot > tr.warning > td,
    .table > thead > tr.warning > th,
    .table > tbody > tr.warning > th,
    .table > tfoot > tr.warning > th {
        background-color: $colour-pearl-lusta;
    }

    .table-hover > tbody > tr > td.warning:hover,
    .table-hover > tbody > tr > th.warning:hover,
    .table-hover > tbody > tr.warning:hover > td,
    .table-hover > tbody > tr:hover > .warning,
    .table-hover > tbody > tr.warning:hover > th {
        background-color: $colour-champagne;
    }

    .table > thead > tr > td.danger,
    .table > tbody > tr > td.danger,
    .table > tfoot > tr > td.danger,
    .table > thead > tr > th.danger,
    .table > tbody > tr > th.danger,
    .table > tfoot > tr > th.danger,
    .table > thead > tr.danger > td,
    .table > tbody > tr.danger > td,
    .table > tfoot > tr.danger > td,
    .table > thead > tr.danger > th,
    .table > tbody > tr.danger > th,
    .table > tfoot > tr.danger > th {
        background-color: $colour-bizarre;
    }

    .table-hover > tbody > tr > td.danger:hover,
    .table-hover > tbody > tr > th.danger:hover,
    .table-hover > tbody > tr.danger:hover > td,
    .table-hover > tbody > tr:hover > .danger,
    .table-hover > tbody > tr.danger:hover > th {
        background-color: $colour-pink-oyster;
    }

    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
    }

    .custom-table {
        table {
            .actions {
                text-align: center;
            }
        }
    }
}
