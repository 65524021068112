#pmContent {
    /*-------------------------
        Main Client Page Layout Class
    --------------------------*/
  .notification {
    padding: 20px 15px;

    &.warning {
      background-color: #fce9d9;

      .info {
        .headline {
          .stack {
            .stack-outer {
              color: #dd8524;
            }
          }
        }
      }
    }

    &.error {
      background-color: #f2dede;

      .info {
        .headline {
          .stack {
            .stack-outer {
              color: #d9534f;
            }
          }
        }
      }
    }

    &.vendor {
      padding: 0;
      margin-bottom: 20px;

      .notification-wrapper {
        color: #ffffff;
        padding: 25px 30px;

        .info {
          .headline {
            h4 {
              margin: 0 0 20px 0;
              font-size: 1.429rem;
              line-height: 1.929rem;
              font-weight: normal;
            }
          }

          .message {
            margin: 0;

            p {
              font-size: 1rem;
              line-height: 1.286rem;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .notification-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 910px;

      .info {
        .headline {
          display: flex;
          align-items: center;

          .stack {
            margin-top: -4px;
          }

          h4 {
            margin: 0 0 0 20px;
            font-weight: bold;
          }
        }

        .message {
          margin-left: 41px;
          margin-right: 30px;

          a {
            margin: 0;
          }
        }
      }
    }
  }

    .client-colour-tool {
        border: 1px solid $colour-gray;
        padding: 5%;
        clear: both;
        overflow: hidden;
        margin-bottom: 30px;

        .colour-viewer {
            border: 1px solid $colour-iron-2;

            //width: 148px;
            height: 148px;
            width: 35%;
            float: left;
            margin-right: 5%;

            &.square {
              height: 35px;
              width: 35px;
            }
        }

        .rgb {
            width: 25%;
            height: 150px;
            float: left;
            margin-right: 5%;
        }

        .hex {
            width: 25%;
            height: 150px;
            float: left;
        }
    }

    .logo {
        .form-hint {
            margin-top: 20px;
        }

        pm-file-uploader {
            .left-column {
                width: 100%;
            }

            .right-column {
                width: 100%;
                text-align: center;
            }
        }
    }

    /*-------------------------
        Partner and Vendor Dashboards
    --------------------------*/

    .dashboard-top {
        color: $colour-white;
        margin-top: 45px;

        h1 {
            font-size: 2rem;
            color: $colour-white;
            margin: 0 0 5px 0;
        }

        h2 {
            font-size: 1.143rem;
            color: $colour-white;
            margin-bottom: 25px;
        }

        h3 {
            font-size: 1rem;
            color: $colour-white;
            text-align: center;
            background: none;
            border: none;
            vertical-align: bottom;
            margin: 0;
            padding: 0;
        }

        .pull-left {
            margin: 0;
        }

        .pull-right {
            margin: 10px 0 0 0;
        }

        &.extra-space {
            margin-bottom: 60px;
        }

        .steps-holder {
            position: relative;
            height: 200px;

            &.partner {
                .step {
                    width: 100px;
                    float: left;
                    position: absolute;

                    &.current {
                        background-color: $colour-white;
                    }

                    &.one {
                        width: 30%;
                        left: 0;
                    }

                    &.two {
                        width: 33%;
                        left: 23%;
                    }

                    &.three {
                        width: 33%;
                        left: 49%;
                    }

                    &.four {
                        width: 25%;
                        left: 75%;
                    }

                    .inner {
                        opacity: 1;
                        position: absolute;
                        top: 20px;
                        text-align: center;
                        height: 170px;
                        vertical-align: bottom;

                        &.one {
                            left: 6%;
                            width: 62%;

                            .inner-bottom {
                                h3 {
                                    display: inline-block;
                                    max-width: 125px;
                                }
                            }
                        }

                        &.two {
                            left: 18%;
                            width: 50%;
                        }

                        &.three {
                            left: 18%;
                            width: 50%;
                        }

                        &.four {
                            left: 23%;
                            width: 65%;
                        }

                        .inner-bottom {
                            position: absolute;
                            bottom: 10px;
                            text-align: center;
                            width: 100%;

                            a, h3 {
                                line-height: 1.5;
                            }
                            a {
                                max-width: 125px;
                            }
                        }
                    }
                }
            }

            &.vendor {
                .step {
                    width: 100px;
                    float: left;
                    position: absolute;

                    &.one {
                        width: 39%;
                        left: 0;
                    }

                    &.two {
                        width: 40%;
                        left: 33%;
                    }

                    &.three {
                        width: 33%;
                        left: 67%;
                    }

                    .inner {
                        opacity: 1;
                        position: absolute;
                        top: 20px;
                        text-align: center;
                        height: 170px;
                        vertical-align: bottom;

                        &.one {
                            left: 6%;
                            width: 67%;
                        }

                        &.two {
                            left: 12%;
                            width: 63%;
                        }

                        &.three {
                            left: 15%;
                            width: 80%;
                        }

                        .inner-bottom {
                            position: absolute;
                            bottom: 10px;
                            text-align: center;
                            width: 100%;

                            a, h3 {
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }

            .dashboard-step {
                position: relative;
                height: 200px;
                font-size: 0.857rem;
                background: $colour-white;
                opacity: 0.2;
                color: $colour-white;
                margin: 0;
                line-height: 1.714rem;
                padding: 0 0 0 10px;

                &.left-arrow {
                    padding: 0 0 0 20px;

                    &:before {
                        border-width: 100px 0 100px 45px;
                        right: 100%;
                        left: 0;
                    }
                }

                &.right-arrow {
                    margin: 0 100px 0 0;

                    &:after {
                        border-left-color: $colour-white;
                        border-width: 100px 0 100px 45px;
                        left: 100%;
                    }
                }

                &:after, &:before {
                    border: solid $colour-transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                }

                &.current {
                    opacity: 1;
                }
            }
        }
    }

    .loyaltyPointsWidget {
      padding: 15px;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
      background-color: #fff;

      &.small {
        padding: 6px;
        background-color: rgba(255, 255, 255, 0.3);
        box-shadow: none;

        .value {
          font-size: 2.571rem;;
          line-height: 1;

          &.white {
            color: #fff;
          }
        }

        .name {
          font-size: 0.857rem;
          line-height: 1;

          &.white {
            color: #fff;
          }
        }
      }

      &.tall {
        padding: 40px 12px 41px 12px;
        /* pointsWidgetTall */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.transparent {
          background-color: rgba(255, 255, 255, 0.3);
          box-shadow: none;

          .name, .value {
            color: #fff;
          }
        }
      }

      .value {
        font-family: LatoLight;
        font-size: 4.286rem;
        line-height: 1;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3e8ddd;
        margin-bottom: 10px;
      }

      .name {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 0.857rem;
        line-height: 1;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #6b778c;
      }
    }

    .userBalance {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 260px;
      width: 100%;

      .balances {
        flex: 1 1 73%;
        display: flex;
        padding: 0 10px;

        &.partnerProgramDescription {
          background-color: #6ac346;
        }

        .balanceColumn {
          flex: 1 1 50%;
          display: flex;
          flex-direction: column;
          padding: 20px 10px 20px 10px;
          justify-content: space-between;
          max-width: 45%;
        }
      }

      .actions {
        flex: 1 1 27%;
        background-color: #ebecf0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        .button {
          flex: 1 1 50%;
          margin: 0 10px 0 10px !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          font-weight: bold;
          max-width: 42%;
        }
      }
    }

    .dashboard-bottom {
        margin: 20px 0 0 0;

        h1 {
            font-size: 1.429rem;
            margin: 0 0 3px 0;
        }

        h2 {
            font-size: 1rem;
        }

        .pills {
            margin: 60px 0 0 0;
        }

        template-types-pill-buttons {
            display: block;
            max-width: 395px;

            li {
                // This 10px is the same width as the pill's right margin.
                margin-bottom: 10px;
            }
        }
    }

    .divider {
        border-bottom: $colour-gray-chateau dotted 2px;
        margin: 15px 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .callout-solid {
        background-color: $colour-concrete;

        @include borderRadius(6px);

        overflow: hidden;
        vertical-align: top;

        &.asset-library {
            img {
                width: 100% !important;
                height: auto !important;
            }
        }
        &.partner {
            padding: 25px;
            position: relative;
            a {
                text-decoration: none;
            }

            p {
                margin: 0;
            }

            img {
                float: left;
                width: 28%;
            }

            .pull-left {
                padding-left: 5%;
                width: 67%;
            }

            h1 {
                margin: 0;
            }

            h2 {
                a {
                    text-decoration: none;
                    color: $colour-gray-dove;
                    font-weight: bold;
                }

                margin-top: 1em;
                margin-bottom: 1em;
            }

            .view-campaign-button {
                margin: 0;
                position: absolute;
                bottom: 25px;
                right: 25px;
                clear: both;
            }

            h4 {
                font-weight: bold;
                margin: 0;
            }

            hr.dotted {
                margin: 10px 0;
                background: $colour-gray;
                border-color: $colour-concrete;
                width: 100%;
            }

            ul.pull-left {
                height: auto;
                padding: 0;
                margin: 0;

                li {
                    padding: 0 10px;
                    float: left;
                    list-style: none;
                    border-left: 1px solid #808080;

                    &.first {
                        border: none;
                        padding: 0 10px 0 0;
                    }

                    &.last {
                        padding: 0 0 0 10px;
                    }
                }
            }
        }

        &.partner.span4 {
            padding: 20px;
        }

        &.vendor {
            padding: 20px;
            height: 250px;

            h4 {
                line-height: 100%;
                font-size: 1.286rem;
                margin: 0 0 20px 0;
                font-weight: normal;
            }

            .circle.small {
                margin: 18px -4px 17px;
                border: 5px solid $colour-white;
            }

            .square-wrapper {
                text-align: center;
                position: relative;

                > .square {
                    display: inline-block;

                    &:first-of-type {
                        left: 0;
                        position: absolute;
                    }

                    &:last-of-type {
                        right: 0;
                        position: absolute;
                    }

                    &.small {
                        margin: 2px 0 17px;
                        border: 5px solid $colour-white;
                        width: 110px;

                        .description {
                            font-size: 0.929rem;
                            width: 118px;
                            max-width: 118px;
                        }
                    }
                }
            }
        }

        &.admin {
            padding: 20px;
            text-align: center;

            &.dark {
                background-color: $colour-outer-space;

                .icon-holder {
                    height: 100px;
                    padding: 10px 0 0 0;
                }
            }

            &.light {
                background-color: $colour-fern;
                height: 232px;

                .icon-holder {
                    height: 90px;
                    padding: 30px 0 0 0;
                }
            }
        }
    }

    .featured-campaigns {
      display: flex;
      align-items: stretch;
      height: 235px;
    }
    .callout-featured {

      @include borderRadius(6px);

      overflow: hidden;
      vertical-align: top;
      background-color: $colour-concrete;
      padding-left: 0;
      padding-right: 0;

      .label {
        z-index: 10;
        left: 34px;
        right: auto;
      }

      &.col-md-4 {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .partner {
          flex-direction: column;
          align-items: center;
          h2 {
            margin-top: 0;
          }
          img {
            max-height: 100px;
          }
          .item-row {
            align-items: center;
            text-align: center;
            margin-bottom: 40px;
            .pull-left {
              float: none !important;
              text-align: center;
            }
          }
          .partner-action {
            position: absolute;
            bottom: 10px;
          }
        }
      }

      &.col-md-6 {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .partner {
          flex-direction: column;
          align-items: stretch;
          margin: 35px 10px 35px 25px;
          img {
            width: 40%;
            margin-right: 10px;
          }
          .item-row {
            flex-direction: row;
            display: flex;
            align-items: flex-start;
            margin-bottom: 40px;
          }
          .partner-action {
            text-align: right;
            position: absolute;
            bottom: 20px;
            right: 20px;
          }
        }
      }

      &.col-md-12 {
        .label {
          left: 14px;
        }
        .partner {
          flex-direction: column;
          img {
            width: 28%;
            margin-right: 30px;
          }
          .item-row {
            flex-direction: row;
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;
          }
          .partner-action {
            text-align: right;
            position: absolute;
            bottom: 25px;
            right: 25px;
          }
        }
      }

      .partner {
        margin: 35px 25px;
        vertical-align: top;
        overflow: hidden;
        display: flex;

        a {
          text-decoration: none;
          margin: 0;
        }

        p {
          margin-top: 1em;
          margin-bottom: 1em;
        }

        h2 {
          a {
            text-decoration: none;
            color: $colour-gray-dove;
            font-weight: bold;
          }

          margin-top: 1em;
          margin-bottom: 1em;
        }

        .view-campaign-button {

        }

        hr.dotted {
          margin: 10px 0;
          background: $colour-gray;
          border-color: $colour-concrete;
          width: 100%;
        }

        ul.pull-left {
          height: auto;
          padding: 0;
          margin: 0;

          li {
            padding: 0 10px;
            float: left;
            list-style: none;
            border-left: 1px solid #808080;

            &.first {
              border: none;
              padding: 0 10px 0 0;
            }

            &.last {
              padding: 0 0 0 10px;
            }
          }
        }
      }
    }

    .callout-dashed {
        border: $colour-gray-light dashed 3px;

        @include borderRadius(6px);

        overflow: hidden;
        padding: 17px;
        vertical-align: top;
    }

    .callout-transparent {
        position: relative;
    }

    div.callout-transparent-content {
        //    position: absolute;
        padding: 20px;
        z-index: 2;

        //    width: 100%;

    }

    .callout-transparent-bg {
        min-height: 250px;
        background-color: $colour-trout;

        @include borderRadius(6px);

        &.small {
            height: 288px;
        }
        .col-md-6, .col-md-4 {
            margin: 0 !important;
            position: relative;
            &:not(:first-of-type):before {
                content: " ";
                display: block;
                width: 1px;
                height: 80%;
                margin-top: 10%;
                left: -1px;
                top: 0;
                position: absolute;
                background: #979797;
            }
        }
    }

    .callout-transparent-inner {
        text-align: center;

        a.button:last-of-type {
            margin: 0;
        }

        &.small {
            .progress-bar {
                margin: 0 0 10px 0;

                > div {
                    padding: 0;

                    &.col-md-11 {
                        width: 83.33334%;
                    }

                    &.col-md-1 {
                        width: 16.66666%;
                    }

                    .outer {
                        margin-left: 0;
                    }
                }
            }

            a.button {
                width: 88%;
            }
        }

        .icon-holder {
            height: 155px;
            padding: 20px 0 28px 0;
            &.small {
                height: auto;
                img {
                    height: 100px !important;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        h4 {
            font-size: 1.286rem;
            line-height: 1.2;
            height: 45px;
            margin: 0 0 10px 0;
            font-weight: normal;
            color: $colour-white;
            text-align: left;
        }

        > .icon {
            font-size: 113px;
            margin-left: -5px;
        }

        .divider-right:after {
            content: '';
            position: absolute;
            top: 0;
            right: -1px;
            border-right: 2px dotted $colour-white;
            height: 100%;
        }

        .user-details {
            position: relative;
            width: 100%;
            margin-top: 3px;

            .bg {
                position: absolute;
                top: 0;
            }

            .stat {
                position: absolute;
                top: 102px;
                width: 100%;
                color: $colour-white;
                text-align: center;
                font-size: 1.786rem;
            }

            .description {
                position: absolute;
                top: 120px;
                width: 95px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                font-size: 0.929rem;
            }
        }

        .campaign-details {
            width: 100%;
            margin: 5px 0 10px 0;

            @include borderRadius(6px);

            background-color: $colour-white;
            height: 100px;

            .stat {
                width: 100%;
                color: $colour-white;
                text-align: center;
                font-size: 3.929rem;
                line-height: 1;
                padding: 10px 0 0 0;
            }

            .description {
                width: 100%;
                text-align: center;
                font-size: 0.929rem;
                padding: 0 0 10px 0;
            }

            .loading, .error {
                color: $colour-nobel-3;
                text-align: center;
                position: relative;
                top: 40px;
            }
        }
    }

    .circle {
        text-align: center;
        position: relative;
        color: $colour-white;

        &.large {
            @include circleButtonInner($colour-white, 85%, 85%);

            padding-bottom: 85%;
            border: 5px $colour-white solid;
            margin: 0 0 20px 4px;
        }

        &.small {
            @include circleButtonInner($colour-white, 120px, 120px);

            margin: 25px 0 30px 18px;
        }

        .inner {
            font-weight: bold;
            position: absolute;
            width: 100%;

            &.large {
                font-size: 3.214rem;
                top: 15px;
            }

            &.medium {
                font-size: 2.286rem;;
                top: 25px;

                ~ .description {
                    top: 67px;
                }
            }

            &.small {
                font-size: 4.643rem;
                top: 12px;
            }
        }

        .description {
            position: absolute;
            top: 65px;
            width: 100%;
        }
    }

    .square {
        text-align: center;
        position: relative;
        color: $colour-white;

        &.small {
            @include circleButtonInner($colour-white, 120px, 10px);

            margin: 25px 0 30px 18px;
        }

        .inner {
            font-weight: bold;
            position: absolute;
            width: 100%;
            font-size: 2.286rem;;
            top: 32px;
        }

        .description {
            display: table-cell;
            height: 60px;
            line-height: 1.143rem;
            overflow: hidden;
            padding: 55px 5px 5px;
            vertical-align: middle;
            text-align: center;
            width: 110px;
        }
    }

    #vendorDashboardActivity {
        .callout-transparent-inner {
            .divider-right {
                width: 55%;

                .col-md-6:first-of-type {
                    padding-right: 0;
                }

                .col-md-6:last-of-type {
                    padding-left: 0;
                }
            }
        }
    }

    #vendorDashboardActivity {
        .callout-transparent-content {
            padding: 20px 15px;

            .callout-transparent-inner {
                &.disableRightCounterWrapper {
                    .divider-right {
                        padding: 0 20px;
                        width: 100%;

                        .col-md-6:first-of-type {
                            .user-details {
                                float: right;
                                margin-right: 20px;
                            }
                        }

                        .col-md-6:last-of-type {
                            .user-details {
                                float: left;
                                margin-left: 20px;
                            }
                        }

                        &:after {
                            height: 0%;
                        }
                    }
                }

                h4 {
                    margin-bottom: 20px;
                }

                .divider-right {
                    width: 53%;

                    &:after {
                        height: 100%;
                    }

                    > .row {
                        height: 171px;
                    }

                    .user-details {
                        width: 103px;
                        margin-bottom: 10px;
                        display: inline-block;

                        .loading-icon {
                            text-align: center;
                            z-index: 10;
                        }

                        .bg-icon {
                            position: relative;
                            line-height: 135px;

                            > .icon {
                                &:before {
                                    font-size: 113px;
                                    margin-left: -5px;
                                }

                                &:after {
                                    border-bottom: 50px solid;
                                    border-radius: 25px;
                                    bottom: 15px;
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    width: 103px;
                                    height: 40px;
                                }
                            }
                        }

                        .stat {
                            top: 73px;
                        }

                        .description {
                            top: 110px;
                            line-height: 1rem;
                        }
                    }

                    .col-md-6:first-of-type {
                        padding-right: 0;
                    }

                    .col-md-6:last-of-type {
                        padding-left: 0;
                    }
                }

                > .col-md-5 {
                    width: 47%;

                    .counterBox {
                        padding-top: 20px;
                        box-sizing: border-box;
                    }

                    .circle.small {
                        margin: 0 auto;
                        vertical-align: top;
                        width: 110px;
                        height: 110px;
                        border-radius: 110px;

                        .inner.small {
                            font-size: 4.429rem;
                            top: 40px;
                        }
                    }
                }
            }
        }
    }

    .dashboard-counters {
        .campaign-counters, .user-counters {
            vertical-align: top;
            width: 45%;
            float: left;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
            box-sizing: border-box;
        }

        .user-counters {
            width: 55%;
        }
    }

    .marketing-store-widget {
        padding-left: 20px;
        padding-right: 0;
    }

    .module-settings-item {
        &::after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
        }

        &.no-border-bottom {
          margin-bottom: 0;
          padding-bottom: 20px;
        }

        padding: 0 0 40px;
        border-bottom: 1px solid $colour-gray;
        margin-bottom: 25px;

        h2 {
            margin-bottom: 20px;
        }

        h4 {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .file-upload-area {
            clear: both;
            padding-top: 25px;
        }

        .input {
            &.multiselect-input {
                label {
                  padding-left: 0;
                }
            }
        }

        .upload-wrapper {
            padding: 30px 0;
        }

        .module-settings-item-header {
            display: flex;
            justify-content: space-between;
        }
    }

    .admin {
        &.user-details {
            h3 {
                border: none;
                background: transparent;
                margin: 15px 0;
                padding: 0;
                font-weight: bold;
            }
        }
    }

    .dashboard-carousel {
        margin: 50px 0 70px 0;

        .slides {
            .slide {
                padding: 0;
                display: flex;
                justify-content: space-between;
                height: 401px;

                &.hidden {
                    display: none;
                }

                .slide-image {
                    width: 580px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;

                    img {
                        max-width: unset;
                    }
                }

                .slide-content {
                    box-sizing: border-box;
                    width: 390px;
                    padding-left: 70px;
                    padding-top: 70px;

                    h3 {
                        font-size: 1.429rem;
                        font-weight: normal;
                        line-height: 1.35;
                        color: #393a3d;
                        margin-bottom: 2px;
                    }

                    h5 {
                        font-size: 14.4px;
                        font-weight: bold;
                        line-height: 1.5;
                        color: #393a3d;
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 12.8px;
                        line-height: 1.19;
                        color: #393a3d;
                        margin-bottom: 24px;
                    }

                    .button {
                        margin: 0px;
                        font-size: 0.857rem;
                        padding: 9px 25px;
                        line-height: normal;
                        background-color: #2ca01c;
                        font-weight: bold;
                    }
                }
            }
        }

        .marks {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;

            .mark {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px solid #979797;
                background-color: #fff;

                &.active {
                    background-color: #2ca01c;
                }

                & + .mark {
                    margin-left: 13px;
                }
            }
        }
    }
}
