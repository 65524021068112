/*!
 * angularjs-color-picker v3.4.8
 * https://github.com/ruhley/angular-color-picker/
 *
 * Copyright 2017 ruhley
 *
 * 2017-10-06 09:52:03
 *
 */
.color-picker-wrapper .color-picker-input-wrapper {
  width: 100%;
}
.color-picker-wrapper .color-picker-swatch:not(.input-group-addon) {
  height: 28px;
}
.color-picker-wrapper.color-picker-swatch-only .input-group .input-group-addon {
  border-radius: 4px;
}
