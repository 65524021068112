/**
 * This style will cover all alert messages
 */
div[ng-show="showDialog"].ng-hide-remove,
div[ng-show="showNotice"].ng-hide-remove,
div[pm-modal] div.state-modal.ng-hide-remove {
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

div[ng-show="showDialog"].ng-hide-add,
div[ng-show="showNotice"].ng-hide-add,
div[pm-modal] div.state-modal.ng-hide-add {
    display: block !important;
}

#pmContent {
    .alert {
        background-color: $colour-black-transparent-80;
        z-index: 2;

        &.info {
            color: $colour-green-persian;
        }

        &.warning {
            color: $colour-yellow;
        }

        &.success {
            color: $colour-fern-2;
        }

        &.error {
            color: $colour-valencia;
        }

        p {
            color: $colour-white;
        }
    }

    .alert-dialog.demo {
        margin: 25px 0;
        position: relative;
        left: inherit;

        @extend .alert;
    }

    .alert-notice.demo {
        position: relative;
    }

    /**
     * Popup alert
     */

    .alert-dialog {
        @extend .alert;
        z-index: 1001;

        .alert {
            position: fixed;
            z-index: 1000;
            width: 470px;
            padding: 25px 25px 0 25px;
            margin-left: -235px;
            left: 50%;
            top: 35%;

            @include borderRadius(4px);
            @include box-shadow(3px 3px 7px 1px $colour-black-transparent-30);

            .alert-close {
                position: absolute;
                right: 25px;
                color: $colour-gray-dusty;
            }
        }

        .header {
            h6 {
                padding-left: 45px;
                color: $colour-white;
                border-bottom: none;
                margin-bottom: 10px;
            }
        }

        .body {
            border-top: 1px solid $colour-gray-2;
            padding-top: 15px;
            font-style: italic;
        }

        .footer {
            padding-top: 15px;
            text-align: right;
        }
    }

    /**
     * Notice bar alert
     */

    .alert-notice {
        @extend .alert;

        p.notice-message {
            margin-top: 10px;
            margin-left: 40px;
            line-height: 2rem;
            color: $colour-gray;
        }

        .inner-container {
            margin-top: 10px;
        }

        a.pull-right {
            line-height: 2rem;

            i.icon {
                color: $colour-gray !important;
            }
        }
    }
}
