#pmContent {

    .color-refresh {
        color: $colour-green;
    }

    .new-version:before {
        content: " | ";
        padding: 0 5px;
    }

    form[name="form.landing_page"],
    form[name="campaignCustomiseForm"] {
        .campaign-url-section {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .bucket-name {
                padding: 0 5px 0 0;
                width: auto;
                white-space: nowrap;
            }

            .url-input {
                width: 100%;
            }
        }
    }

    .asset-request-form {
        textarea {
            min-height: 130px;
        }
    }

    @include bootstrap-table-styling;

    // Added this styles to allow text angular tags wrok properly.
    // Need to extend this rules with classes, where text angular's html will be placed.
    .confirmation-page-description,
    .ta-editor [contenteditable] {
        i {
            font-style: italic;
        }

        b {
            font-weight: bold;
        }
    }

    .filter-button-box {
        background-color: $colour-gray-light;
    }

    /* 1-5 Stage list (currently enforced at 5 parts due to 20% width * 5) */
    .stage-list {
        background-color: #f4f5f7;

        .step-title-container {
            background-image: linear-gradient(to top, #f4f5f7 0%, #ebecf0 100%);
            border-top: dotted 2px #d8d8d8;
            margin-top: 20px;

            .step-title {
                font-size: 0.857rem;
                font-weight: bold;
                margin-left: -20px;
                margin-top: 10px;
                color: #6b778c;
            }
        }
        ul {
            margin: 20px 0;
            width: 100%;
            list-style: none;
            background: $colour-white;
            text-align: center;

            li {
                list-style: none;
                //display: inline;
                width: 20%;
                margin: 0 0 0 0;
                display: block;
                float: left;
                text-align: center;
                //background: $colour-transparent url("/images/icons/stage-list-bg.png") repeat-x;
                //background-position: 0 15px;
                position: relative;
                &:before {
                    position: absolute;
                    top: 18px;
                    left: 0;
                    right: 0;
                    height: 6px;
                    content: " ";
                    background: $colour-white;
                }

                &.first {
                    margin-left: 0;
                    //background: $colour-transparent url("/images/icons/stage-list-bg-left.png");
                    //background-position: 50% 15px;
                    //background-repeat: no-repeat;
                    &:before {
                        left: 50%;
                        right: 0;
                    }
                }

                &.last {
                    position: relative;
                    margin: 0;
                    //background: $colour-transparent url("/images/icons/stage-list-bg-right.png");
                    //background-position: 50% 15px;
                    //background-repeat: no-repeat;
                    &:before {
                        left: 0;
                        right: 50%;
                    }
                }

                i b {
                    @include innerCircleButtonWithText($colour-transparent, 1.571rem, 1.571rem, 4px, $colour-gray);

                    padding: 5px;
                    font-weight: bold;
                    font-style: normal;
                }

                &.current,
                &.past {
                    position: relative;

                    i {
                        position: relative;
                        color: $colour-white;
                        line-height: 2.286rem;

                        b {
                            // background-color overridden in the client-specific CSS.
                            @include innerCircleButtonWithText($colour-green, 1.571rem, 1.571rem, 4px, $colour-white);

                            padding: 5px;
                        }
                    }

                }

                &.current:before {
                    //right: 50%;
                }

                &.first:before {
                    left: 50% !important;
                }

                &.past {
                    i {
                        background: $colour-white;
                    }
                }

                i {
                    @include circleButtonInner($colour-white, 1.429rem, 2.143rem);

                    padding: 10px;
                    margin: 0 auto;
                    color: $colour-gray;
                    font-size: 1.286rem !important;
                    line-height: 1.286rem;
                }

                p {
                    margin-top: 10px;
                    color: $colour-gray-dark;
                    font-size: 12px !important;
                }
            }
        }
    }

    .create-a-campaign {
        margin: 0 auto;
        width: 380px;
        background: $colour-transparent url("/images/campaigns/create-first-campaign.png") no-repeat top center;
        padding-top: 178px;

        p {
            font-size: 1.286rem !important;
        }

        a {
            margin: 0 auto;
            text-align: center;
            display: block;
            width: 180px;
        }
    }

    .info-block {
        background: $colour-concrete;
        padding: 15px 20px;
        margin: -20px 0 20px;

        &.with-border {
            border: 1px solid $colour-gray-light-1;

            p {
                font-style: normal;
            }
        }

        p {
            line-height: 1.5rem;
            margin-bottom: 0;
            margin-left: 45px;
            color: $colour-gray-dark;
            font-style: italic;
        }
    }

    .inner-info-block {
        margin: 5px 0 10px;
        .icon-holder {
            float: left;
        }
        p {
            line-height: 1.429rem;
            margin-left: 30px;
            font-style: italic;
        }
    }

    .campaign-search {
        form.list-search-widget-with-button {
            input[type="text"] {
                width: 400px;
            }
        }
    }

    .campaign-choices {
        table, tr, td {
            border: none;
            border-bottom: none;
        }

        table tr:last-child {
            border-bottom: none;
        }

        b {
            font-weight: bold;
        }

        .number-results {
            font-size: 1.286rem;
            font-weight: 400;
            margin-bottom: 10px;

            .clear-filters {
                margin-left: 15px;
            }
        }

        .campaign {
            background: none repeat-x scroll 0 100% $colour-concrete;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABPCAIAAAClJTjCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjlBQ0Q3Nzk5RDc0MTFFNDgzN0NBRDBGRDYzMjBGOTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjlBQ0Q3N0E5RDc0MTFFNDgzN0NBRDBGRDYzMjBGOTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2OUFDRDc3NzlENzQxMUU0ODM3Q0FEMEZENjMyMEY5OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2OUFDRDc3ODlENzQxMUU0ODM3Q0FEMEZENjMyMEY5OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp8KtkkAAAAlSURBVHjaYvj48SMTAwMD2fj///8004dPDalyxIiRwgeyAQIMAFa4JE8rxmoWAAAAAElFTkSuQmCC);
            min-height: 187px;
            .campaign-image {
                width: 185px;
                vertical-align: baseline;
                padding: 10px;
                img {
                    display: block;
                }
            }

            .campaign-meta {
                position: relative;
                width: 100%;
                vertical-align: top;
                padding-bottom: 63px;

                h4 {
                    font-size: 1.143rem;
                    line-height: 1.429rem;
                    margin-bottom: 10px;
                    font-weight: bold;
                }

                .campaign-meta-padding {
                    overflow: hidden;
                    padding: 30px 5px 0 0;
                    // min-height: 105px;
                    position: relative;
                    p {
                        line-height: 1.286rem;
                    }
                    @extend .pill-buttons;

                    .pill-buttons {
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }

            .campaign-actions {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 0 0 20px 25px;
                padding: 0 15px 0 0;
                min-width: 220px;

                ul {
                    margin: 0;

                    li {
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        list-style: none;

                        @extend .button;

                        //background-color overridden by client-main-colour-background class on html
                        width: 100%;
                        margin: 0;
                        padding: 9px 14px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            color: $colour-white;
                            display: block;
                            text-align: center;
                            text-decoration: none;
                            font-weight: bold;
                        }

                        &.view-campaign {
                            a {
                                color: $colour-black;
                                text-align: left;

                                i {
                                    color: $colour-white;
                                }
                            }
                        }

                        &.preview i.icon {
                            float: none;
                        }

                        span {
                            float: left;
                            display: block;
                        }

                        i.icon {
                            float: right;
                            line-height: inherit;
                        }

                        &.number {
                            i {
                                @include circleButtonInner($colour-white, 1.714rem, 1.714rem);

                                b {
                                    // background-color overridden in the client-specific CSS.
                                    @include innerCircleButtonWithText($colour-green-persian, 20px, 20px, 2px, $colour-white);
                                }
                            }

                            span {
                                width: 100%;
                                margin-top: 1px;
                            }
                        }
                    }
                }

                .campaign-actions-padding {
                    padding: 0 20px;
                    overflow: hidden;
                }
            }
        }

        /* Sub Campaigns */
        .sub-campaigns {
            margin: -20px 0 20px 0;

            .sub-campaigns-arrow {
                position: relative;

                &:after {
                    @include triangleTop($colour-gray-light, 10px, left, 85%);
                }
            }

            .sub-campaigns-wrapper {
                border: 1px solid $colour-mercury-3;
                border-top: 0;
                padding: 20px 20px 0 20px;
            }

            .campaign-actions {
                padding-bottom: 0;
                margin-bottom: 15px;
            }
        }
    }

    .main-campaign-wrapper > .campaign {
        border: 1px solid transparent;
        border-bottom: 0;
    }

    .main-campaign-wrapper.is-open > .campaign {
        border: 1px solid $colour-mercury-3;
        border-bottom: 0;
    }

    .sub-campaign-wrapper,
    .main-campaign-wrapper {
        overflow: hidden;
        position: relative;

        .highlight {
            height: 4px;
            background-color: $colour-green-light;
            clear: both;
        }

        .label {
            right: 20px;
            position: absolute;
            float: right;
            background-color: $colour-green-light;
            color: $colour-white;
            padding: 0px 6px 2px 6px;
            border-radius: 0 0 6px 6px;
            font-size: 12px !important;
            line-height: 1.571rem !important;
        }

        .shown {
            border-bottom: 20px solid $colour-gray-light;
        }
    }

    // Template Carousel
    .expanded-campaign-view {
        clear: both;
        background: $colour-blue-oxford;
        padding: 10px 30px 0;
        margin-bottom: 20px;

        .extended-wrapper {
            position: relative;

            &:after {
                @include triangleTop($colour-blue-oxford, 10px, left, 85%);
            }
        }

        .expanded-header {
            padding: 15px 0;

            h5 {
                color: $colour-white;
                float: left;
                line-height: 2.143rem;
            }

            a.close {
                float: right;
                display: block;
                color: $colour-white;
                background: $colour-gray-shuttle;
                height: 25px;
                width: 25px;
                text-align: center;
                line-height: 1.786rem;
                font-weight: bold;

                @include borderRadius(15px);
            }
        }

        .expanded-content {
            border-top: 1px solid $colour-gray-shuttle-2;
            border-bottom: 1px solid $colour-gray-shuttle-2;
            padding: 30px 0 20px 0;
        }

        .expanded-footer {
            padding: 10px 0;

            ul {
                margin: 0;

                li {
                    list-style: none;
                    float: left;

                    &.submit {
                        float: right;

                        a {
                            padding-right: 35px;
                            padding-left: 35px;
                        }
                    }
                }
            }
        }
    }

    form[name="form.value"] #limitedTextarea {
        height: 360px;
    }

    .confirmation-page-description {
        margin: -10px 0 30px 0;
    }

    .application-description {
        padding-top: 30px;
        margin: 0 0 30px 0;
        &.border {
            border-top: 1px solid $colour-gray-88;
        }
    }

    .templates-page-previews {
        padding-top: 30px;
        overflow: hidden;
        position: relative;

        .templates-page-previews-url {
            padding: 10px 15px;
            background: $colour-concrete;
        }

        &.time-line {
            &:after {
                content: '';
                position: absolute;
                height: 100%;
                left: 50%;
                top: 0;
                border-left: 2px solid $colour-gray-88;
            }
        }

        &:last-of-type {
            padding-bottom: 45px;
        }
    }

    .templates-group-description {
        padding-top: 15px;
    }

    .templates-page-preview {
        float: left;
        width: 45%;
        margin-bottom: 2%;
        padding: 20px;
        border-radius: 4px;
        background: $colour-concrete;
        min-height: 100px;
        position: relative;

        &.optional {
            background: transparent;
            border: 2px dotted $colour-gray;
        }

        &.publish {
            width: 44%;
            height: 210px;
            padding: 20px 2%;

            &.client-main-colour-border {
                border: 2px solid;
                padding: 18px 20px;
            }

            .asset {
                min-height: 190px;
            }

            .content-button {
                position: absolute;
                bottom: -25px;
                right: 0;
            }
        }

        &.edit-highlight {
            width: 44%;

            &.client-main-colour-border {
                border: 2px solid;
                padding: 18px 20px;
            }

            .asset {
                min-height: 150px;
            }
        }

        &.even {
            float: right;
        }

        &.disabled {
            background-color: transparent;
            border: 1px solid $colour-gray-light-1;
            padding: 19px;

            > * {
                opacity: 0.5;
            }
        }

        &.time-line {
            width: 43%;

            &:not(.first) {
                margin-top: calc(-3% - 40px);
            }

            &.odd {
                margin-right: 50%;

                /* Chage circle position. */
                &:before {
                    content: '';
                    left: auto;
                    right: -44px;
                }

                /* Chage arrow position for element. */
                &:not(.optional):not(.disabled):not(.publish):not(.edit-highlight) {
                    &:after {
                        border-right-color: transparent;
                        border-left-color: $colour-concrete;
                        left: auto;
                        right: -20px;
                    }
                }
            }

            /* Arrow pointing circle on timeline. */
            &:not(.optional):not(.disabled):not(.publish):not(.edit-highlight) {
                &:after {
                    position: absolute;
                    top: 20px;
                    left: -20px;
                    display: block;
                    width: 0;
                    border: 10px solid transparent;
                    border-right-color: $colour-concrete;
                    content: '';
                }
            }

            /* Circle on timeline. */
            &:before {
                content: '';
                width: 16px;
                height: 16px;
                background-color: $colour-gray-88;
                border-radius: 50%;
                position: absolute;
                left: -42px;
                border: 6px solid $colour-white;
                top: 16px;
                z-index: 1;
            }

            /* Optional or disabled (social post) template. */
            &.optional,
            &.disabled,
            &.publish,
            &.edit-highlight {
                /* Chage arrow position for element. */
                &.odd {
                    .border-arrow-wrapper {
                        left: auto;
                        right: -20px;

                        .border-arrow {
                            left: auto;
                            right: 12px;
                        }
                    }
                }

                /* Arrow pointing circle on timeline. */
                .border-arrow-wrapper {
                    position: absolute;
                    top: 20px;
                    left: -20px;
                    width: 20px;
                    height: 20px;
                    background-color: $colour-white;
                    overflow: hidden;

                    .border-arrow {
                        height: 10px;
                        width: 10px;
                        border: 2px dotted $colour-gray;
                        border-radius: 2%;
                        transform: rotate(45deg);
                        position: absolute;
                        top: 2px;
                        left: 12px;
                    }
                }
            }

            /* Disabled (social post) template. */
            &.disabled {
                background-color: transparent;
                border: 1px solid $colour-gray-light-1;

                &.optional {
                    border: 2px dotted $colour-gray-light-1;
                    padding: 20px;

                    .border-arrow-wrapper .border-arrow {
                        height: 10px;
                        width: 10px;
                        border: 2px dotted $colour-gray-light-1;
                    }
                }


                > * {
                    opacity: 0.5;
                }

                /* Arrow pointing circle on timeline. */
                .border-arrow-wrapper {
                    opacity: 1;

                    .border-arrow {
                        height: 14px;
                        width: 14px;
                        border: 1px solid $colour-gray-light-1;
                        background: $colour-white;
                    }
                }
            }

            &.publish,
            &.edit-highlight {
                &.time-line.client-main-colour-border {
                    &.even:before {
                        left: -40px;
                    }

                    &.odd:before {
                        right: -41px;
                    }
                }

                &.odd {
                    .border-arrow-wrapper {
                        left: auto;
                        right: -21px;
                    }
                }

                /* Arrow pointing circle on timeline. */
                .border-arrow-wrapper {
                    width: 21px;
                    height: 21px;
                    left: -21px;

                    .border-arrow {
                        background: $colour-concrete;
                        height: 14px;
                        width: 14px;

                        &:not(.client-main-colour-border) {
                            border: none;
                        }

                        &.client-main-colour-border {
                            border: 2px solid;
                        }
                    }
                }
            }

            &.disabled {
                &.edit-highlight {
                    .border-arrow-wrapper {
                        .border-arrow {
                            border-width: 1px;
                        }
                    }
                }
            }
        }

        .name {
            font-size: 1.2em;
            margin-bottom: 10px;
            max-height: 1.5em;
            overflow: hidden;
        }

        .type {
            margin-bottom: 6px;
            max-height: 1.5em;
            overflow: hidden;
            font-size: 1.5em;
        }

        .description {
            min-height: 119px;
        }

        .template-preview__optional_lock {
            position: absolute;
            right: 17px;
            top: 17px;
        }

        .template-preview-content {
            position: relative;

            .template-preview__optional-checkbox {
                position: absolute;
                right: -14px;
                top: -13px;
                min-width: auto;
                cursor: pointer;
            }

            .col-md-10 {
                padding-left: 0;
            }

            .social {
                margin-top: 0;

                .social-post-message {
                    font-weight: bold;
                    margin: 10px 0;
                }

                .name {
                    line-height: 2.5em;
                    max-height: 2.5em;
                }

                hr {
                    border: 1px solid $colour-gray-light;
                }
            }
            .button, a {
                margin-bottom: 0;
                float: right;
                margin-left: 10px;
                line-height: 1.714rem;
                transition-duration: 0s;
                -webkit-transition-duration: 0s;
            }
        }

        &.publish .template-preview-content {
          .button, a {
            padding: 1px 7px;
          }
        }

        &.missed-fields {
            border: 3px solid;
        }

        form[name=templateEditForm] {
            .float-input {
                padding: 10px 0;
            }
        }

        &.error, &.error .border-arrow {
            background-color: $colour-red-light !important;
            border: 2px solid $colour-red !important;
        }
    }

    .campaign-full-info {
        .form-hint {
            background-color: $colour-concrete;
            margin-bottom: 20px;
        }

        .campaign-full-info__assets-confirmation {
            padding: 15px 0 5px;

            h3 {
                margin: 10px 0;
            }
        }

        .campaign-full-info__assets-confirmation__item {
            position: relative;
            background: $colour-concrete;
            border: 1px solid $colour-gray-light-1;
            padding: 15px;
            margin: 10px 0;

            .template-preview__optional-checkbox {
                min-width: auto;
                padding-top: 2px;
                margin-right: 5px;
            }

            .template-preview__optional_lock {
                margin-right: 5px;
            }

            .social-status-wrp {
                float: right;

                .social-status {
                    line-height: 1.714rem;
                    padding: 20px 15px 16px 0;
                    border-right: 1px solid $colour-gray-light-1;

                    .stack {
                        margin: 0 5px;
                        width: 1.571rem;

                        .stack-inner {
                            width: 21px;
                            line-height: 1.571rem;
                        }
                    }
                }

                .stack {
                    margin: 0 3px 0 18px;
                    width: 15px;

                    .stack-outer {
                        font-size: 1.143rem;
                    }

                    .stack-inner {
                        width: 16px;
                        font-size: 0.857rem;
                        line-height: 1.714rem;
                    }
                }

                .tooltip {
                    min-width: 170px;
                    width: auto;

                    .tooltip-inner {
                        max-width: 400px;
                    }
                }
            }

            .tooltip {
                width: 160px;

                .tooltip-inner {
                    text-align: left;
                }
            }
        }
    }

    .display_block {
        display: block !important;
        visibility: visible !important;
    }

    /*
        Created on : 10-Jan-2014, 13:42:40
        Author     : Seb Dangerfield <seb.dangerfield@wearetwogether.com>
    */
    .campaign-instance {
        .refresh {
            position: absolute;
            background-color: transparent;
            top: 0;
            left: 0;
            width: 70px;
            height: 70px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-color: transparent;
                border-style: solid;
            }

            &:before {
                border-width: 35px;
                border-left-color: $colour-iron;
                border-top-color: $colour-iron;
            }

            &:after {
                border-width: 34px;
                border-left-color: $colour-white;
                border-top-color: $colour-white;
            }

            .stack {
                z-index: 1;
                position: absolute;
                top: 7px;
                left: 8px;

                &.x1 {
                    width: 25px;
                    height: 25px;
                    line-height: 1.786rem;
                }

                .icon.x1 {
                    font-size: 1.786rem !important;
                }

                .icon.x075 {
                    font-size: 1.071rem !important;
                }
            }
        }

        border: solid 1px #d8d8d8;
        height: 355px;
        padding: 0;
        margin-bottom: 20px;
        width: 298px;
        float: left;
        margin-right: 35px;
        position: relative;

        &.last-in-row {
            margin-right: 0;
        }

        .cover-image-container {
            background-color: $colour-concrete;
            height: 209px;
            width: 100%;
            white-space: nowrap;
            text-align: center;
            border-bottom: 1px solid #d8d8d8;

            span {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
                max-height: 209px;
                max-width: 170px;
            }
        }

        .campaign-instance-details {
            position: relative;
            height: 145px;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;

            p {
                margin: 0;

                &.updated-at {
                    font-size: 0.857rem;
                    margin-bottom: 14px;
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .items {
                    font-size: 0.857rem;
                    margin-right: 8px;
                    .icon {
                        margin-right: 6px;
                        font-size: 1rem;
                    }
                }

                .button {
                    margin: 0 0 0 7px;
                }

                .status-bubble {
                    line-height: 1;
                }
            }
        }

        .name {
            position: relative;
            height: 40px; /* exactly three lines */
            color: $colour-gray-dark;
            font-size: 1rem;
            font-weight: bold;
            max-width: 248px;
            line-height: 1.43;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .name:after {
            content: "";
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 70%;
            height: 20px;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        }

        .date, .user {
            font-size: 0.857rem;
        }

        .info {
            height: 70px;
        }
    }

    .progress-indicator-bar {
        margin: 0;

        li {
            list-style: none;
            float: left;
            width: 25%;
        }
    }

    .progress-indicator {
        position: relative;
        height: 24px;
        font-size: 0.857rem;
        background: $colour-white;
        color: $colour-nobel-2;
        margin: 0;
        line-height: 1.714rem;
        padding: 0 0 0 10px;

        &.left-arrow {
            padding: 0 0 0 20px;

            &:before {
                border-left-color: $colour-concrete;
                border-width: 12px;
                right: 100%;
                left: 0;
            }
        }

        &.right-arrow {
            margin: 0 12px 0 0;

            &:after {
                border-left-color: $colour-white;
                border-width: 12px;
                left: 100%;
            }
        }

        &:after, &:before {
            border: solid $colour-transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
        }
    }

    .complete .progress-indicator, .in-progress .progress-indicator {
        color: $colour-white;
    }

    .complete .progress-indicator {
        background-color: $colour-mantis;

        &:after {
            border-left-color: $colour-mantis;
        }
    }

    .in-progress .progress-indicator {
        background-color: $colour-manhattan;

        &:after {
            border-left-color: $colour-manhattan;
        }
    }

    #campaignFilterBar {
        margin-bottom: 30px;
        padding: 10px 0 10px;

        ul {
            padding-left: 28px;

            &.filter-buttons li a {
                margin: 0;
                border: none;

                &.current {
                    border: none !important;
                    color: $colour-white;
                }
            }

            &.pill-buttons {
                margin: 0;

                li a {
                    padding: 4px 15px;
                    background: $colour-gray-athens;
                }
            }
        }
    }

    .my-activity-filter {
        padding: 16px 14px 14px;

        &-stripe {
            background-color: $colour-concrete;
            margin-bottom: 25px;
        }

        .sub-title {
            font-size: 1.143rem;
            font-weight: bold !important;
            line-height: 1.25;
            color: #172b4d;
        }

        .filters-count {
            margin-top: 4px;
        }

        .clear-filters {
            position: relative;
            top: -2px;
        }

        .filter-multiselect {
            width: 100%;

            .multiSelect {
                line-height: 0;

                & > button:not(.pm-ignore-styles) {
                    width: 100%;
                }
            }
        }

        select.full-width {
            height: 38px;
        }

        form.list-search-widget-with-button {
            input[type="text"] {
                min-height: 35px;
                padding: 0 10px 0 37px;
            }
            input[type="submit"] {
                min-height: 35px;
                padding: 0 12px;
            }
        }

        .filters-count {
            display: inline-block;
        }

        a.clear-filters {
          margin: 0 0 0 13px;
        }

        a.stack {
          display: inline-block;
          width: 26px;
          height: 26px;
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          margin: 0 0 0 13px;

          .icon {
            top: 2px;
          }
        }
    }

    .activity-description {
        margin: 27px 0;
    }

    templates-publication-item,
    templates-publication-item-landing-page,
    templates-publication-item-email,
    templates-publication-item-static-banner {
        display: block;
    }

    .version-switcher {
        margin-bottom: 10px;
    }

    .templates {
        .content-wrapper {
            margin-bottom: 30px;
        }

        .list {
            padding-top: 30px;
            padding-bottom: 30px;

            > div > hr {
                margin: 10px 0;
            }

            .img {
                padding: 5px;
                background: $colour-white;
                position: relative;
                min-height: 290px;

                &:hover {
                    &:before {
                        font-family: 'partnermarketing';
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        content: "\e610";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        font-size: 4em;
                        margin-left: -0.5em;
                        margin-top: -0.5em;
                    }

                    img {
                        opacity: 0.3;
                    }

                    .info {
                        display: block;
                    }
                }

                img {
                    width: 100%;
                    cursor: pointer;
                }

                .info {
                    display: none;
                    border: 1px solid $colour-white;
                    background-color: $colour-black;
                    color: $colour-white;
                    padding: 10px;
                    height: 83px;
                    z-index: 1;
                    bottom: 5px;
                    position: absolute;
                    left: 5px;
                    right: 5px;
                }
            }
        }

        &.publication {
            $publication_big_text: 17px;
            $publication_general_spacing: 17px;

            > p {
                margin-bottom: $publication_general_spacing;
            }

            > div > .align-center {
                margin-top: 7px;
                margin-bottom: 7px;
            }

            h2:not(.seperator) {
                font-size: $publication_big_text;
                font-weight: bold;
                margin-top: $publication_general_spacing;
                margin-bottom: $publication_general_spacing;
            }

            .marketing-box-package {
              border-color: $colour-gray-light-1;
              &-header {
                background: $colour-concrete;
                border-bottom: 1px solid $colour-gray-light-1 !important;
              }
              &-body {
                background-color: $colour-white;
                &.colour-concrete {
                  background-color: $colour-concrete;
                }
              }
            }

            .accented-area {
              padding: 12px 13px;
              background: $colour-concrete;

              a {
                text-decoration: underline;
              }
            }

            p.accented-area {
              margin: 20px 0;
            }

          .overview {
                height: auto;
                margin: 20px 0 30px 0;

                .thumbnail {
                    float: left;

                    img {
                        width: 170px;
                        height: 170px;
                        overflow: hidden;
                        vertical-align: bottom;
                    }
                }

                .details {
                    padding: 16px 20px 9px;
                }

                .margin-tooltip {
                    margin-top: -20px;
                }
            }

            #autopilotDisabled,
            #autopilotPrompt,
            #autopilotStatus {
                margin: 40px 0 20px 0;
                padding: 20px;
                border: 2px solid;

                .form-hint {
                    padding: 0;
                    background-color: $colour-transparent;

                    .copy {
                        color: $colour-white;
                    }
                }

                .content-button {
                    padding-top: 10px;

                    > .margin-right {
                        margin-right: 20px;
                    }
                }

                .headline {
                    font-size: $publication_big_text;
                    margin: 0 0 5px 0;
                }
            }

            #autopilotStatus .button-group,
            #autopilotDisabled .button-group {
                margin-top: 20px;
            }

            #autopilotNotNow {
                line-height: 2.143rem;
                text-decoration: underline;
                font-size: 0.9em;
                margin-right: 30px;
            }

            #autopilotPrompt {
                .loading-container {
                    .loading {
                        background-color: $colour-transparent;
                    }
                    &.active {
                        .loading-content-wrapper {
                            visibility: hidden;
                            .button {
                                transition-duration: 0s;
                                -webkit-transition-duration: 0s;
                            }
                        }
                    }
                }
            }

            .scheduleBroadcastLeadListModal,
            #autopilotLeadListModal,
            #emailLeadListModal {
                select {
                    width: 100%;
                }

                &.state-inline {
                    .add-item-link {
                        color: $colour-tundora;
                    }
                }

                .add-item-link {
                    margin-top: $publication_general_spacing;
                    color: $colour-white;
                    text-decoration: none;

                    .stack {
                        left: -16px;
                        top: 1px;

                        .icon-plus {
                            font-size: 0.6em !important;
                            left: 3px;
                            top: 0.5px;
                        }
                    }
                }
            }

            .scheduleBroadcast {
              .spacer {
                margin-top: 25px;
                margin-bottom: 25px;
                padding-bottom: 25px;
                border-bottom: solid 2px #d8d8d8;

                &.mt-0 {
                  margin-top: 0;
                }
              }

              h2 {
                font-size: 1.143rem;
                margin-bottom: 17px;
                font-weight: bold;
                margin-top: 0;
              }

              &.scheduleBroadcastConfirmationModal {
                position: relative;
                .back-to-form {
                  position: absolute;
                  bottom: 0;
                  margin-left: 0;
                }
                h2 {
                  margin-top: 25px;
                }
              }

              .time-and-date {
                li {
                  list-style: none;
                  margin: 0;
                }
              }

              .add-item-link {
                color: $colour-gray-dark;
                text-decoration: none;

                .stack {
                  margin: 0 18px 0 0;

                  .stack-outer {
                    font-size: 120%;
                  }

                  .stack-inner {
                    left: 3px;
                  }
                }
                .x075 {
                  color: $colour-white;
                }
              }
            }

            .scheduleBroadcast .time-and-date,
            #emailDateTimeModal {
                .input {
                    padding-bottom: 25px;

                    label {
                        display: block;
                        width: 100%;
                        padding: 0 0 5px;
                    }

                    &.time select {
                        width: 90px;
                        margin: 0 3% 0 0;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }

            #autopilotTimezoneModal {
                select {
                    width: 100%;
                }
            }

            .scheduleBroadcastConfirmationModal,
            #autopilotConfirmationModal {
                .divider {
                    margin: 20px 0;
                }

                .form-hint {
                    padding: 0;
                    background-color: $colour-transparent;

                    .copy {
                        width: 50%;
                    }
                }
            }

            .asset {
                position: relative;
                color: $colour-tundora;

                .asset-loader {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -1em;
                }

                .headline {
                    font-size: $publication_big_text !important;
                    margin: 0 0 5px 0;
                }

                .col-md-11 {
                    .status {
                        font-weight: bold;
                        margin-left: 0px;
                        min-height: auto;
                        span.additional-info {
                            margin-top: 10px;
                            display: inline-block;
                            font-weight: normal;
                        }
                    }
                }

                .status {
                    font-weight: bold;
                    margin-left: 10px;
                    min-height: 50px;
                    max-width: 370px;

                    &.send-info {
                        min-height: auto;
                    }

                    .regular {
                        font-weight: normal;
                        display: block;
                        margin-top: 5px;
                    }

                    a {
                        word-wrap: break-word;
                    }
                }

                .long-status {
                    font-weight: normal;
                }

                .stack:not(.social) .icon-bg-circle {
                    color: $colour-gray;

                    &.error {
                        color: $colour-red;
                    }
                }

                &.disabled .stack.social .icon-bg-circle {
                    color: $colour-gray;
                }

                .content-button {
                    padding-top: 0px;
                    margin-top: -10px;
                    clear: both;
                    line-height: 2.857rem;

                    .dropdown {
                        margin-left: 10px;
                    }
                }

                &.client-main-colour-border {
                    border: solid 2px;
                }

                &.disabled {
                    color: $colour-silver;

                    &:after {
                      content: '';
                      position: absolute;
                      display: block;
                      top: -20px;
                      bottom: -40px;
                      left: -20px;
                      right: -20px;
                    }
                }
            }

            .campaign-stat {
                background: $colour-white;

                @include borderRadius(8px);

                padding: 5px 12px 10px;
                text-align: center;
                width: 210px;
                margin: 0 10px 0 0;
                display: inline-block;
                float: left;

                .header {
                    font-size: 1rem !important;
                    line-height: 1.286rem;
                    margin: 0;
                    height: 36px;
                    width: 100%;
                    display: table;

                    span {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                    }
                }

                p {
                    font-size: 1.714rem !important;
                    margin: 0;
                }

                &.last {
                    margin: 0;

                    h1 {
                        color: $colour-white;
                    }

                    p {
                        color: $colour-white;
                    }

                    .header {
                        color: $colour-white;
                    }
                }

                i.spin:before {
                    padding: 7px 0 8px;
                }
            }

            .templates-status {
                .progress-bar {
                    .percentage {
                        color: $colour-gray-dark;
                    }
                }
            }

            .progress-bar {
                margin-top: 0;
                margin-bottom: 10px;

                .outer {
                    margin-left: 0;
                }

                .percentage {
                    color: $colour-white;
                    font-weight: normal;
                }
            }

            .checking-template {
                .icon {
                    vertical-align: middle;
                }
            }

            .assets {
                border: 1px solid $colour-gray-light-1;
                border-radius: 10px;
                margin-bottom: 30px;

                &.good-assets {
                    h2 {
                        color: $colour-green;
                        background-color: $colour-green-light !important;
                    }
                }

                &.missing-assets {
                    h2 {
                        color: $colour-red;
                        background-color: $colour-red-light !important;
                    }

                    p {
                        margin-bottom: 20px;
                    }
                }

                h2 {
                    line-height: 4.286rem;
                    height: 60px;
                    padding: 10px 20px;
                    margin: 0;
                    font-size: 1.143rem !important;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;

                    .stack {
                        margin-right: 15px;
                        line-height: 4.286rem;
                    }
                }

                .list {
                    margin: 0 30px;

                    .asset {
                        border: 1px solid $colour-gray-light-1;
                        background-color: $colour-gray-lighter;
                        margin-bottom: 10px;
                        padding: 10px;

                        .item-name {
                            a, span {
                                color: $colour-gray-dark;
                                text-decoration: none;
                                font-weight: bold;
                                line-height: 2rem;
                            }
                        }
                    }

                    .missing-items {
                        margin-bottom: 20px;

                        span {
                          font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .thumbnail-error {
        background: $colour-white;
        position: relative;
        min-height: 145px;
        min-width: 120px;

        i {
            &.icon-bg-triangle {
                color: $colour-gray-light-1;
                top: 35px;
            }

            &.icon-exclamation {
                top: 53px;
            }
        }
    }

    .with-or-without-crop-choice {
        margin-top: 20px;

        input {
            width: 1.571rem;
            padding: 0;
            margin: 0;
            position: relative;
            top: 2px;
        }

        b {
            font-weight: bold;
        }

        span {
            margin-left: 26px;
            display: inline-block;
        }
    }

    .static-banner-publish,
    .animated-banner-publish {
        strong {
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
        }

        .publish-embed-code {
            width: 100%;
            border-radius: 0;
            padding: 10px;
            margin-top: 25px;
        }

        .input.radio {
            margin-top: 20px;

            label.long {
                width: auto;
                float: none;
                display: block;
                padding-left: 25px;

                input {
                    float: left;
                    margin-left: -25px;
                }
            }
        }
    }
    campaigns-list .option2 {
        margin-right: 0;
    }
    campaigns-list .option1 .button,
    campaigns-list .option2 .button {
        margin: 0 !important;
    }
    .campaigns-list .button.mini,
    campaigns-list .button.mini {
        margin: 2px !important;
    }

    .right-filtered-content {
        margin-left: 320px;
    }

    .left-bar-box {
        float: left;
        width: 300px;

        .asset-request-box {
            margin-top: 20px;
        }

        .square-box {
            background: transparent;
            margin-bottom: 20px;
            padding: 13px 26px;

            h1 {
                font-size: 1.214rem;
                line-height: 1.25;
                margin: 5px 0 12px;
            }
        }
    }

    .left-filterbar {
        width: 260px;
        padding: 20px;
        background-color: $colour-concrete;

        .input {
            padding-bottom: 10px;
            label {
                display: table;

                i {
                    display: table-cell;
                    vertical-align: top;
                    width: 20px;
                    height: 100%;
                }

                div {
                    display: table-cell;
                    font-weight: bold;
                }
            }
        }

        label.bold {
            font-weight: bold;
            cursor: pointer;
        }

        label.bold, a.clear-filters {
            margin-bottom: 1em;
        }

        a.clear-filters {
            line-height: 1.143rem;
            padding-top: 5px;
            float: right;
            text-align: right;

            &.button.tertiary {
                margin-bottom: 0;
            }
        }

        .input {
            .clear-filters {
                color: $colour-gray-dark;
                font-size: 0.929rem;
            }
        }

        div.inputs {
            background-color: $colour-white;
            padding: 10px 15px;

            .input {
                padding: 0;

                input[type="checkbox"] {
                    display: inline;
                    vertical-align: middle;
                    padding: 0 !important;
                    margin: 0 !important;
                }

                label {
                    display: inline;
                    vertical-align: middle;
                    margin: 0 !important;
                    margin-right: 5px;
                    padding: 0 !important;
                    width: 195px;
                    float: none !important;
                }

            }
        }
    }

    .application-switcher {
        display: flex;
        height: 115px;

        &--no-icons {
            height: 3.929rem;
            margin-bottom: 40px;
        }

        &.details {
            height: 75px;
        }
        .application-tab {
            position: relative;
            flex-basis: 50%;
            flex-grow:1;
            border-style: solid;
            border-color: $colour-gray-88;
            &.last {
                border-width: 1px 1px 1px 0;
            }
            &.first {
                border-width: 1px 0 1px 1px;
            }
            &.active {
                border-width: 1px;
                .border-arrow-wrapper {
                    position: absolute;
                    top: auto;
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    width: 20px;
                    height: 20px;
                    background-color: $colour-white;
                    overflow: hidden;
                    .border-arrow {
                        height: 14px;
                        width: 14px;
                        border: 1px solid $colour-gray;
                        border-radius: 2%;
                        transform: rotate(45deg);
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        bottom: 12px;
                    }
                }
            }


            .app-title {
                position: absolute;
                bottom: 15px;
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: $colour-gray-dark;
                &.details {
                    bottom: 10px;
                }
            }

            .app-icon {
                border-radius: 50%;
                width: 54px;
                height:54px;
                background-color: $colour-black;
                position: absolute;
                top: 15px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;

                &.app-switcher-icon-campaignBuilder {
                    background: $colour-black url("/images/marketing-store/thumb-envelope-bubble.png") no-repeat center center;
                }

                &.app-switcher-icon-marketingStore {
                    background: $colour-black url("/images/marketing-store/hand-window.png") no-repeat center center;
                }

                &.details {
                    top: 10px;
                    width: 27px;
                    height: 27px;
                    background-color: $colour-gray-dusty;
                    text-align: center;
                }

                .items-count {
                    color: $colour-white;
                    font-weight: bold;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .confirmation-page-activity-wrapper {
        margin-bottom: 20px;

        .activity {
            background-color: $colour-concrete;
            position: relative;

            &-overview {
                height: 100px;

                h2 {
                    top: 20px;
                    margin: 0;
                }
            }

            .activity-icon {
                float: left;
                height: 100px;
                width: 100px;
                background: #d8d8d8;
                margin-right: 20px;

                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100px;
                }
            }

            &:not(.activity-overview) {
                height: 50px;

                h2 {
                    top: 50%;
                    transform: translateY(-50%);
                    margin: 0 0 0 20px;
                }
            }

            .activity-short-description {
                position: relative;
                top: 45px;
                height: 45px;
                overflow: hidden;
            }

            h2 {
                display: inline-block;
                position: absolute;
                vertical-align: middle;
                font-weight: bold;
                &.cost {
                    right: 70px;
                }
            }

            a.stack.arrow-down,
            a.stack.arrow-up {
                margin-left: 7px;
            }

            a.stack.x1 {
                margin: 14px 4px 0 6px;
                float: right;
            }

            a.stack.arrow-down {
                display: inline-block;
                height: 3.571rem;
                width: 3.571rem;
                text-align: center;
                background-color: $colour-gray;
                float: right;

                span, i {
                    top: 15px;
                }
            }
        }

        .activity-content {
            > p {
                margin: 20px 0 0 20px;
            }

            .marketing-box-package-header {
                h4:not(.with-checkbox) {
                    margin: 0 !important;
                }
            }
        }

        .marketing-upscales-wrapper {
            border-top: 1px solid #cccccc;
            margin-top: 35px;
            label {
                .text:not(.with-checkbox) {
                    margin: 0 !important;
                }
            }
        }
    }

    .marketing-box-package-body > p {
        margin: 0;
        padding: 0;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    .campaign-elements-selector {
        background: $colour-gray-light-1;
        line-height: 2.5rem;
        margin: -20px 0 30px;

        a {
            color: $colour-gray-dark;
            text-decoration: none;

            &.secondary {
                color: $colour-white;
            }
        }

        & + .content-underheader {
            margin-top: -5px;
        }
    }

    .my-activity-details, .my-campaign-details {
        h5 {
            font-size: 1.143rem;
            font-weight: bold;
        }

        .updated-info {
            margin: 12px 20px 0 0;
            font-style: italic;
        }

        .ms-total {
            border: 1px solid #dddddd;
            .title {
                width: 75%;
            }
            .cost {
                width: 25%;
                border-left: 1px solid #dddddd;
            }
        }
    }

    workflow-rules-display {
        display: block;
        padding-top: $smallPad + $xSmallPad;

        .circle {
            display: inline-block;
            min-width: 1.571rem;
            height: 1.571rem;
            font-size: 1.143rem;
            font-weight: bold;
            line-height: 1.38;
            border-radius: 11px;
            margin-right: 5px;
        }

        .editable-language-form .form-content {
            padding: $smallPad + $xSmallPad;
        }

        .row {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .workflow-rule {
            padding: $smallPad;
            border: 1px solid $colour-gray;

            &.disabled {
                opacity: 0.5;
            }

            & + .workflow-rule {
                margin-top: $smallPad;
            }
        }

        .workflow-rule-label {
            margin-top: $smallPad;
            font-weight: bold;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .campaign-automation {
        border: solid 1px $colour-gray;
        background: $colour-concrete;
        padding: $smallPad;
        vertical-align: middle;
        overflow: hidden;

        p {
            margin: 0;
        }

        .box-header {
            font-size: 1.143rem;
            line-height: 1.929rem;
            margin-bottom: 10px;
        }

        .marketing-checkbox .tick:not(.inline) {
            top: 18px;
        }

        .marketing-box-package .marketing-box-package-header {
            background: $colour-white;
            border-bottom: none !important;
        }

        .campaign-automation-box {
            padding-left: 13px;
            background: $colour-white;
            line-height: 3.571rem;
            margin-top: $smallPad;
        }
    }

    #campaignReportModalForm {
        .input {
            margin-top: 15px;

            > label {
                padding-top: 8px;
            }

            pm-file-uploader {
                width: 95%;
            }
        }
    }

    #myCampaignInstanceReport {
        margin-bottom: 30px;
    }

    .report-expiry {
        font-size: 0.786rem;
        line-height: 1rem;
        width: 50px;
        display: block;
        float: right;
    }

    expiry-date {
        .green {
            color: $colour-green;
        }

        .red {
            color: $colour-red;
        }

        .orange {
            color: $colour-orange-carrot;
        }
    }

    report, report-update {
        .state-modal {
            .modal-window {
                width: 430px;
            }
        }
    }

    #reportsList {
        .filters {
            padding: 1.1em 1.5em;
            margin-bottom: 2em;
            background-color: $colour-concrete;

            form {
                line-height: 1;
            }

            form > div {
                display: inline-block;
                width: 45%;
                margin-right: 4%;
                padding-bottom: 0;
            }

            form label {
                width: 130px;
                float: left;
            }

            form select {
                width: 260px;
                float: right;
            }

            hr {
                margin-top: 1.5em;
            }
        }

        .download-csv-button {
            position: relative;
            top: -55px;
        }
    }
}
info-link {
  position: relative;
}
