@import "../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";

// Constructors
%input {
    font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 1.571rem;
    min-height: 30px;
    padding: 7px 8px;
    border: 1px solid $colour-gray-88;
    color: $colour-gray-dark;
    background-color: $colour-white;
    box-sizing: border-box;
    width: 100%;
    outline: 0;

    @include insetShadow(1px);
    @include borderRadius(4px);

    &.ng-dirty.ng-invalid {
        border: 1px solid $colour-chestnut;

        & + .char-counter {
            border-color: $colour-chestnut;
        }
    }

    &[disabled] {
        opacity: 0.3;
    }

    &[read-only] {
        color: $colour-gray-light;
    }

    &:hover {
    }

    &:focus {
        border-color: $colour-mariner;
        outline: 0;

        & ~ .char-counter {
            border-color: $colour-mariner;
        }
    }

    &.has-char-counter {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.pm-input-icon.icon-cross {
    & + .char-counter {
        border-color: $colour-chestnut !important;
    }
}

// Selectors

// Inputs
input[type="hide"]:not(.pm-ignore-styles) {
    display: none !important;
}

input[type="checkbox"]:not(.pm-ignore-styles), input[type="radio"]:not(.pm-ignore-styles) {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
    margin-left: 2px;
}

[datepicker-popup] {
    padding: 7px 0px 7px 12px;
    line-height: 1.571rem;
    border: 1px solid $colour-gray-88;
    color: $colour-gray-dark;
    background-color: $colour-white;
    width: 110px;
    font-size: 1rem;

    &.imbedded {
        border-radius: 4px;
        width: 50%;
    }
}

input:not([type="submit"]):not([datepicker-popup]):not([type="checkbox"]):not([type="radio"]):not(.pm-ignore-styles), select:not(.pm-ignore-styles), textarea:not(.pm-ignore-styles), .ta-root .ta-editor {
    @extend %input;

    &.search {
        background: url(/images/icons/icon-search.png) no-repeat 12px 12px $colour-alabaster-2;
        padding: 10px 10px 10px 37px;
        width: 200px;
        margin-right: 0;
        &::placeholder {
            color: #c1c4d2;
        }

        @include borderRadius(1.571rem 0 0 1.571rem);
    }

    &.square-input {
        width: 38px;

        &.x2 {
            width: 76px;
        }

        &.x3 {
            width: 116px;
        }
    }
}


// Labels
label:not(.pm-ignore-styles) {
    min-width: 100px;
    display: block;
    float: left;
    line-height: 1.429rem;
    padding: 5px 0 0 0;
    font-weight: normal !important;

    &.inline {
        width: auto;
        display: inline-block;
        float: none;
        vertical-align: top;
        margin-right: 5px;

        &-block {
            display: inline-block !important;
            vertical-align: top !important;
            padding-top: 0 !important;
        }
    }

    span:not(.ignore-span) {
        font-style: italic;
        font-size: 0.857rem;
        color: $colour-gray-dusty;
    }

    span.ignore-span {
        display: inline-block;
        vertical-align: middle;
    }

    &.long-label {
        float: none;
        width: auto;
    }

    &.short {
        max-width: 100px;
        word-wrap: break-word;
    }

    &.password {
        min-width: 130px;
    }

    &.for-multi-select {
        padding-top: 13px;
    }

    &.checkbox-container {
        position: relative;
        padding-left: 30px;

        & > input {
            position: absolute;
            left: 6px;
            top: 11px;
        }
    }

  &.bold {
    font-weight: bold !important;
  }

  &.centered {
    display: flex;
    align-items: center;
    float: left;
  }
}

// Fake labels
div.input:not(.pm-ignore-styles) {
    clear: both;
    overflow: hidden;
    padding-bottom: 25px;

    .input-hint {
        font-size: 0.857rem;
        display: block;
        font-style: italic;
        padding: 3px 0 0 10px;
        clear: both;
        color: $colour-gray-dark;
    }

    &.multiselect-input {
        @extend .clearfix;

        overflow: visible;

        label.long + .float-input {
            width: calc(100% - 180px);
            float: left;
        }

        .float-input {
            overflow: visible;
        }

        .multiSelect {
            display: block;

            & > button {
                display: block;
            }
        }
    }
}

// Floated inputs
.float-input:not(.pm-ignore-styles) {
    margin-top: 0;
    overflow: hidden;

    &.checkbox {
        display: table;
        margin: 0;
    }

    &.radio {
        display: table;
        margin: 0;
    }

    &.checkbox label {
        width: auto;
        padding-top: 3px;
        padding-left: 5px;
    }

    input, textarea {
        float: left;
    }

    input[type="radio"]:not(.pm-ignore-styles) {
        margin-top: 0;
    }
}

.standard-input {
    min-height: 34px;
}

// Forms
form:not(.pm-ignore-styles) {
    // Base form type
    &.base {
        position: relative;

        div.input .ta-root .ta-editor {
            margin-top: 4px;
            height: auto;

            .ta-bind {
                margin: 0;
                min-height: 200px;
                max-height: 400px;
                outline: 0;
            }
        }

        &.set-up {
            .form-hint {
                float: right;
                width: 340px;
            }
        }
    }

    // Static form type
    &.static-box-form {
        button[type="submit"],
        input[type="submit"] {
            float: right;
        }

        div.checkbox {
            margin-left: 70px;
            width: 50%;
            float: left;
        }

        div.checkbox .checkbox-input {
            float: left;
            width: 10px;
            height: 10px;
        }

        div.checkbox label {
            width: 80%;
            float: left;
            margin-left: 5px;
            line-height: 1.429rem;
        }

        #recaptcha_widget_div {
            @extend .clearfix;
        }

        #recaptcha_area, #recaptcha_table {
            line-height: 0 !important;
            table-layout: auto;
        }

        #recaptcha_area {
            margin-bottom: 10px;
            float: right;
        }
    }

    // Set input invalid state even though inputs not $dirty
    &.ng-submitted {
        .ng-invalid:not(ng-form) {
            border: 1px solid $colour-chestnut !important;

            &[isteven-multi-select] {
                border: none !important;

                .multiSelect > button {
                    border: 1px solid $colour-chestnut !important;
                }
            }

            &[pm-rich-text] {
                border: none !important;

                .ta-root .ta-editor {
                    border: 1px solid $colour-chestnut !important;
                }
            }
        }
    }

    // Form helper
    span.help-inline {
        color: $colour-chestnut;
    }

    // This needs removing at some point but right now no alternative
    .long {
        width: 160px;
    }

    .longer {
        width: 275px;
    }

    &.tag-search {
        .long {
            width: 210px;
        }
    }

    div.input + hr + div.input {
        margin-top: 20px;
    }
}

// Char counter
.char-counter {
    position: relative;
    float: right;
    text-align: right;
    background-color: $colour-gray-light;
    color: $colour-gray-2;
    margin: -1px 0 0;
    width: 100%;
    border: 1px solid $colour-gray-light;
    border-top: none;
    box-sizing: border-box;

    @include borderRadius(0 0 4px 4px);

    div {
        padding: 5px 10px 4px 0;
    }
}


.ta-root textarea[id^='taHtmlElement'] {
    display: none !important;
}

.ta-root .ta-editor, #limitedTextarea {
    margin-top: 0;
    margin-bottom: 0;
    resize: none;
    overflow: auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 6em;

    @include borderRadius(4px 4px 0 0);
}

.ta-root .ta-editor {
    @include borderRadius(4px);
}

.ta-root .ta-toolbar {
    .btn {
        background-color: $colour-gray-lighter;
        padding: 4px 9px;
    }

    .btn.active,
    .btn:hover {
        background-color: $colour-gallery;
    }
}

.btn-toggle {
    background-color: $colour-gray-lighter;
    border: 1px solid $colour-gray-light-1;
    border-radius: 0;
    outline: 0 !important;

    &.first {
        border-radius: 5px 0px 0px 5px;
        border-right: 0px;
    }

    &.last {
        border-radius: 0px 5px 5px 0px;
        border-left: 0px;
    }
}

.form-header-box {
    overflow: hidden;
    background-color: $colour-gray-lighter;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid $colour-gray-light-1;

    h2 {
        font-size: 1.143rem;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .columns-row {
        padding: 0;
    }
}

.checkbox-set {
    overflow: hidden;
    margin-bottom: 30px;

    h3 {
        font-size: 1.143rem;
        padding: 10px 25px;
    }

    .col-md-4 {
        padding-left: 0;
    }

    .col-md-12 > span > input {
        margin-right: 4px;
    }
}

form .row > [class^="col-md-"] >, form .row > [class^="col-md-"] > span > {
    input {
        vertical-align: top;
    }
}

/* form hints */

.form-hint {
    background-color: $colour-gray-lighter;
    padding: 15px;
    overflow: hidden;

    @include borderRadius(4px);

    &.margin {
        margin-top: 20px;
    }

    div.icon-holder {
        float: left;
        width: 20px;
        margin: 0 15px 0 0;
    }

    div.copy {
        float: left;
        width: 80%;
    }

    div.info-line {
        min-height: 15px;

        h4 {
            font-weight: 700;
            margin: 0;
        }

        span.info {
            font-style: italic;
        }
    }

    strong {
        font-weight: bold;
    }
}

.form-hint-dark {
    padding: 15px 15px 15px 60px;
    overflow: hidden;
    color: $colour-white;
    background-color: $colour-ebony-clay;
    margin-bottom: 30px;

    .stack {
        float: left;
        margin-left: -45px;
    }
}

/* Form Validation Errors */

.error-message {
    &:not(.pm-ignore-styles), &.dont-ignore-styles {
        position: relative;
        background-color: $colour-red-persian;
        color: $colour-white;
        font-size: 0.857rem;
        padding: 4px 10px;

        & a {
            color: $colour-white !important;
            text-decoration: underline;
        }

        @include borderRadius(4px);

        &:before {
            content: '';
            display: block;
            position: absolute;
            border: 7px outset $colour-transparent;
        }

        &.position-below {
            display: inline-block;
            margin: 10px 0 0 0;

            &.label-offset {
                margin-left: 110px;
            }

            &:before {
                left: 22px;
                top: -13px;
                border-bottom: 7px solid $colour-red-persian;
            }
        }

        &.position-right {
            float: right;

            &:before {
                left: -14px;
                top: 6px;
                border-right: 7px solid $colour-red-persian;
            }
        }

        &.margin-bottom {
            margin-bottom: 10px
        }
    }
}

select {
    &:not(.pm-ignore-styles), &.dont-ignore-styles {
        display: inline-block;

        @extend .form-control;

        &.ng-invalid.ng-dirty {
            border: 1px solid $colour-chestnut;
        }

        &.multi-select {
            min-height: 150px;
        }
    }
}

label:not(.pm-ignore-styles) {

    &.label-responsive {
        max-width: none !important;
        width: 100%;
        margin-left: -16px;
        padding-left: 25px;
        box-sizing: border-box;
    }

    &.disabled {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        opacity: 0.5;
        color: lighten($colour-gray-dark, 20%);
    }

    input[type="checkbox"] + span:not(.variable-width) {
        width: 230px;
        display: inline-block;
        vertical-align: middle;
    }

    .full-width {
        width: 100%;
    }
}

.input-prefixed:not(.pm-ignore-styles) {
    display: flex;

    .input-prefix {
        font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;
        padding: 7px 8px;
        border: 1px solid $colour-gray-88;
        color: $colour-gray-dark;
        background: $colour-gray-light-1;
        @include borderRadiusPartial(0, 0, 4px, 4px);
    }

    input {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
}

.input-validated:not(.pm-ignore-styles) {
    position: relative;
    display: block;
    padding-right: 20px;

    .pm-input-icon {
        position: absolute;
        right: 0;
        top: 6px;
        font-size: 0.857rem;

        &.icon-cross {
            color: $colour-valencia;
        }

        &.icon-tick {
            color: $colour-fern-2;
        }

        &.icon-asterisk {
            top: 11px;
            right: 2px;
            color: $colour-gray-dusty;
            font-size: 1.429rem;
        }
    }

    &.no-padding {
        padding-right: 0px;
    }

    &.multi-select {
        display: block;
        position: static !important;
    }

    &.client-10pc-colour-background, &.client-20pc-colour-background {
        select, input, textarea {
            width: 245px !important;
        }

        .pm-input-icon {
            right: auto !important;
            left: 270px;
            top: 20px;
        }
    }

    &.client-20pc-colour-background {
        select, input, textarea {
            width: 320px !important;
        }

        .pm-input-icon {
            left: 344px;
            top: 15px;
        }
    }

    [limited-textarea] .pm-input-icon {
        top: 0 !important;
    }

    [pm-rich-text] .pm-input-icon {
        top: 30px !important;
    }

    .pm-input-icon-container {
        width: 100%;
        overflow: visible;
        position: relative;
        height: 0;
        line-height: 0;

        i {
            right: -20px !important;
            top: -23px !important;
        }
    }
}

.publish-embed-code {
    @extend %input;
}

.toggleDivider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    hr {
        width: 100%;
        height: 1px;
        display: inline-block;
        border-top: 1px solid $colour-gray-light-1;
    }

    a {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        margin-left: 10px;

        i {
            top: 10px;
        }
    }
}

.tab-switcher {
    display: flex;
    margin-bottom: 30px;

    .tab-item {
        width: 50%;
        border: 1px solid #cccccc;
        height: 50px;
        padding: 5px;
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
        position: relative;

        .icon-holder {
            margin-bottom: 5px;
        }

        &:after, &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &.active:after {
            border-width: 10px;
            margin-left: -10px;
        }

        &.active:before {
            border-width: 11px;
            margin-left: -11px;
        }
    }

    .tab-item:first-child {
        border-right-color: #ffffff;
    }

    .tab-item:last-child {
        border-left-color: #ffffff;
    }
}

.application-switcher {
    display: flex;
    height: 115px;

    &--no-icons {
        height: 3.929rem;
        margin-bottom: 40px;
    }

    &.details {
        height: 75px;
    }

    .application-tab {
        position: relative;
        flex-basis: 50%;
        flex-grow: 1;
        border-style: solid;
        border-color: $colour-gray-88;

        &.last {
            border-width: 1px 1px 1px 0;
        }

        &.first {
            border-width: 1px 0 1px 1px;
        }

        &.active {
            border-width: 1px;

            .border-arrow-wrapper {
                position: absolute;
                top: auto;
                bottom: -20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 20px;
                height: 20px;
                background-color: $colour-white;
                overflow: hidden;

                .border-arrow {
                    height: 14px;
                    width: 14px;
                    border: 1px solid $colour-gray;
                    border-radius: 2%;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    bottom: 12px;
                }
            }
        }


        .app-title {
            position: absolute;
            bottom: 15px;
            width: 100%;
            text-align: center;
            font-weight: bold;
            color: $colour-gray-dark;

            &.details {
                bottom: 10px;
            }
        }

        .app-icon {
            border-radius: 50%;
            width: 54px;
            height: 54px;
            background-color: $colour-black;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;

            &.app-switcher-icon-campaignBuilder {
                background: $colour-black url("/images/marketing-store/thumb-envelope-bubble.png") no-repeat center center;
            }

            &.app-switcher-icon-marketingStore {
                background: $colour-black url("/images/marketing-store/hand-window.png") no-repeat center center;
            }

            &.details {
                top: 10px;
                width: 27px;
                height: 27px;
                background-color: $colour-gray-dusty;
                text-align: center;
            }

            .items-count {
                color: $colour-white;
                font-weight: bold;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
