@import "../../../../vendor/font-awesome/scss/font-awesome";

// Constructors
%text {
    font-size: 1rem;
    line-height: 1.5rem;
}

%heading {
    margin: 10px 0 0 0;
}

// Initial font size.
html {
    font-size: 14px;
}

// Selectors
body {
    @extend %text;

    color: $colour-gray-dark;
}

body.global-admin-wrapper {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

    h1, h3, h3, h4, h5, h6 {
        &:not(.pm-ignore-styles) {
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        }
    }
}

#pmContent {
    // Wrap typography so it's not applied to templates

    .text-no-decoration {
        text-decoration: none;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .text-capitalize {
        text-transform: capitalize;
    }

    em {
        font-style: italic;
    }

    a {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    h1, h3, h3, h4, h5, h6 {
        @extend %heading;
    }

    h2 {
        margin: 0 0 30px 0;

        &.seperator {
            margin: 0 0 10px 0;
            width: 100%;
            text-align: center;
            border-bottom: 2px dotted $colour-gray;
            line-height: 0.1em;
            margin: 40px 0 20px;

            span {
                background-color: $colour-white;
                padding: 10px;

                &.title {
                    color: $colour-white;
                    padding: 10px 20px;
                    border-radius: 18px;
                    vertical-align: middle;
                    font-size: 1rem;
                }
            }

            &.transparent {
                border-bottom-color: transparent;
            }
        }
    }

    h2.formatted {
        font-weight: bold;

        &.small-margin-bottom {
            margin-bottom: 5px;
        }
    }

    h3:not(.formatted) {
        background-color: $colour-gray-lighter;
        border: 1px solid $colour-gray-light-1;
        padding: 10px;
        margin-bottom: 30px;
    }

    h3.formatted {
        font-size: 1rem;
        font-weight: bold;
    }

    h4 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0 0 30px 0;
    }

    .info-line h4 {
        font-weight: bold;
        margin-bottom: 5px;
    }

    h6 {
        border-bottom: 1px solid $colour-gray-light-1;
        color: $colour-gray-dark;
        font-size: 1.143rem;
        font-weight: normal;
        padding-bottom: 10px;
        margin: 0 0 30px 0;

        &.text-bold {
            font-weight: bold;
        }
    }

    p.standalone {
        margin-top: 15px;
    }
}
