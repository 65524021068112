/*!
 * Bootstrap v3.0.3
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 *
 */
.dropdown {
    position: relative;
}

.dropdown-toggle:focus {
    outline: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 1rem;
    background-color: $colour-white;
    -webkit-box-shadow: 0 6px 12px $colour-black-transparent-175;
    box-shadow: 0 6px 12px $colour-black-transparent-175;
    background-clip: padding-box;
}

[datepicker-popup] + .dropdown-menu {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        margin: 0;

        table {
            outline: none;
        }
    }
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: $colour-mercury-2;
}

.dropdown-menu > li > a {
    display: block !important;
    padding: 3px 20px !important;
    clear: both !important;
    line-height: 1.428571429 !important;
    color: $colour-mine-shaft;
    white-space: nowrap !important;
}

#pmContent .dropdown-menu > li > a {
    color: $colour-mine-shaft;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    text-decoration: none;
    color: $colour-white;
    background-color: $colour-wild-sand;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: $colour-white !important;
    text-decoration: none !important;
    outline: 0 !important;
    background-color: $colour-blue-boston !important;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: $colour-gray-dusty;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: $colour-transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    cursor: not-allowed;
}

.open > .dropdown-menu {
    display: block;
}

.open > a {
    outline: 0;
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 0.857rem;
    line-height: 1.428571429;
    color: $colour-gray-dusty;
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}

.pull-right > .dropdown-menu {
    right: 0;
    left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px solid;
    content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }
}
