[ui-view="tour"] {
    .tour {
        position: relative;
        background-color: $colour-mine-shaft;
        width: 290px;
        color: $colour-gray;
        font-size: 1.071rem;
        line-height: 1.429rem;
        font-weight: normal;
        margin: 0;
        padding: 20px;

        @include borderRadius(4px);

        display: table;

        &:before {
            content: '';
            display: block;
            position: absolute;
            border: 10px outset $colour-transparent;
        }

        &.position-right {
            &:before {
                left: -20px;
                top: 45%;
                border-right: 10px solid $colour-mine-shaft;
            }
        }

        &.position-below {
            &:before {
                left: 135px;
                top: -20px;
                border-bottom: 10px solid $colour-mine-shaft;
            }
        }

        &.position-above {
            &:before {
                left: 135px;
                bottom: -20px;
                border-top: 10px solid $colour-mine-shaft;
            }
        }

        &.position-left {
            &:before {
                right: -20px;
                top: 45%;
                border-left: 10px solid $colour-mine-shaft;
            }
        }

        h1 {
            font-size: 1.429rem;
            padding: 5px 0 0 0;
            margin: 0;
            color: $colour-white;
        }

        p {
            margin-bottom: 10px;
        }

        .icon-holder {
            float: left;
            color: $colour-white;
            margin: 0 10px 10px 0;
        }

        a {
            float: right;
            cursor: pointer;
        }

        a.text-link {
            font-size: 0.857rem;
            color: $colour-gray-2;
            text-decoration: underline;
            margin: 5px 10px 0 0;
        }
    }
}
