.hide-text {
    font: 0/0 a;
    color: $colour-transparent;
    text-shadow: none;
    background-color: $colour-transparent;
    border: 0;
}

.input-block-level {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.carousel {
    position: relative;
    margin-bottom: 20px;
    line-height: 1;
}

.carousel-inner {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.carousel-inner > .item {
    display: none;
    position: relative;
    -webkit-transition: .6s ease-in-out left;
    -moz-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left;
    width: 100%;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
    display: block;
    line-height: 1;
    max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
    display: block;
}

.carousel-inner > .active {
    left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner > .next {
    left: 100%;
}

.carousel-inner > .prev {
    left: -100%;
}

.carousel-inner > .next.pull-left,
.carousel-inner > .prev.pull-right {
    left: 0;
}

.carousel-inner > .active.pull-left {
    left: -100%;
}

.carousel-inner > .active.pull-right {
    left: 100%;
}

.carousel-control {
    position: absolute;
    top: 40%;
    left: 15px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    font-size: 4.286rem;
    font-weight: 100;
    line-height: 0.5;
    color: $colour-white;
    text-align: center;
    background: $colour-mine-shaft-2;
    border: 3px solid $colour-white;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 23px;
    opacity: 0.5;
    filter: alpha(opacity = 50);
}

.carousel-control.pull-right {
    left: auto;
    right: 15px;
}

.carousel-control:hover,
.carousel-control:focus {
    color: $colour-white;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity = 90);
}

.carousel-nav {
    position: absolute;
    bottom: -25px;
    width: 100%;
}

.carousel-indicators {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.carousel-indicators li {
    display: inline;
    list-style: none;
    margin: 0 5px;
    cursor: pointer;
    background: $colour-white;

    @include borderRadius(10px);

    padding: 0 8px;

    &.active {
        background: $colour-green-persian;
    }
}

.carousel-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background: $colour-mine-shaft;
    background: $colour-black-transparent-75;
}

.carousel-caption h4,
.carousel-caption p {
    color: $colour-white;
    line-height: 1.429rem;
}

.carousel-caption h4 {
    margin: 0 0 5px;
}

.carousel-caption p {
    margin-bottom: 0;
}
