// @todo after bootstrap including remove this
//
// Tooltips
// --------------------------------------------------
#template,
#pmContent {
    $zindex-tooltip: 1070 !default;
    $font-size-base: 14px !default;
    $font-size-small: ceil($font-size-base * 0.85) !default;
    $tooltip-opacity: 0.9 !default;
    $tooltip-arrow-width: 5px !default;
    $tooltip-max-width: 200px !default;
    $tooltip-color: #fff !default;
    $tooltip-bg: #000 !default;
    $border-radius-base: 4px !default;
    $tooltip-arrow-color: $tooltip-bg !default;
    $line-height-base: 1.42857 !default;

    .tooltip {
        position: absolute;
        z-index: $zindex-tooltip;
        display: block;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-break: auto;
        line-height: $line-height-base;
        text-align: left;

        // Fallback for where `start` is not supported
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        white-space: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        font-size: $font-size-small;
        opacity: 0;
        filter: drop-shadow(0px 2px 1px #c1c4d2);

        &.in {
            opacity: $tooltip-opacity;
        }

        &.top {
            margin-top: -3px;
            padding: $tooltip-arrow-width 0;
        }

        &.pull-right {
            margin-left: 3px;
            padding: 0 $tooltip-arrow-width;
        }

        &.bottom {
            margin-top: 3px;
            padding: $tooltip-arrow-width 0;
        }

        &.pull-left {
            margin-left: -3px;
            padding: 0 $tooltip-arrow-width;
        }

        &.tertiary {
            .tooltip-inner {
                background: $colour-gray;
                color: $colour-tundora;
            }
        }

        &.min-150 {
            min-width: 150px;
        }

        &.min-200 {
            min-width: 200px;

            div.tooltip-inner {
                max-width: 200px;
                text-align: left;
                font-weight: bold !important;
            }
        }

        &.min-300 {
            min-width: 300px;

            .tooltip-inner {
                max-width: 300px;
            }
        }

        &.group-list-tooltip {
            .tooltip-inner {
                padding: 10px 15px;
                text-align: left;
                line-height: 1.5;
                font-weight: bold;
            }
        }
    }

    .tooltip-inner {
        max-width: $tooltip-max-width;
        padding: 3px 8px;
        color: $tooltip-color;
        text-align: center;
        background-color: $tooltip-bg;
        border-radius: $border-radius-base;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .tooltip {
        &.top .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
            border-top-color: $tooltip-arrow-color;
        }

        &.top-left .tooltip-arrow {
            bottom: 0;
            right: $tooltip-arrow-width;
            margin-bottom: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
            border-top-color: $tooltip-arrow-color;
        }

        &.top-right .tooltip-arrow {
            bottom: 0;
            left: $tooltip-arrow-width;
            margin-bottom: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
            border-top-color: $tooltip-arrow-color;
        }

        &.pull-right .tooltip-arrow {
            top: 50%;
            left: 0;
            margin-top: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
            border-right-color: $tooltip-arrow-color;
        }

        &.pull-left .tooltip-arrow {
            top: 50%;
            right: 0;
            margin-top: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-left-color: $tooltip-arrow-color;
        }

        &.bottom .tooltip-arrow {
            top: 0;
            left: 50%;
            margin-left: -$tooltip-arrow-width;
            border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-bottom-color: $tooltip-arrow-color;
        }

        &.bottom-left .tooltip-arrow {
            top: 0;
            right: $tooltip-arrow-width;
            margin-top: -$tooltip-arrow-width;
            border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-bottom-color: $tooltip-arrow-color;
        }

        &.bottom-right .tooltip-arrow {
            top: 0;
            left: $tooltip-arrow-width;
            margin-top: -$tooltip-arrow-width;
            border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-bottom-color: $tooltip-arrow-color;
        }

        &.tertiary {
            &.top, &.top-left, &.top-right {
                .tooltip-arrow {
                    border-top-color: $colour-gray;
                }
            }
            &.bottom, &.bottom-left, &.bottom-right {
                .tooltip-arrow {
                    border-bottom-color: $colour-gray;
                }
            }
            &.pull-right .tooltip-arrow {
                border-right-color: $colour-gray;
            }
            &.pull-left .tooltip-arrow {
                border-left-color: $colour-gray;
            }
        }
    }

    .white-tooltip {
        >.tooltip-cell-wrapper {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .tooltip {
            .tooltip-arrow {
                transform: rotate(45deg);
                box-shadow: 1px 1px rgba(204, 204, 204, 0.7);
                border: 1px solid;
                border-top-color: $colour-white;
                border-right-color: #d9d9d9;
                border-bottom-color: $colour-white;
                border-left-color: $colour-white;
                background-color: $colour-white;
                width: 10px;
                height: 10px;
            }
            .tooltip-inner {
                max-width: none;
                font-weight: bold;
                color: $colour-gray-dark;
                background-color: $colour-white;
                border: solid 1px #d9d9d9;
                box-shadow: 1px 1px rgba(204, 204, 204, 0.7);
            }
        }
    }
}
