#pmContent {
    [pm-rich-text][max-chars] {
        [text-angular] {
            position: relative !important;
            padding-bottom: 45px;
        }

        .char-counter {
            position: absolute !important;
            z-index: 998;
            width: auto !important;
            padding: 7px 8px;
            margin: 0 !important;
            bottom: 1px;
            left: 0;
            right: 0;
            box-sizing: border-box;

            .char-counter-invalid {
                color: $colour-chestnut !important;
            }
        }

        .ta-root .ta-editor {
            border-radius: 4px 4px 0 0;
        }
    }

    form .ng-invalid > text-angular > .ta-editor,
    [pm-rich-text][max-chars] > [text-angular].ng-invalid {
        & > .char-counter,
        & > .ta-editor {
            border: 1px solid $colour-chestnut !important;
        }
    }
}
