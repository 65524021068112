#pmContent {
    #listSelect {
        border-bottom: 1px solid $colour-gray;
        border-top: 1px solid $colour-gray;
        padding-top: 30px;
        margin-bottom: 20px;
        clear: both;

        .ui-select-wrapper {
            width: 425px;
        }
    }

    .subtitle {
        font-weight: bold;
        margin-top: -20px;
        margin-bottom: 16px;
        font-size: 1.143rem;
    }

    #lead-info {
        margin-bottom: 30px;
        position: relative;
        padding-left: 55px;

        i {
            position: absolute;
            left: 10px;
            top: 12px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .button-container {
        padding: 20px 20px 0 0;
    }

    table {
        td {
            &.lead-checkbox {
                text-align: center;
            }
        }
    }

    .user-invite-multiple,
    .lead-add-page {
        h3 {
            border: none;
            background: none;
            margin: 0 0 10px 0;
            padding: 0;
            font-weight: bold;
        }

        .breadcrumbs {
            height: 20px;
            margin: 30px 0 40px;
        }

        .rounded-box, .square-box {
            padding: 20px;
        }

        .rounded-box {
            border-radius: 6px;
        }

        .example-csv {
            padding: 10px 10px 10px 60px;
            margin-bottom: 20px;

            // The button is 26px high.
            min-height: 26px;
            position: relative;

            i.stack {
                margin-left: -40px;
                float: left;
            }
        }

        table {
            &.borderless {
                tr {
                    border: none;

                    td {
                        border: none;
                    }
                }
            }

            tr {
                th {
                    background-color: $colour-gray-lighter;
                }

                td {
                    background-color: $colour-transparent;
                }
            }
        }
    }

    .lead-add-page .submit_status_message {
        border: 1px solid $colour-gray;

        @include borderRadius(6px);

        overflow: hidden;
        margin-bottom: 30px;

        div.input {
            padding-bottom: 0;
        }

        p.error {
            font-weight: bold;
        }

        span.error-note {
            font-style: italic;
        }

        td {
            vertical-align: middle;
        }

        .error-container {
            background-color: $colour-red-light;
            color: $colour-red;
            padding: 20px;
        }

        .success-container {
            background-color: $colour-green-light;
            color: $colour-green;
            padding: 20px;
        }

        .warning-container {
            background-color: $colour-yellow-light;
            color: $colour-yellow;
            padding: 20px;
        }

        .stack.margin-right {
            margin-right: 15px;
        }

        .stack.margin-left {
            margin-left: 15px;
        }

        h3 {
            border: none;
            margin-bottom: 0;
            background: none;
            display: inline;
            position: relative;
            top: 4px;
            font-size: 1.071rem;

            &.error {
                color: $colour-red;
            }

            &.success {
                color: $colour-green;
            }

            &.warning {
                color: $colour-yellow;
            }
        }

        .submit_messages {
            padding: 20px 20px 20px 30px;
            line-height: 2.1;
        }

        .table-wrapper {
            padding: 0 30px;
        }

        .footer {
            border-top: 1px solid $colour-gray;
            overflow: hidden;
            clear: both;
            margin: 0 20px;
            padding: 20px 0 0 0;

            a {
                float: right;
                margin-left: 12px;
                font-size: 1rem;
            }
        }
    }

    span.big-number {
        font-size: 1.571rem;
        font-weight: bold;
    }

    pre.lead-format-hint {
        color: $colour-gray;
        position: relative;
        bottom: -30px;
        left: 10px;
        font-family: monospace;
        line-height: 1.571rem;
        font-size: 1rem;
    }

    #leadListForm {
        textarea {
            height: 150px;
            padding-top: 32px;

            &.expanded {
                height: 300px;
            }
        }
    }

    p.or-text {
        text-align: center;
        font-size: 1.286rem;
        font-weight: bold;
        margin-bottom: 5px;
        background-color: $colour-white;
        z-index: 2;
    }

    hr.or-text {
        position: relative;
        top: -25px;
        z-index: 1;
        border-style: dashed none none;
        border-top-width: 2px;
        width: 45%;
        background: $colour-gray-light;
    }

    .browse-column {
        display: table-cell;
        vertical-align: middle;
    }

    .lead-add-page .right-col {
        font-size: 0.857rem;

        h4 {
            margin: 0 0 5px 0;
            padding-left: 40px;
            position: relative;

            .stack {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:before {
                display: block;
                content: " ";
                height: 33px;
                width: 1px;
                float: left;
            }
        }

        ul {
            clear: both;
        }
    }

    #editLeadList {
        .content-header {
            input {
                min-width: 500px;
                margin-top: 10px;
            }
        }

        table .col-actions {
            width: 76px;
        }
    }

    div.center-notice {
        margin: auto;
        text-align: center;

        p {
            text-align: center;
            font-size: 1.143rem;
            padding: 20px 0;

            &.no-items {
                font-size: 1rem;
            }
        }
    }

    form.edit-lead {
        &.base {
            div.input {
                .long {
                    width: 110px;
                }
            }
        }

        .input.time {
            select {
                width: 90px;
                width: 0 3% 0 0;
            }
        }

        h1.landing-page-form-fill-data {
            margin-bottom: 1em;
        }

        div.landing-page-form-fill-data {
            table {
                table-layout: auto;
            }

            th {
                cursor: auto;
                font-weight: bold;

                .button {
                    font-weight: normal;
                }
            }

            tbody th {
                width: 150px;
            }
        }
    }

    multi-lead-list-select {
        display: block;
        border: 1px solid $colour-gray;

        .outline {
            padding: 10px;
        }

        ul.outline {
            padding-bottom: 0;
        }

        ul.typeahead {
            right: 26px;

            li {
                margin: 0;
                padding: 0;
            }
        }

        ul.pending {
            padding-top: 0;

            li {
                background: $colour-table-row-odd;
            }
        }

        ul {
            margin: 0;

            li {
                list-style-type: none;
                padding: 3px 6px;

                .stack {
                    top: 5px;
                    left: 10px;
                    width: 13px;
                    line-height: 1;
                }
            }
        }

        span.remove {
            cursor: pointer;

            .icon-cross {
                font-size: 0.4em;

                &:before {
                    line-height: 1.8;
                }
            }
        }

        .action-bar {
            text-align: right;
            border-top: 1px solid $colour-gray;
            background: $colour-table-row-odd;
            overflow: hidden;

            .button {
                margin: 0 0 0 5px;
            }
        }

        // @todo - figure out styling for selected lists.
        // The old select2 styling doesn't work because our lead list names are so long.
        // Jon has said the current unstyled look is fine for now.

    }

    table.data-sample {
        tr {
            background: $colour-transparent;
        }

        tr:nth-child(2n+1) {
            background: $colour-table-row-odd;
        }

        tr:last-child {
            td:after {
                content: " ";
                display: block;
                overflow: hidden;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkE4RUNFMzRCNUVDMTFFNEJCM0E5RDNFNUQyNjY2QUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkE4RUNFMzVCNUVDMTFFNEJCM0E5RDNFNUQyNjY2QUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGQThFQ0UzMkI1RUMxMUU0QkIzQTlEM0U1RDI2NjZBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGQThFQ0UzM0I1RUMxMUU0QkIzQTlEM0U1RDI2NjZBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PrPX+hYAAADtSURBVHjarNK9DoIwEAfwo1hIDAZI7Kibzvp47j6WiTExfjwJTLCwMdT/EZrU8I1e0nA9ej9KU0qS5IwREkJr/dOAo4TjOCeMB6N40txI01Sh/y2UUsLzvD0m97lojb18398KBEVRtLDQ1RQU2Brrn8A2cFzBRQYw8Wr0AnQ5BgXGf3TjnaFfck2YlxZ6QH4dQmvsAWxnsC9wCtqFNUAblVIe21CD8fGEYSgb/ZovUEtwOc/zKo/juPoQ17IsI9d1CRi17b4TtFFuZIDzPmwQtNGyLAln1ouNAg1aFAUFQUBD12kUOCUE/Tk+AgwAA1in4VIePrUAAAAASUVORK5CYII=) 0 0 repeat-x $colour-white;
                height: 14px;
                margin: 11px -11px -11px;
                position: relative;
            }
        }
    }

    .markup-table {
        margin-top: 20px;

        > tbody > tr,
        > tbody > tr > td {
            padding: 0;
            border: none;
            background: $colour-transparent;
        }

        > tbody > tr {
            border-bottom: 30px solid $colour-white;
        }

        .column-data {
            width: 61%;
            vertical-align: top;

            > table {
                margin-bottom: 0;
            }
        }

        .column-arrow {
            width: 10%;
            vertical-align: middle;
            text-align: center;

            .arrow {
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 30px solid $colour-transparent;
                border-bottom: 30px solid $colour-transparent;
                border-left: 30px solid $colour-gray-light-1;
            }
        }

        .column-match {
            vertical-align: top;
            width: 30%;
            padding: 20px;
            background-color: $colour-table-row-odd;
        }
    }

    table#listTable {
        .col-actions {
            width: 107px;
        }
    }

    table#leadsTable {
        .col-actions {
            width: 76px;
        }
    }
}
