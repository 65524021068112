#pmContent {
    .font-upload {
        .upload-files-text {
            font-weight: bold;
            font-size: 1.2em;
            margin-bottom: 1.2em;
        }
    }

    [text-angular] strong {
        font-weight: bold;
    }

    [text-angular] u {
        text-decoration: underline;
    }

    .font-details {

        .float-input:not(.pm-ignore-styles) {
            overflow: visible;
            width: 30%;
            float: left;
        }

        label {
            &.horizontal-margin-left {
                padding-left: $mediumPad;
            }
        }
    }
}
