// Text angular overwrites
.ta-hidden-input {
  width: 1px;
  height: 1px;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  overflow: hidden;
}

.client-versapartner {
  #angular-root #pmContent {
    &.client-logged-out {
      .client-helpdesk {
        background: transparent url(https://files.partnermarketing.com/dashboard-images/versapay/icon-quest.svg) no-repeat 20px 20px;
        background-size: 48px;
        background-color: #002233;
        padding: 20px 20px 20px 88px;

        h2 {
          color: #fff;
          font-size: 20px;
          line-height: 1.2;
          margin: 0 0 8px 0;
        }

        p {
          color: #fff;
          margin: 0;
        }

        a {
          color: #ffa101;
        }
      }
    }

    .login-only {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .rounded-box {
      border-radius: 0;
    }

    .static-boxes {
      min-height: 400px;
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-image: url('/images/login-versapay-bg.png');
      background-color: #0055cc;

      .signup {
        display: none;
      }

      .login,
      &.forgot-password,
      &.set-password {
        border: 0;
        padding-right: 0;

        .response-error-text,
        label {
          color: #ffffff !important;
          a {
            color: #ffffff !important;
            text-decoration: underline;
          }
        }

        .sucess-reset,
        .platform {
          display: block;
        }

        .static-box-form,
        .sucess-reset {
          .button,
          .icon {
            background: #cccccc !important;
            color: #4D4D4D !important;
          }
        }

        .sucess-reset {
          .button {
            display: inline-block;
            float: none;
            margin: 20px 0 0 0;
          }
        }
      }

      &.forgot-password,
      &.set-password {
        justify-content: flex-start;
        align-items: flex-start;

        .platform {
          margin-bottom: 20px;
        }

        .col-md-6 {
          padding: 0;
          max-width: 42%;
          .description {
            max-width: 80%;
            margin-bottom: 20px;
          }

          form.static-box-form {
            .button {
              &.primary {
                .icon {
                  color: #6b778c !important;
                }
              }
            }
          }
        }

        .form-hint,
        .login-link {
          display: none;
        }

        p.highlight {
          display: inline;
          font-weight: bold;
        }

        p.padding-bottom {
          padding-bottom: 10px;
        }

        .loading-container {
          .loading {
            background-color: #0055cc;
          }
        }
      }

      &.forgot-password {
        form.static-box-form {
          .input {
            label {
              display: none;
            }
          }
        }
      }

      & > div {
        max-width: 48%;
      }

      h2 {
        font-size: 2.571rem;;
        font-weight: 600;
        line-height: 1.11;
      }

      h2.client-main-colour {
        display: none;
      }

      h2 + p {
        margin-top: 0;
      }

      h2, h5, a, p {
        color: #ffffff;
      }

      h5 {
        font-size: 1rem;
        margin-bottom: 25px;
        font-weight: bold;
      }

      label.password,
      label.signup-email {
        color: #ffffff;
      }

      label.signup-email {
        float: none;
      }

      .input i {
        color: #ffffff;
      }

      button {
        float: right;
      }
      &.login-signup {
        .button {
          background: #cccccc !important;
          color: #4D4D4D !important;
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
}

.client-versapartner, .client-sage-advance {
  &.terms-and-conditions{
    #angular-root #pmContent {
      & > .view {
        border-top: 45px solid #0055cc;
      }
    }
  }

  #angular-root #pmContent {
    .terms-and-conditions {
      hr {
        border-top: 1px solid #d8d8d8;
      }

      .content-header {
        h1 {
          margin-bottom: 17px;
          border-bottom: solid 1px #d8d8d8;
        }
      }
      .contact-subtitle {
        font-family: arial, sans-serif;
        font-weight: bold;
        margin: 30px 0 15px 0;
      }
      .contact-me-checkbox {
        padding: 0 0 0 20px;
      }
      .progress-wrapper {
        width:100%;

        * {
          box-sizing: border-box;
        }

        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          padding: 0;
          margin: 0 0 25px;

          li {
            width: 48%;
            background-color: #f4f5f7;
            border: 1px solid #d8d8d8;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: arial, sans-serif;
            font-size: 1rem;
            position: relative;
            z-index: 1;
            padding-left: 19px;
            font-weight: bold;
            color: #6b778c;

            &.active {
              background-color: #0055cc !important;
              color: #fff;
            }

            span {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: #ffa101;
              color: #fff;
              text-align: center;
              line-height: 1.786rem;
              font-weight: bold;
              margin: 0 20px;
            }

            &.arrow {
              width: 40px;
              background: url('/images/terms/arrow2.png') no-repeat top left;
              border: none;
            }

            &.active + .arrow {
              width: 40px;
              background: url('/images/terms/arrow.png') no-repeat top left;
              border: none;
            }

            &:first-child {
              border-right: 0;
            }

            &:first-child:not(.active) {
              border: solid 1px #d8d8d8;
              border-right: 0;
              background-color: rgba(17, 102, 34, 0.1);
              color: rgba(17, 102, 34, 0.6);
            }

            &:first-child:not(.active) span {
              position: relative;
              overflow: hidden;
            }

            &:first-child:not(.active) span:before {
              width: 25px;
              height: 25px;
              content: '\2713';
              background-color: #116622;
              position: absolute;
              left: 0;
              top: 0;
            }

            &:last-child {
              border-left: 0;
            }
          }
        }
      }
      .content-wrapper {
        background-color: #f4f5f7;
        .form-hint {
          background-color: #ffffff;
        }
      }
    }
  }
}

.client-sage-advance {
  &.terms-and-conditions{
    #angular-root #pmContent {
      & > .view {
        border-top: none;
      }
    }
  }

  #angular-root #pmContent {
    .terms-and-conditions {
      .content-header {
        h1 {
          font-weight: bold;
        }
      }
      h4 {
        font-weight: normal;
      }

      .steps {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;

        .pointer {
          width: 48%;
          height: 70px;
          position: relative;
          line-height: 70px;
          text-align: left;
          background: #f4f5f7;
          color: #6b778c;
          border: solid 1px #d8d8d8;

          span {
            width: 26px;
            height: 25px;
            display: inline-block;
            border-radius: 50%;
            background-color: #00d639;
            color: #000;
            text-align: center;
            line-height: 1.786rem;
            font-weight: bold;
            margin-right: 17px;
            margin-left: 20px;
          }

          &:last-child {
            div.tail {
              height: 70px;
              width: 20px;
              background-color: transparent;
              position: absolute;
              left: -20px;
              top: -1px;
              border-top: 1px solid #d8d8d8;
              border-bottom: 1px solid #d8d8d8;
            }
          }

          &:last-child:before, &:last-child:after {
            content: "";
            position: absolute;
            top: 0;
            left: -20px;
            width: 0;
            height: 0;
            border-top: 35px solid #f4f5f7;
            border-bottom: 35px solid #f4f5f7;
            border-left: 20px solid transparent;
          }

          &:last-child:before {
            border-top: 35px solid #d8d8d8;
            border-bottom: 35px solid #d8d8d8;
            left: -21px;
          }

          &:first-child:after, &:first-child:before {
            content: "";
            position: absolute;
            left: 100%;
            bottom: 0;
            width: 0;
            height: 0;
            border-top: 35px solid transparent;
            border-bottom: 35px solid transparent;
            border-left: 20px solid #f4f5f7;
          }

          &:first-child:before {
            border-left: 20px solid #d8d8d8;
            left: calc(100% + 1px);
          }

          &.active {
            background: #2c2a29;
            color: #ffffff;

            &:last-child:before, &:last-child:after {
              border-top: 35px solid #2c2a29;
              border-bottom: 35px solid #2c2a29;
            }

            &:first-child:after, &:first-child:before {
              border-left: 20px solid #2c2a29;
            }
          }
        }
      }

      .content-wrapper {
        margin-bottom: 25px;

        .form-hint {
          a {
            font-weight: bold;
            color: inherit;
          }
        }
      }

      .button {
        margin-top: 25px;
      }
    }
  }
}
