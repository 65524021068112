@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@mixin borderRadius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin borderRadiusPartial($top_right, $bottom_right, $bottom_left, $top_left) {
    -webkit-border-top-right-radius: $top_right;
    -webkit-border-bottom-right-radius: $bottom_right;
    -webkit-border-bottom-left-radius: $bottom_left;
    -webkit-border-top-left-radius: $top_left;
    border-top-right-radius: $top_right;
    border-bottom-right-radius: $bottom_right;
    border-bottom-left-radius: $bottom_left;
    border-top-left-radius: $top_left;
    background-clip: padding-box;
}

@mixin gradient($from, $too) {
    background-color: $from;
    background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($too));
    background-image: -webkit-linear-gradient(top, $from, $too);
    background-image: -moz-linear-gradient(top, $from, $too);
    background-image: -o-linear-gradient(top, $from, $too);
    background-image: linear-gradient(to bottom, $from, $too);
}

@mixin verticalGradient($top, $bottom, $base64) {
    background: $top;
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
    background: -o-linear-gradient(top, $top 0%, $bottom 100%);
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
    background: linear-gradient(to bottom, $top 0%, $bottom 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$top', endColorstr='$bottom', GradientType=0);
}

@mixin triangle($colour, $size, $direction, $offset) {
    border: solid $colour-transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: $colour-mine-shaft-transparent-0;
    border-width: $size;
    #{$direction}: $offset;
    margin-#{$direction}: -$size;
}

@mixin triangleBottom($colour, $size: 6px, $direction: left, $offset: 50%) {
    @include triangle($colour, $size, $direction, $offset);

    top: 100%;
    border-top-color: $colour;
}

@mixin triangleTop($colour, $size: 6px, $direction: left, $offset: 50%) {
    @include triangle($colour, $size, $direction, $offset);

    bottom: 100%;
    border-bottom-color: $colour;
}

@mixin triangleLeft($colour, $size: 6px, $direction: top, $offset: 50%) {
    @include triangle($colour, $size, $direction, $offset);

    right: 100%;
    border-right-color: $colour;
}

@mixin triangleRight($colour, $size: 6px, $direction: top, $offset: 50%) {
    @include triangle($colour, $size, $direction, $offset);

    left: 100%;
    border-left-color: $colour;
}

@mixin circleButton($background, $diameter, $round) {
    @include borderRadius($round);

    background: $background;
    padding: 0px;
    display: block;
    width: $diameter;
    height: $diameter;
}

@mixin circleButtonInner($background, $diameter, $round) {
    @include circleButton($background, $diameter, $round);

    margin: 0 auto;
    text-align: center;
    position: relative;
}

@mixin innerCircleButtonWithText($background, $diameter, $round, $offset, $colour) {
    @include circleButton($background, $diameter, $round);

    position: absolute;
    top: $offset;
    left: $offset;
    color: $colour;
    line-height: $diameter;
}

@mixin insetShadow($size) {
    -webkit-box-shadow: inset 0 $size $size $colour-alto-2;
    -moz-box-shadow: inset 0 $size $size $colour-alto-2;
    box-shadow: inset 0 $size $size $colour-alto-2;
}
