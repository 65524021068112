#companies-table .col-actions {
    width: 20px;
}

#pmContent #edit-company-form form[name="editCompanyForm"] h2 {
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 1em;
}
#merge-companies {
    .row {
        .input {
            overflow: visible;
        }
    }

    .multiSelect .checkboxLayer {
        position: relative;
    }
}
