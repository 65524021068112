.progress-bar-wrapper {
    padding: 15px 20px;
    border: 1px solid $colour-gray;

    @include borderRadius(8px);
}

div.progress-bar {
    .outer {
        @include borderRadius(8px);

        height: 13px;
        background-color: $colour-concrete;
        margin: 6px;
        -moz-box-shadow: inset 0 0 5px $colour-gray;
        -webkit-box-shadow: inset 0 0 5px $colour-gray;
        box-shadow: inset 0 0 5px $colour-gray;
    }

    .inner {
        @include borderRadius(8px);

        height: 13px;
        position: relative;
        background-image: url("/images/loading/progress-bar.png");
        background-repeat: repeat-x;

        &.static {
            background-image: none;
        }
    }

    span.percentage {
        font-size: 1.143rem;
        line-height: 2rem;
        display: block;
        text-align: left;
    }
}
