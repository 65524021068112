#pmContent {
    .state-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        height: 100%;
        background: $colour-tundora-transparent-70;
        overflow: auto;

        .modal-window {
            @include borderRadius(4px);

            padding: 25px;
            width: 720px;
            margin: 6% auto;
            background-color: $colour-black;
            color: $colour-white;
            position: relative;

            &.width-430 {
                width: 430px;
            }

            img {
                display: block;
                margin: auto;
            }

            .spin {
                display: block;
                text-align: center;
            }

            .modal-header {
                margin: 0 0 20px 0;
                padding-bottom: 20px;
                font-weight: bold;
                border-bottom: 1px solid $colour-gray;

                .stack {
                    margin-right: 5px;
                }

                .title-text {
                    margin: 0;
                    padding: 0;
                    height: 28px;
                    line-height: 2rem;
                }
            }

            .title-text {
                font-size: 1.143rem;
                padding: 0 0 0 5px;
                margin-bottom: 30px;
            }

            form.standard {
                p {
                    margin: 0 0 25px 0;
                }

                div.input {
                    padding-bottom: 25px;
                }
            }

            div.input {
                padding-bottom: 0;

                &.pb {
                    padding-bottom: 25px;
                }

                input[type="text"],
                input[type="email"],
                input[type="url"],
                input[type="tel"],
                input[type="password"],
                textarea {
                    border: none;
                }

                .ta-root .ta-toolbar {
                    .btn {
                        opacity: 1;
                    }
                }

                #limitedTextarea {
                    float: none;
                    height: 50px;
                    padding: 10px;
                }

                &.ask-question {
                    #limitedTextarea {
                        height: 250px;
                    }
                }

                &.feedback-comment {
                    #limitedTextarea {
                        height: 75px;
                    }
                }

                &.visible {
                    overflow: visible;

                    >.float-input {
                        overflow: visible;
                    }
                }

                &.date-time {
                    display: flex;
                    justify-content: space-between;

                    [datepicker-popup] {
                        border-radius: 4px;
                        margin-right: 5px;
                        width: calc(100% - 40px);
                    }

                    .icon-tick {
                        top: 0;
                    }
                }
            }

            div[limited-textarea], div.input-wrapper {
                background: $colour-mercury-4;
                padding: 10px 10px 30px;
                color: $colour-black;

                @include borderRadius(4px);

                &.ask-question {
                    padding: 10px 10px 10px;
                }

                textarea {
                    resize: none;
                    width: 100%;
                    height: 80px;
                }

                .char-counter {
                    clear: both;
                    float: right;
                    font-size: 1em;
                    margin-top: -1px;
                    border: none;
                }
            }

            // Override the padding-bottom property
            div.input-wrapper {
                padding-bottom: 10px;

                &.disabled {
                    p {
                        // color: $colour-nobel-3;
                    }
                }
            }

            #limitedTextarea {
                border: none;
                resize: none;
                overflow: auto;
                padding: 20px;
                height: 200px;
                color: $colour-gray-dark;
                font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;

                @include borderRadius(4px);
            }

            .char-counter {
                margin: 2px 10px 0 0;

                div {
                    padding: 0;
                }
            }

            .toggle-images-wrapper {
                &.scrollable {
                    overflow-y: scroll;
                    height: 510px;
                }
            }

            .modal-img {
                border: 1px solid $colour-nevada;
                cursor: pointer;
                padding: 4px;
                float: left;
                margin: 0 20px 20px 0;

                .actions {
                  height: 100%;
                  text-align: center;
                  padding-bottom: 5px;
                }

                div {
                    width: 250px;
                    height: 140px;
                    position: relative;
                    background: $colour-white;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &.modal-img-selected {
                    padding: 0;
                    border-width: 5px;
                }
            }

            .image-editor-wrapper {
                padding-bottom: 50px;

                image-editor {
                    width: auto;
                    background: $colour-white;
                    padding: 15px;
                    border-radius: 4px;
                    overflow: visible;

                    img {
                        max-width: none;
                        margin: 0;
                    }

                    editor-panel {
                        bottom: -50px;
                    }
                }
            }

            .modal-footer {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid $colour-gray;
                clear: both;

                a {
                    margin: 0 0 0 5px;
                    float: right;
                }
            }

            .publish-status {
                padding: 5px 0 40px 15px;
            }

            .instructional-text {
                border-bottom: 1px solid $colour-gray;
                margin-bottom: 20px;
                padding-bottom: 10px;

                p {
                    color: inherit !important;
                    background-color: inherit !important;
                }

                ul {
                    color: inherit !important;
                    background-color: inherit !important;
                }

                b {
                    font-weight: bold;
                }

                i {
                    font-style: italic;
                }
            }

            .pm-link-sales-resource {
                .selected-sales-resource {
                    background: #ffffff;
                    color: #666666;
                    margin-top: 20px;
                    padding: 20px;

                    .sales-resource-name {
                        font-size: 1rem;
                        font-weight: bold;
                        line-height: 1.43;
                        color: #6b778c;
                    }
                }

                .sales-resource-empty {
                    color: #666666;
                    padding: 170px 30px 30px 30px;
                    text-align: center;
                    background: #ffffff url("/images/icons/empty-paper.png") no-repeat center 40px;
                }

                .sales-resource-info {
                    .stack {
                        color: #079CE2 !important;
                        float: left;
                        margin: 0 15px 0 0;
                    }

                    background-color: #f4f5f7;
                    padding: 10px 50px 10px 10px;
                    margin: 15px 0 0 0;
                    font-size: 1rem;
                    line-height: 1.43;
                    color: #6b778c;
                }

                .create-btn {
                    display: inline-block !important;
                    margin: 30px 0 0 0;
                }

                .back-btn {
                    display: inline-block !important;
                    position: absolute;
                    margin: 41px 0 0 0;
                }

                table {
                    color: #666666;
                    margin-top: 20px;

                    td {
                        background: #ffffff;

                        &.td-radio {
                            text-align: center;
                        }

                        input {
                            height: 15px;
                            width: 15px;
                        }
                    }
                }
            }

            .globalIconDescription {
                display: flex;
                background-color: rgba(255, 255, 255, 0.2);
                margin-bottom: 15px;
                padding: 10px;

                p {
                    display: inline;
                    margin: 0 0 0 10px;
                }
            }

            .pm-image-option-wrapper {
                form {
                    width: 100%;
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #ccc;

                    select {
                        width: 340px;
                    }
                }
            }

            .pm-link-assets {
              display: flex;
              flex-wrap: wrap;
              margin: 0 0 0 -35px;
              padding-top: 35px;


              .pm-link-asset {
                flex: 0 0 21%;
                margin: 0 0 35px 35px;
                max-width: 290px;

                .pm-link-asset-wrapper {
                  width: 216px;
                  text-decoration: none;
                  cursor: pointer;
                }

                .pm-link-asset-wrapper.active .pm-link-asset-image {
                  border-width: 8px;
                }

                .pm-link-asset-image {
                  height: 200px;
                  padding: 10px;
                  border: 1px solid #d8d8d8;
                  display: flex;
                  transition: border 0.3s;
                  align-items: center;
                  justify-content: center;
                  box-sizing: border-box;
                }

                .pm-link-asset-image img {
                  max-width: 100%;
                  max-height: 100%;
                }


                .pm-link-asset-name {
                  padding-top: 15px;
                  display: block;
                  color: #d8d8d8;
                  transition: color 0.3s;
                }

                .pm-link-asset-wrapper:hover .pm-link-asset-image {
                  border-color: #fff;
                }

                .pm-link-asset-wrapper:hover .pm-link-asset-name {
                  color: #fff;
                }
              }
            }

            #asset-library-browse-one-asset {
              img {
                max-width: 270px;
                max-height: 500px;
              }

              .asset-details {
                max-width: 390px;

                .restriction-details {
                  color: #656565;

                }
              }

              .pm-link-asset-title {
                font-size: 1.429rem;
                padding-bottom: 20px;
              }
            }

        }
    }

    .templates.publication .state-modal .modal-window .modal-footer {
      border-top: none;
    }

    #testEmailModal {
        .state-modal .modal-window .modal-footer {
            border-top: 1px solid $colour-gray;
        }

        .loading-container {
            .loading {
                background-color: transparent;
            }

            .spin {
                color: $colour-white;
            }
        }

        .disabled {
            .loading-content-wrapper {
                opacity: 0.2;
            }
        }
    }
}
