#pmContent {
    #asset-library-browse {
        #asset-library-search-results {
            .asset {
                margin-bottom: 20px;
                display: inline-block;
                width: 216px;
                vertical-align: top;
                padding-right: 6.5%;
                box-sizing: border-box;
                width: 36%;
                opacity: 0;
                -webkit-animation-iteration-count: 1;
                -webkit-animation: fadeIn 0.25s forwards;
                animation-iteration-count: 1;
                animation: fadeIn 0.25s forwards;

                &:nth-child(3n+3) {
                    padding-right: 0;
                    width: 28%;
                }

                @for $item from 1 through 40 {
                    &:nth-child(#{$item}) {
                        -webkit-animation-delay: #{0.1 * $item}s;
                        animation-delay: #{0.1 * $item}s;
                    }
                }

                &.ng-leave {
                    display: none;
                }

                a.image-wrap {
                    display: block;
                    border: 5px solid $colour-concrete;
                    height: 200px;
                    margin-bottom: 15px;
                    position: relative;
                    text-align: center;

                    img {
                        display: block;
                        box-sizing: border-box;
                        margin: 0 auto;
                        position: absolute;
                        left: 0;
                        right: 0;
                        padding: 20px;
                        max-height: 100%;
                        display: block;
                    }
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    &:nth-child(2) {
                        display: block;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .pagination-footer {
            clear: both;
            padding-top: 2em;
            text-align: right;

            p {
                display: inline;
                margin-right: 1em;

                // Make the line the same height as the pagination.
                line-height: 2.357rem;
            }

            .pagination-wrapper {
                float: none;
                clear: none;
                display: inline;
            }
        }

        b {
            font-weight: bold;
        }

        .no-assets {
            text-align: center;

            img {
                margin-top: 2em;
                margin-bottom: 2em;
            }
        }
    }

    #asset-library-browse-one-asset {
        img {
            max-width: 450px;
            max-height: 646px;
            float: left;
            padding: 5px;
            border-color: $colour-concrete;
            border-style: solid;
            border-width: 5px;
            margin-right: 40px;
        }

        .asset-details {
            float: left;
            max-width: 430px;

            table {
                width: auto;
            }

            table tr, table td, table th {
                border: none;
            }

            table td, table th {
                padding: 0.5em 0;
                height: auto;
            }

            table th {
                padding-right: 1em;
            }

            .asset-description {
                margin-bottom: 2em;

                b {
                    font-weight: bold;
                }

                i {
                    font-style: italic;
                }
            }

            .restriction-details {
                > i {
                    float: left;
                    margin-right: 1em;
                }

                h2 {
                    font-size: 1rem;
                    margin-bottom: 1em;
                    line-height: 2rem;
                }

                padding: 2em;
                background-color: $colour-concrete;
            }
        }
    }

    #asset-library-reporting {
        .filters {
            padding: 1.1em 1.5em;
            margin-bottom: 2em;
            background-color: $colour-concrete;

            pm-date-range {
                margin-bottom: 1.5em;
            }

            form > div {
                display: inline-block;
                width: 45%;
                margin-top: 1em;
                margin-right: 4%;
                padding-bottom: 0;
            }

            form label {
                width: 130px;
                float: left;
            }

            form select {
                width: 260px;
                float: left;
            }
        }

        .asset-usage {
            h2 {
                border-bottom: 1px solid $colour-gray;
                padding-bottom: 5px;
                font-size: 1.3em;
            }

            .search-text {
                padding: 0.5em 0.5em 0.5em 0;
                background-color: $colour-concrete;
            }
        }
    }

    #asset-library-set-up {
        .asset-filter,
        .status-filter-buttons {
            padding: 1em;
            margin-bottom: 1em;
        }

        .asset-filter {
            margin-top: 1em;
            background-color: $colour-gallery-2;

            select {
                width: auto;
            }

            .language-label {
                margin-right: 10px;
            }
        }

        td.actions {
            width: 52px;
        }

        .status-filter-buttons {
            margin-top: 2em;

            li, a {
                margin-bottom: 0;
            }
        }

        .languages-available {
            font-weight: bold !important;
            font-size: 1.2em !important;
            margin: 1.5em 0 1.5em !important;

            + .error-message {
                margin-top: -0.5em !important;
                margin-bottom: 1.5em !important;
            }
        }

        form[name="assetForm"] {
            h2 {
                font-weight: bold;
                font-size: 1.2em;
                margin-top: 1em;
            }

            .asset-status {
                padding: 10px;

                select {
                    width: 280px;
                }

                .input-validated {
                    width: 285px;

                    i {
                        top: 8px;
                    }
                }
            }

            .asset-content {
                padding-bottom: 0;

                textarea {
                    height: 340px;
                }
            }

            .half-screen {
                float: left;
                clear: none;

                label {
                    width: 160px;
                }

                .float-input {
                    width: 300px;
                    margin-right: 20px;
                }
            }

            .float-input.half-screen {
                width: 300px;
            }

            div[limited-textarea] {
                textarea {
                    width: 100%;
                    float: left;
                }

                padding: 0;
            }

            .form-hint {
                float: none;
                width: auto;
            }

            select option.hide {
                display: none;
            }

            .has-file-uploader {
                .list-box {
                    margin-top: 20px;
                }

                pm-file-uploader {
                    width: calc(96% - 4px);
                }
            }

            hr.dark {
                margin: 30px 0;
            }
        }

        #asset-form-filter-groups {
            .input-validated {
                display: block;
                padding-right: 20px;
                position: static !important;
            }
        }

        #asset-description {
            .pm-input-icon {
                top: 30px !important;
            }
        }

        b {
            font-weight: bold;
        }

        .multiSelect > button {
            width: 100%;
            text-align: left;
        }
    }
}
