.content-header-subtitle {
    border-bottom: 1px solid $colour-gray;
    margin-bottom: 10px;
    h2 {
        font-size: 1.286rem !important;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal !important;
        letter-spacing: normal;
        text-align: left;
        margin: 0 0 20px !important;
    }
}

.calendly-input-url-row {
    width: 513px;
}

.calendly-input-key-row {
    width: 258px;
}

.calendly-input-url {
    width: 390px !important;
}

.calendly-input-key {
    width: 257px !important;
}

.toggle-decription {
    text-decoration: underline;
    margin: 15px 0 30px 0;
    display: block;
}

.marketing-box {
    text-align: center;
    border: solid 1px #d8d8d8;
    padding: 0 !important;
    margin: 15px;
    width: calc(25% - 32.5px) !important;
    display: block;
    text-decoration: none;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    &-details {
        padding: 20px 30px 15px;
        background-color: $colour-concrete;
        border-bottom: 2px solid $colour-black;
        position: relative;
        img {
            width: 80%;
            margin: 0 auto 15px;
            display: block;
        }
        h4 {
          font-size: 1rem !important;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal !important;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 0;
          text-decoration: none !important;
          color: #666666;
        }
        > div {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    &-price {
        background: #fff;
        p {
            font-size: 1rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $colour-gray-dark;
            line-height: 2.857rem;
            margin-bottom: 0;
            text-decoration: none !important;
        }
    }
    &:nth-child(4n+4) {
        margin-right: 0;
    }
    &:nth-child(4n+1) {
        margin-left: 0;
    }
    &:hover:not(.no-effect) {
        z-index: 10;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
        border: solid 1px #CDCDCD;
    }
}

.marketing-box-image {
  text-align: center;
}
.marketing-box-package {
    display: block;
    width: 100%;
    min-width: 100% !important;
    box-sizing: border-box;
    border: solid 1px #d8d8d8;
    margin-top: 25px;
    padding: 0 !important;
    float: none !important;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    &-header {
        background: $colour-white;
        border-bottom: 2px solid $colour-black;
        position: relative;
        padding: 10px 20px;
        &.knowledgebase {
          padding: 5px 10px 0 20px;
          display: flex;
          align-items: center;
          .marketing-radio {
            .check {
              top: 23px;
            }
          }
        }
        input {
            display: inline-block;
            margin: -5px 5px 0 0 !important;
            line-height: 2 !important;
        }
        h4 {
            color: #666666;
            display: inline-block;
            font-size: 1.143rem !important;
            font-weight: bold !important;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.4 !important;
            margin-bottom: 0 !important;
            letter-spacing: normal;
            width: calc(100% - 95px);
            margin-left: 18px !important;
        }
        h4 + i {
            display: block;
            color: $colour-gray-dusty;
            margin-left: 18px;
        }
        span {
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -9px;
            text-decoration: none;
            display: block;
            font-size: 1.143rem !important;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        .marketing-radio {
            .check {
                top: 13px;
            }
        }
    }
    &-body {
        background-color: $colour-concrete;
        color: #666666;
        padding: 10px 20px;
        ul {
            li {

            }
        }

        &.border-radius {
            @include borderRadius(4px);
        }
    }
    &:hover:not(.no-effect) {
        z-index: 10;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
        border: solid 1px #CDCDCD;
    }

    &.no-accents {
        font-size: 1rem !important;

        .marketing-box-package-header {
            background: $colour-white;
            border-bottom: none !important;
            padding-bottom: 0;

            h4 {
                font-size: 1rem !important;
                line-height: 2.429rem !important
            }
        }

        .marketing-box-package-body {
            padding-left: 43px;
            padding-top: 0;
        }
    }
}

.header-with-info-link {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: -30px 0 0;
}

.marketing-sidebar-box {
    background-color: $colour-concrete;
    padding: 15px 25px;
    margin-bottom: 25px;
    h5 {
        font-size: 1rem !important;
        color: #666666;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal !important;
        margin-bottom: 10px !important;
    }
}

.mdf-details {
    .icon-holder {
        display: inline-block;
        margin-right: 7px;
    }
    h5 {
        display: inline-block;
        line-height: 1.8 !important;
        letter-spacing: normal;
        margin-bottom: 10px !important;
    }
    p > span {
        font-size: 1.286rem;
        font-weight: bold;
    }
}

.marketing-store-package-list {
    background: $colour-white;
    border: solid 1px #d8d8d8;
    a.button {
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 0 !important;
        &[disabled] {
            opacity: 0.5;
        }
    }
    .selected-title {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .package-details {
        padding-bottom: 10px;
        border-bottom: solid 1px #d8d8d8;
        margin-bottom: 10px;
        &:last-of-type {
            border-bottom: 0;
        }
    }
    .no-options {
        font-weight: bold;
        margin-bottom: 20px;
    }
}

.available-campaigns {
    ul {
        margin-bottom: 15px;
    }
    .button {
        background: $colour-gray !important;
        color: #666666 !important;
        i {
            color: #666666 !important;
        }
    }
}

.marketing-upscales-wrapper {
    h3 {
        color: #666666;
        font-size: 1.143rem !important;
        font-weight: bold !important;
        font-style: normal;
        font-stretch: normal;
        line-height: 2 !important;
        letter-spacing: normal;
        background: none !important;
        border: none !important;
        margin: 25px 0 15px 0 !important;
        padding: 0 !important;
    }
    ul {
        margin: 0 !important;
        padding: 0 !important;
        li {
            list-style: none !important;
            margin: 0 0 10px !important;
            padding: 0 !important;
        }
    }
    label {
        float: none !important;
        display: block;
        min-width: 0 !important;
        width: 100%;
        height: 100%;
        position: relative;
        font-size: 1rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        margin: 0 !important;
        -webkit-transition: background 0.15s ease-in-out;
        transition: background 0.15s ease-in-out;
        background: $colour-concrete;
        border: solid 1px #d8d8d8;
        input {
            display: inline-block;
            margin-top: 0 !important;
            line-height: 2;
        }
        span {
            width: auto !important;
            display: block !important;
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -9px;
            text-decoration: none;
            display: block;
            font-size: 1.143rem !important;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        strong {
            font-weight: bold;
        }
        .text {
            width: calc(100% - 95px);
            margin-left: 18px !important;
        }
        &:hover:not(.no-effect) {
            background: darken($colour-concrete, 5%);
            -webkit-transition: background 0.15s ease-in-out;
            transition: background 0.15s ease-in-out;
        }
    }
}

.marketing-radio {
    display: inline-block;
    line-height: 1.786rem;
    margin-right: 5px;
    .check {
        display: inline-block;
        border-radius: 100%;
        height: 15px;
        width: 15px;
        transition: all .15s linear;
        -webkit-transition: all .15s linear;
        position: absolute;
        top: 18px;
        margin: 0 0 -3px;
        background: $colour-white;
        border: 1px solid $colour-gray;
        &::before {
            display: block;
            position: absolute;
            content: '';
            border-radius: 100%;
            height: 7px;
            width: 7px;
            top: 4px;
            left: 4px;
            margin: auto;
            transition: all 0.15s linear;
            -webkit-transition: all 0.15s linear;
        }
    }

    .client-colours {
        overflow: visible;
        height: 0;
    }

    input[type=radio]:checked ~ .client-colours > .check {
        background: inherit !important;
        border-color: inherit !important;
    }

    input[type=radio]:checked ~ .client-colours > .check::before {
        background: $colour-white;
    }

    input[type="radio"] {
        visibility: hidden;
        position: absolute;
    }

    &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }
}

.marketing-checkbox {
    display: inline-block;
    line-height: 1.786rem;
    margin-right: 5px;
    .tick {
        display: inline-block;
        height: 1.071rem;
        width: 1.071rem;
        background: $colour-white;
        border: 1px solid $colour-gray;
        transition: all .15s linear;
        -webkit-transition: all .15s linear;

        &:not(.inline) {
            position: absolute;
            top: 12px;
        }

        &.inline {
            position: relative;
        }

        &::before {
            display: block;
            position: absolute;
            content: "\e61c";
            opacity: 0;
            visibility: hidden;
            transition: all .15s linear;
            -webkit-transition: all .15s linear;
            font-family: 'partnermarketing';
            color: $colour-white;
            top: -4px;
            left: 2px;
            font-size: 0.857rem;
        }
    }

    .client-colours {
        overflow: visible;
        height: 0;
    }

    input[type=checkbox]:checked:not([disabled]) ~ .client-colours > .tick {
        background: inherit !important;
        border-color: inherit !important;
        transition: all .15s linear;
        -webkit-transition: all .15s linear;
    }

    input[type=checkbox][disabled]:checked ~ .client-colours > .tick::before {
        color: $colour-gray !important;
    }

    input[type=checkbox]:checked ~ .client-colours > .tick::before {
        opacity: 1;
        visibility: visible;
        transition: all .15s linear;
        -webkit-transition: all .15s linear;
    }

    input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
    }

    &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }
}

.marketing-checkbox ~ div {
    display: inline-block;
    vertical-align: top;
    line-height: 1.286rem;
}

.marketing-store-form {
    position: relative;
    padding-top: 75px;
    h4 {
        font-size: 1.143rem !important;
        font-weight: normal !important;
        font-style: normal !important;
        font-stretch: normal !important;
        line-height: 1.25 !important;
        letter-spacing: normal !important;
        color: #666666;
    }
    .selected-packages {
        margin: 0;
        list-style: none;
        li {
            float: none !important;
            display: block;
            min-width: 0 !important;
            width: 100%;
            height: 100%;
            position: relative;
            min-height: 50px;
            font-size: 1rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            padding: 15px 80px 15px 20px;
            box-sizing: border-box;
            margin: 0 0 13px !important;
            background: $colour-concrete;
            border: solid 1px #d8d8d8;
            &:last-of-type {
                margin-bottom: 0;
            }
            input {
                display: inline-block;
                margin-top: 0 !important;
                line-height: 2;
            }
            span:not(.regular) {
                width: auto !important;
                display: block !important;
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: -9px;
                text-decoration: none;
                display: block;
                font-size: 1.143rem !important;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
            }
            .header {
                font-size: 1.143rem;
                line-height: 1.429rem;
                color: #172b4d;
                font-weight: bold;
            }
        }
    }
    .col-md-12 {
        .vertical-padding + label {
            margin: 0 0 40px !important;
            padding: 0 !important;
            input {
                margin: 0 !important;
            }
        }
        > label {
            padding-top: 15px;
            display: block;
            float: none !important;
            &:first-of-type {
                min-width: 435px;
                width: auto;
                display: inline-block;
            }
            input, select, textarea {
                margin-top: 15px;
            }
            select {
                width: 100%;
                display: block;
                margin-right: 0 !important;
            }
            textarea {
                width: 100%;
            }
            .input-validated .pm-input-icon {
                top: 20px;
                &.icon-asterisk {
                    top: 24px;
                }
            }
        }
    }
    fieldset {
        display: block;
        iframe {
            border: solid 1px #d8d8d8;
        }
        legend {
            width: calc(50% - 9px);
            position: absolute;
            top: 0;
            margin: 0;
            height: 45px;
            font-size: 1rem;
            line-height: 1.43;
            -webkit-border-radius: 0;
            border-radius: 0;
            z-index: 3;
            border: solid 1px #d8d8d8;
            color: #666666;
            background: #fff;
            span {
                display: inline-block;
                border-radius: 50%;
                width: 1.429rem;
                height: 1.429rem;
                line-height: 1.429rem;
                text-align: center;
                margin: 12px 5px 12px 15px;
                font-size: 1rem;
                font-weight: bold;
                color: #ffffff;
                background: #cccccc;
            }
        }

        // Outwards arrow
        &:nth-child(1) legend {
            &:after {
                content: " ";
                left: 100%;
                top: 50%;
                border: solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-left-color: $colour-white;
                border-width: 24px 19px;
                margin-top: -24px;
                margin-left: -1px;
            }
            &:before {
                content: " ";
                left: 100%;
                top: 50%;
                border: solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-left-color: inherit !important;
                border-width: 24px 19px !important;
                margin-top: -24px;
            }
        }

        // Inwards arrow
        &:nth-child(2) legend {
            right: 0;
            position: absolute;
            -moz-border-radius: 0px;
            -webkit-border-radius: 0;
            border-radius: 0;
            z-index: 2;
            span {
                margin-left: 30px;
            }
            &:after {
                content: " ";
                top: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                left: -2px;
                border-left-color: white;
                border-width: 23px 18px;
                margin-top: -23px;
            }
            &:before {
                content: " ";
                top: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-left-color: #d8d8d8;
                border-width: 23px 18px;
                margin-top: -23px;
                left: -1px;
            }
        }

        // Active and inactive styling rules
        > *:not(legend) {
            display: none;
        }
        &.active > * {
            display: block;
        }
        &.active {
            > * {
                display: block;
            }
            &:nth-child(1) legend:after {
                border-left-color: inherit;
            }
        }
    }

    .package-item-wrapper {
        margin: 0 0 13px 0;
        border: 1px solid #d8d8d8;
        display: flex;
        background-color: #f4f5f7;

        .titleWrapper {
            padding: 15px;
            flex-grow: 8;
            flex-basis: 117px;
            border-right: 1px solid #d8d8d8;
            display: flex;
            justify-content: space-between;
            font-size: 1.14rem;
            line-height: 1.25;
        }

        .header {
            color: #172b4d;
            font-weight: bold;
        }

        .description {
            font-size: 1rem;
            line-height: 1.43;
        }

        .cost {
            padding: 15px;
            flex-shrink: 1;
            flex-basis: 117px;
            text-align: right;
            font-weight: bold;
            font-size: 1.14rem;
            line-height: 1.25;
        }
    }

    .totalWrapper {
        display: flex;
        font-size: 1.14rem;
        margin: 27px 0 30px 0;

        .total {
            flex-grow: 6;
            flex-basis: 147px;
            text-align: right;
            color: #172b4d;
        }

        .number {
            flex-shrink: 1;
            flex-basis: 147px;
            text-align: right;
            font-weight: bold;
        }

        .pullLeft {
            float: left;
            text-align: left;
        }
    }
}

.flex-start-center {
    input[type="radio"].green-bg:checked:after {
        content: " ";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: -1px;
        left: -1px;
        position: relative;
        background-color: white;
        display: inline-block;
        visibility: visible;
        border: 5px solid #62d84e;
    }
}
