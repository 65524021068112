@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

// Box model helper dimensions
$xLargePad: 160px;
$largePad: 50px;
$mediumPad: 30px;
$smallPad: 15px;
$xSmallPad: 10px;

// Colours
$colour-alabaster-2: #FCFCFC;
$colour-alabaster: #F9F9F9;
$colour-alto: #D3D3D3;
$colour-alto-2: #e9e7e7;
$colour-apple-green: #DBF1E4;
$colour-apple: #47a447;
$colour-astral: #3276B1;
$colour-bizarre: #F2DEDE;
$colour-black-transparent-10: rgba(0, 0, 0, 0.1);
$colour-black-transparent-125: rgba(0, 0, 0, 0.125);
$colour-black-transparent-15: rgba(0, 0, 0, 0.15);
$colour-black-transparent-175: rgba(0, 0, 0, 0.175);
$colour-black-transparent-20: rgba(0, 0, 0, 0.2);
$colour-black-transparent-30: rgba(0, 0, 0, 0.3);
$colour-black-transparent-75: rgba(0, 0, 0, 0.75);
$colour-black-transparent-80: rgba(0, 0, 0, 0.8);
$colour-black: #000000;
$colour-blue-boston-2: #357EBD;
$colour-blue-boston: #428BCA;
$colour-blue-eastern: #269ABC;
$colour-blue-fountain: #53BAB7;
$colour-blue-oxford: #323A49;
$colour-blue-transparent-20: rgba(0, 0, 255, 0.2);
$colour-boulder: #777777;
$colour-brown-sandy: #F0AD4E;
$colour-cerulean: #00ACED;
$colour-cerulean-deep: #007BB6;
$colour-champagne: #FAF2CC;
$colour-chestnut: #B94A48;
$colour-concrete: #f2f2f2;
$colour-ebony-clay: #23323B;
$colour-fern-2: #5CB85C;
$colour-fern: #5EB55E;
$colour-fruit-salad: #4CAE4C;
$colour-gallery-2: #EEEEEE;
$colour-gallery: #EBEBEB;
$colour-goblin: #398439;
$colour-gray-2: #808080;
$colour-gray-88: #E0E0E0;
$colour-gray-athens: #F7F7F8;
$colour-gray-chateau: #9FA8AD;
$colour-gray-dark: #666666;
$colour-gray-dove: #656565;
$colour-gray-dusty: #999999;
$colour-gray-funnel: #F0F0F0;
$colour-gray-light-1: #dddddd;
$colour-gray-light: #e6e6e6;
$colour-gray-lighter: #f7f7f9;
$colour-gray-shuttle-2: #666A73;
$colour-gray-shuttle: #5B616D;
$colour-gray: #cccccc;
$colour-grey-green-light-two: #D8EFDC;
$colour-green-light: #DFEDD8;
$colour-green-persian: #00ABA7;
$colour-green: #5DB85C;
$colour-iron-2: #E7E7E8;
$colour-iron: #e6e6e8;
$colour-link-water: #D9EDF7;
$colour-mako: #434853;
$colour-manhattan: #F5C288;
$colour-mantis: #92D166;
$colour-mariner: #3875D7;
$colour-mercury-2: #E5E5E5;
$colour-mercury-3: #E7E7E7;
$colour-mercury-4: #E3E3E3;
$colour-mercury: #E8E8E8;
$colour-meteor: #D58512;
$colour-mine-shaft-2: #222222;
$colour-mine-shaft-3: #262626;
$colour-mine-shaft-transparent-0: rgba(51, 51, 51, 0);
$colour-mine-shaft: #333333;
$colour-mint-tulip: #C4E3F3;
$colour-nevada: #657076;
$colour-nobel-2: #B3B3B3;
$colour-nobel-3: #B6B6B6;
$colour-nobel: #B7B4B3;
$colour-orange-carrot: #ED9C28;
$colour-outer-space: #2C353D;
$colour-pearl-lusta: #FCF8E3;
$colour-peppermint: #DFF0D8;
$colour-pink-oyster: #EBCCCC;
$colour-pink: #D38B8B;
$colour-red-light: #F3DFDE;
$colour-red-mexican: #AC2925;
$colour-red-persian-2: #D2322D;
$colour-red-persian: #D32F2F;
$colour-red-transparent-20: rgba(255, 0, 0, 0.2);
$colour-red: #DB5350;
$colour-roman: #D9534F;
$colour-san-marino: #4967A9;
$colour-scooter-2: #39B3D7;
$colour-scooter: #46B8DA;
$colour-silver: #B8B8B8;
$colour-silver-chalice-2: #AAAAAA;
$colour-silver-chalice: #ADADAD;
$colour-st-topaz-2: #2A6496;
$colour-st-topaz: #285E8E;
$colour-table-row-odd: #f6f5f8;
$colour-transparent: transparent;
$colour-trout: #4F5B62;
$colour-tundora: #4D4D4D;
$colour-tundora-transparent-70: rgba(77, 77, 77, 0.7);
$colour-valencia-2: #D43F3A;
$colour-valencia-3: #DB4040;
$colour-valencia: #D6514B;
$colour-viking: #5BC0DE;
$colour-white: #FFFFFF;
$colour-white-transparent-50: rgba(255, 255, 255, 0.5);
$colour-wild-sand: #F5F5F5;
$colour-yellow-fuel: #EEA236;
$colour-yellow-light: #fce9d9;
$colour-yellow: #dd8524;
$colour-zanah: #D0E9C6;
