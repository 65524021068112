/**
 * Primary navigation
 */
#pmContent header#globalHeader {
    .client-navigation-colour-background {
        position: relative;
    }
    nav#globalNav {
        margin: 0 auto;

        .dropdown-menu {
            box-shadow: none;
            -webkit-box-shadow: none;
            background-color: transparent;
            padding: 25px 0 0 0;
            right: 0;
            background-color: #f4f5f7;
            padding-left: calc((100% - 970px) / 2);
            padding-right: calc((100% - 970px) / 2);
            box-shadow: 0 2px 4px 0 #d8d8d8;

            .wrapper {
                width: 100%;
                display: flex;
            }
        }

        .open .dropdown-menu {
            display: flex;
            flex-wrap: wrap;
        }

        .menu-section {
            background-color: $colour-white;
            box-sizing: border-box;
            padding: 25px 21px;
            width: 290px;
            -webkit-box-shadow: 0 2px 4px 0 #d8d8d8;
            box-shadow: 0 2px 4px 0 #d8d8d8;
            margin-bottom: 28px;


            &.wide {
                width: 940px;
            }

            & + .menu-section {
                margin-left: 35px;
            }

            &:nth-child(4) {
                margin-left: 0;
            }

            &.forSideMenuRight {
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 40px 55px 25px;

                dl {
                    margin-bottom: 18px;
                    padding: 0 5px;
                }

                dl dt {
                    margin-bottom: 11px;
                }

                dl dd {
                    line-height: 1.43;
                }

                dt {
                    font-weight: normal !important;
                }
            }
        }

        .need-help {
            a.button {
                font-size: 1rem;
                border: none;
                box-shadow: none;
            }

            .dropdown-menu {
                border: 0px;
                border-radius: 0px;
                right: 0;
                padding: 20px 0 0 0;
                margin: 0;
                -webkit-box-shadow: unset;
                box-shadow: unset;

                .need-help-block {
                    background-color: $colour-concrete;
                    padding: 20px 20px 15px 20px;
                    width: 250px;
                    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    font-size: 0.857rem;
                    line-height: 1.5;

                    h1 {
                        margin: 0 0 5px 0;
                        font-size: 1.286rem;
                        line-height: 1.429rem;
                        height: auto;
                    }

                    h4 {
                        font-size: 0.857rem;
                        font-weight: bold;
                        line-height: 1.5;
                        margin: 0 0 5px 0;
                    }

                    p {
                        font-size: 0.857rem;
                        line-height: 1.5;
                        margin: 0 0 5px 0;
                    }

                    hr.dotted {
                        border-top: 2px dotted $colour-gray;
                    }
                }

                .need-help-block:before {
                    content: "\e605";
                    font-family: 'partnermarketing';
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    font-size: 2.143rem;
                    right: 0;
                    position: absolute;
                    top: -1px;
                    color: $colour-concrete;
                }
            }
        }

        ul {
            margin: 0;

            &.primary-nav {
                float: left;

                li {
                    float: left;
                    margin: 0;
                    list-style: none;

                    a {
                        color: $colour-white;
                        line-height: 3.214rem;
                        text-decoration: none;
                        display: block;

                        span {
                            padding: 0 10px;
                            border-right: 1px solid $colour-white;
                        }
                    }

                    &:nth-of-type(1) a span {
                        padding-left: 0;
                    }

                    &:last-of-type a {
                        border: none;
                    }

                    &.current > a {
                        position: relative;

                        &:after {
                            @include triangleBottom($colour-outer-space);
                            z-index: 1001;
                        }
                    }

                    .dropdown-menu li {
                        float: none;
                    }

                    .dropdown-menu li a {
                        display: block;
                        padding: 5px;
                        clear: both;
                        line-height: 1.43;
                    }

                    .dropdown-menu li.grouping {
                        font-weight: bold;
                        font-size: 0.9em;
                        cursor: default;

                        dl {
                            margin-bottom: 18px;
                            padding: 0 5px;

                            dt {
                                margin-bottom: 11px;
                            }

                            dd {
                                line-height: 1.43;
                            }
                        }

                        &.columns-3 {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            ul {
                                width: 28%;
                            }
                        }
                    }

                    &:last-child a span {
                        border-right: none;
                    }
                }
            }

            &.account {
                float: right;

                > li {
                    float: left;
                    margin: 0 0 0 20px;
                    list-style: none;

                    > a {
                        color: $colour-white;
                        font-size: 1rem;
                        line-height: 3.214rem;
                        padding-left: 22px;
                    }

                    > span {
                        &.indicator {
                            color: $colour-white;
                            font-size: 1rem;
                            line-height: 3.214rem;
                            background: $colour-tundora;
                            padding: 7px 15px;

                            @include borderRadius(14px);

                            &.global-admin {
                                background: $colour-fern;
                            }
                        }
                    }
                }
            }
        }

        .arrowRight {
            display: inline-block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent #666666 transparent;
            transform: rotate(90deg);
            margin-bottom: 3px;
            margin-left: 1px;
        }

        .linkDescription {
            padding: 0 5px 5px;
        }

        .sideMenu {
            display: flex;
            width: 100%;

            .sideMenuLeft {
                flex: 1 1;
                padding: 40px 0;
                box-sizing: border-box;

                ul {
                    height: 100%;

                    .leftSideItem {
                        font-size: 1.142857143rem;
                        padding: 10px 50px;
                        cursor: pointer;
                    }
                }
            }

            .sideMenuRight {
                flex: 3.4 1;
            }

            .sideMenuColumns {
                display: flex;
                justify-content: flex-start;

                .sideMenuColumn {
                    margin-right: 6rem;

                    .rightSideLink {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                    }
                }
            }
        }
    }

    .global-admin-nav {
        background-color: $colour-outer-space;
    }
}
