#pmContent activity-icon {
    font-size: inherit;

    span, i {
        font-size: 1em;
        margin-right: 5px;
        vertical-align: middle;

        &.icon {
            margin: 0;
            color: $colour-gray-2;
        }

        &.half {
            float: left;
            overflow: hidden;
            width: 34%;
            margin-left: 18%;
        }

        &.active {
            color: $colour-fern-2 !important;
        }
    }
}
