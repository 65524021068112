#pmContent {
    cover-image-uploader,
    pm-file-uploader {
        display: block;
        width: calc(96% - 4px);
        overflow: hidden;
        padding: 30px 2%;
        text-align: center;

        > div p {
            margin-bottom: 1em;
            font-size: 1rem;
            color: $colour-gray-dark;
        }

        [ng-show="uploaded"] img {
            max-width: 100%;
            height: auto;
        }

        .untouched-state {
            text-align: center;

            .button-wrapper {
                margin-top: 10px;
                display: inline-block;
            }

            &.disabled {
              opacity: 0.5;

              input[type="file"] {
                cursor: not-allowed;
              }
            }

            .button {
                margin: 0 auto;
                display: inline-block;
            }

            // Hide the browser's native file upload button, in favour of the nicely-styled <a> element.
            input[type="file"] {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }

        // The untouched state has a button at the bottom, so the last <p> should keep it's bottom
        // margin. Only touched states have nothing after the last <p>.
        .touched-state p:last-of-type {
            margin-bottom: 0;
        }
    }

    pm-file-uploader {
        position: relative;
        background-color: $colour-concrete;

        @include borderRadius(8px);

        border: 2px dashed $colour-gray;
        background-clip: initial;
    }

    cover-image-uploader {
        width: 95%;
        position: relative;
        padding-top: 5px;

        pm-file-uploader {
            width: 60%;
            float: right;
        }

        > img {
            position: relative;
            z-index: 1;
            max-width: 30%;
            float: left;
        }

        > img + pm-file-uploader {
            position: absolute;
            right: 2%;

            .left-column {
                opacity: 0;
            }
        }
    }
}
