#pmContent loading-icon {
    .loading-icon {
        position: absolute;
        text-align: center;
        width: 100%;

        i.icon {
            margin: 0 !important;
        }
    }

    .loading-icon-centred {
        display: table;
        width: 100%;
        height: 100%;

        span.loading-icon-wrapper {
            display: table-cell;
            height: 100%;
            vertical-align: middle;

            i.icon {
                margin: 0 !important;
            }
        }
    }
}