.icon {
    &.lg {
        font-size: calc(4rem / 3);
        line-height: calc(3rem / 4);
        vertical-align: -15%;
    }

    &.x075 {
        font-size: 0.75rem !important;
    }

    &.x1 {
        font-size: 1.5rem !important;
    }

    &.x1_2 {
        font-size: 1.2rem !important;
    }

    &.x2 {
        font-size: 2rem !important;
    }

    &.x3 {
        font-size: 3rem !important;
    }

    &.x4 {
        font-size: 4rem !important;
    }

    &.x5 {
        font-size: 5rem !important;
    }

    &.dark {
        color: $colour-mine-shaft;
    }

    &.light {
        color: $colour-white;
    }

    &.gray {
        color: $colour-gray;
    }

    &.warning {
        color: $colour-yellow;
    }

    &.success {
        color: $colour-fern-2;
    }

    &.error {
        color: $colour-valencia;
    }

    &.margin-right {
        margin-right: 5px;
    }

    &.margin-left {
        margin-left: 5px;
    }

    &.large {
        display: block;
        font-size: 3rem;
        margin-bottom: 15px;
    }
    &.icon-question {
        display: block;
        width: 12px;
        text-align: center;
    }
}

.icon-info.x1.stack-inner {
    position: relative;
    top: 3px;
}

.icon-arrow-left.button:before {
    position: relative;
    bottom: -1px;
}

h3.icon-edit:before,
h3.icon-edit-colour:before,
.icon-cross.button:before,
.icon-tick.button:before {
    font-size: 150%;
    line-height: 0.5;
    position: relative;
    bottom: -4px;
}

.stack {
    position: relative;
    display: inline-block;
    vertical-align: top;

    &.x1 {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
    }

    &.x2 {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
    }

    &.x3 {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }

    &.x4 {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
    }

    &.x5 {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
    }
}

.stack-outer, .stack-inner {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
}

.stack-inner {
    line-height: inherit;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: 'partnermarketing';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.button[class^="icon-"]:before,
.button[class*=" icon-"]:before,
.button.align-center[class^="icon-"]:before,
.button.align-center[class*=" icon-"]:before {
    float: left;
    font-size: 140%;
    line-height: 0.5;
    margin: 1px -16px 0 7px;
    width: 16px;
}

.button[class^="icon-"]:before,
.button[class*=" icon-"]:before {
    margin: 1px 7px 0 -4px;
}

.icon-account:before {
    content: "\e600";
}

.icon-archive:before {
    content: "\e601";
}

.icon-arrow-down:before {
    content: "\e602";
}

.icon-arrow-left:before {
    content: "\e603";
}

.icon-arrow-right:before {
    content: "\e604";
}

.icon-arrow-up:before {
    content: "\e605";
}

.icon-bg-circle:before {
    content: "\e606";
}

.icon-bg-square:before {
    content: "\e607";
}

.icon-bg-triangle:before {
    content: "\e608";
}

.icon-calendar:before {
    content: "\e609";
}

.icon-cross:before {
    content: "\e60a";
}

.icon-disabled:before {
    content: "\e60b";
}

.icon-download:before {
    content: "\e60c";
}

.icon-edit-colour:before {
    content: "\e60d";
}

.icon-edit-image:before {
    content: "\e60e";
}

.icon-edit-link:before {
    content: "\e60f";
}

.icon-edit-text:before {
    content: "\e610";
}

.icon-edit:before {
    content: "\e611";
}

.icon-exclamation:before {
    content: "\e612";
}

.icon-graph:before {
    content: "\e613";
}

.icon-info:before {
    content: "\e614";
}

.icon-loading:before {
    content: "\e615";
}

.icon-lock:before {
    content: "\e616";
}

.icon-new:before {
    content: "\e617";
}

.icon-plus:before {
    content: "\e618";
}

.icon-publish:before {
    content: "\e619";
}

.icon-remove:before {
    content: "\e61a";
}

.icon-save-exit:before {
    content: "\e61b";
}

.icon-tick:before {
    content: "\e61c";
}

.icon-trash:before {
    content: "\e61d";
}

.icon-unarchive:before {
    content: "\e61e";
}

.icon-updated:before {
    content: "\e61f";
}

.icon-view:before {
    content: "\e620";
}

.icon-zoom-in:before {
    content: "\e621";
}

.icon-sign-in:before {
    content: "\f090";
}

.icon-facebook:before {
    content: "\e623";
}

.icon-linkedin:before {
    content: "\e624";
}

.icon-twitter:before {
    content: "\e625";
}

.icon-googleplus:before {
    content: "\e900";
}

.icon-blog:before {
    content: "\e901";
}

.icon-instagram:before {
    content: "\e902";
}

.icon-pinterest:before {
    content: "\e903";
}

.icon-vimeo:before {
    content: "\e904";
}

.icon-xing:before {
    content: "\e905";
}

.icon-youtube:before {
    content: "\e906";
}

.icon-wechat:before {
    content: "\e90c";
}

.icon-weibo:before {
    content: "\e90d";
}

.icon-youku:before {
    content: "\e90e";
}

.icon-merge:before {
    content: "\e907";
}

.icon-asterisk:before {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    content: "*";
}

.icon-notebook:before {
    content: "\e908";
}

.icon-bell:before {
    content: "\e909";
}

.icon-workflow:before {
    content: "\e90A";
}

.icon-settings:before {
    content: "\e90b";
}

.icon-globus:before {
    content: "\e90f"
}

.icon-copy:before {
    content: "\e914";
}

.icon-flip-horizontal:before {
    content: "\e913";
}

.icon-flip-vertical:before {
    content: "\e912";
}

.icon-unlink:before {
    content: "\e911";
}

.icon-upload:before {
    content: "\e910";
}

.icon-url:before {
    content: "\e90f";
}

.icon-question:before {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    content: "?";
}

.icon-knowledgebase {
    margin: 0;
    background: transparent url("/images/icons/knowledgebase.png") no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
    display: block;
}

.icon-basket:before {
    content: "\e916";
}

.icon-file:before {
    content: "\e919";
}

.icon-category:before {
    content: "\e91a";
}

.icon-global {
    &:before {
        content: "\e917";
        font-size: 18px;
    }

    &.for-button {
        &:before {
            font-size: 12px;
        }
    }
}

.icon-semi-global:before {
    content: "\e918";
    font-size: 18px;
}
