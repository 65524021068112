#pmContent {
    .pm-tabs {
        .tab-pane {
            display: none;

            &.selected-tab {
                display: block;
            }
        }
    }

    .big-tabs {
        height: 95px;
        margin-bottom: 45px;

        a {
            display: block;
            float: left;
            width: 50%;
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            text-align: center;
            padding: 15px;
            height: 95px;
            color: #666666;
            text-decoration: none;
            font-size: 1.143rem;
            font-weight: bold;

            &:nth-child(even) {
                border-left: 0;
            }

            &.selected {
                background: #f2f2f2;
            }

            span {
                display: block;
            }
        }
    }
}
