//template wrapper
$templateEditPageHeaderHeight: 72px;
.page-header-hidden {
    #pmContent {
        height: 100%;
        min-height: 100%;

        & > .view,
        & > .view > [ui-view="main"] {
            height: 100%;
            min-height: 100%;
        }

        #globalHeader, div[progress-step] .stage-list, & > footer {
            display: none;
        }
    }
}

pm-content-template-render {
    display: block;
}

#angular-root action-panel, .notification-panel {
    padding: 8px;
    margin: 20px 0;
    background: $colour-concrete;
    display: block;

    &.large {
        padding: $smallPad;
        border: 1px solid $colour-gray-light-1;

        .stack.x1 {
            width: 1.786rem;
            height: 1.786rem;
            line-height: 1.786rem;
            margin-top: -14px;
        }

        .icon.x1 {
            font-size: 1.786rem !important;
        }

        .icon.x075 {
            font-size: 1.071rem !important;
        }

        .action-text-wrapper {
            line-height: 1.786rem;
            padding-left: 40px;
            font-weight: bold;
        }

        .dropdown-wrapper {
            float: right;
            border-left: 1px solid $colour-gray-light-1;
            margin: (-$smallPad) (-$smallPad) (-$smallPad) 0;
            margin-left: $smallPad;

            .dropdown-toggle {
                color: $colour-gray-dark;
                padding: $smallPad;
                display: inline-block;
                line-height: 1.786rem;
                cursor: pointer;
            }

            .dropdown-menu {
                top: 38px;
                right: 0;
                left: auto;

                li {
                    list-style: none;
                    margin: 0;
                    text-align: left;
                }
            }
        }
    }

    .action-text-wrapper {
        padding-left: 30px;
        position: relative;
    }

    .button {
        margin: -3px 0 !important;
    }

    .stack {
        margin: -11px 0 0;
        position: absolute;
        top: 50%;
        left: 0;
    }
}

settings-switcher {
    $padding: 24px;
    $icon-size: 40px;

    display: flex;
    align-items: stretch;
    background: $colour-concrete;
    border: solid 1px $colour-gray;
    padding: $padding;

    .subtitle {
        font-size: 1.143rem;
        line-height: 1.25;
        margin-bottom: 5px;
    }

    .switcher-text {
        width: 85%;
    }

    .switcher-wrapper {
        width: 15%;
        margin-top: -$padding;
        margin-bottom: -$padding;
        padding-top: $padding;
        padding-bottom: $padding;
        border-left: solid 1px $colour-gray;
        font-weight: bold;

        & > span {
            display: block;
            clear: both;
        }
    }

    pm-toggle-button {
        float: right;
        margin-bottom: 5px;
    }

    .has-icon {
        padding-left: $icon-size + $padding;
    }

    .switcher-icon {
        float: left;
        width: $icon-size;
        height: $icon-size;
        font-size: $icon-size;
        margin-left: - $icon-size - $padding;
    }
}

#angular-root {
  #template {
    width: 100%;
    visibility: hidden;

    &.cobrandedpdftemplate {
      display: flex;

      > #fakeTemplateHtmlElement {
        flex: 1;

        > #fakeTemplateBodyElement {
          height: 100%;
        }
      }
    }

    > #fakeTemplateHtmlElement {
      display: block;

      > #fakeTemplateBodyElement {
        display: block;
        position: relative;
      }
    }

    word-wrap: normal;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
  }

  #pmContent,
  [ui-view="template"] {
    opacity: 1;
    transition: opacity .6s ease-in-out;
    -moz-transition: opacity .6s ease-in-out;
    -webkit-transition: opacity .6s ease-in-out;
  }

  div.templates.publication div.overview.ng-enter {
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }

  div.templates.publication div.overview.ng-leave {
    -webkit-animation: fadeOut 0.5s;
    animation: fadeOut 0.5s;
    display: block !important;
  }

  #template {
    li {
      list-style: disc;
      list-style-position: inside;
    }

    strong, b {
      font-weight: bold;
    }

    em, i {
      font-style: italic;
    }

    [pm-text].editable, [pm-link].editable, [pm-calendar].editable {
      cursor: pointer;
    }

    [pm-text]:not(.editable) {
      p {
        margin: 0;
      }
    }

    span.editable {
      &[pm-text], &[pm-link], &[pm-calendar] {
        background-color: $colour-black-transparent-10;

        .editable-overlay {
          position: relative;
          background-color: inherit;
          height: auto;
          width: auto;

          &.disabled-overlay {
            border-color: $colour-red;
          }

        }
      }
    }

    .pm-image-option-container {
      font-size: 0;
      line-height: 0;
      text-shadow: none;
      color: $colour-transparent;
      display: inline-block;
      max-width: 100%;
    }

    .pm-image-wrapper {
      display: inline;
    }

    .pm-video-iframe-wrapper {
      display: inline-block;
    }

    *[pm-item-deleted="true"] {
      display: none !important;
    }

    // Template editable overlay style
    *[editable="true"], *[text-editable="true"], *[link-editable="true"], *[calendar-editable="true"], .pm-image-option-container {
      border: 2px dashed $colour-tundora;
      position: relative;
      z-index: 1;
      box-sizing: border-box;

      &.disabled-area {
        border-color: $colour-red;
      }

      &.pm-image-option-container {
        border: none;

        &:not(.highlight-error):not(.highlight-success) {
          .editable-overlay {
            border: 2px dashed $colour-tundora;
            box-sizing: border-box;

            &.disabled-overlay {
              border-color: $colour-red;

              .icon-holder:nth-child(2) {
                top: 18px;
                background: $colour-red;
              }
            }
          }
        }

        &.highlight-error {
          border: 2px dashed $colour-red;
        }

        &.highlight-success {
          border: 2px dashed $colour-fern-2;
        }
      }

      &.have-image {
        font: 0/0 serif;
        text-shadow: none;
        color: $colour-transparent;
        border: none;
        display: inline-block;
        max-width: 100%;

        .icon-holder {
          top: 0px;
        }

        .pm-image-option-container > .editable-overlay > .icon-holder {
          right: -20px;
          top: 16px;
          z-index: 1;
        }
      }

      .editableFiledTooltip {

      }

      .editable-overlay {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $colour-black-transparent-10;
        left: 0;
        top: 0;

        &.pm-area-container {
          cursor: default;
          margin: 0 18px 0 0;
          width: calc(100% - 18px);
        }
      }

      img {
        float: none;
      }

      .icon-holder {
        position: absolute;
        right: -18px;
        top: -2px;
        height: 18px;
        width: 18px;
        background-color: $colour-tundora;
        font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;

        &.right {
          right: -36px;
        }

        &.bottom {
          top: 16px;
        }

        .icon {
          font-size: 12px;
          line-height: 18px;
        }

        .icon-disabled {
          background: $colour-red;
        }

        &.light {
          background-color: $colour-white;

          .icon {
            color: $colour-tundora;
          }
        }
      }

      &.highlight-success {
        border-color: $colour-fern-2;

        .icon-holder {
          background-color: $colour-fern-2;
        }
      }

      &.highlight-error {
        border-color: $colour-red;

        .icon-holder {
          background-color: $colour-red;
        }
      }
    }

    *[pm-not-active="true"].editable {
      border-color: $colour-red;

      &.single-area {
        .pm-area-container {
          .icon-holder {
            &.remove {
              display: none;
            }

            &.add {
              top: -2px;
            }
          }
        }
      }
    }

    [pm-area].editable {
      padding-right: 36px !important;

      .loading-container {
        display: none;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.9;
        z-index: 100;
      }

      &.loading {
        .loading-container {
          display: block;
        }
      }
    }

    .pm-area-container {
      .icon-holder {
        cursor: pointer;

        &:nth-child(2) {
          top: 18px;
        }

        &:nth-child(3) {
          top: 38px;
        }

        &:nth-child(4) {
          top: 58px;
        }
      }
    }

    .deletable-and-deleted {
      .pm-area-container {
        .icon-holder {
          &:nth-child(2) {
            top: -2px;
          }
        }
      }
    }

    .no-deletable {
      .pm-area-container {
        .icon-holder {
          &:nth-child(2) {
            top: -2px;
          }

          &:nth-child(3) {
            top: 18px;
          }

          &:nth-child(4) {
            top: 38px;
          }
        }
      }
    }

    .single-area {
      .pm-area-container {
        .icon-holder {
          &.up,
          &.down {
            display: none;
          }
        }
      }
    }

    .pm-image-option-container img {
      z-index: -1;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }

    .social-post-template-edit {
      padding: 40px 40px 0;

      label {
        font-weight: bold !important;
      }

      .social-post-image {
        .pm-image-option-container {
          .editable-overlay {
            display: inline-block;
            background: white;
            border: 2px dashed #4D4D4D;
            z-index: -1;

            &:after, &:before {
              content: "";
              position: absolute;
              border-top: 1px solid #4D4D4D;
              width: 458px;
              transform: rotate(29deg);
              transform-origin: 0% 0%;
            }

            &:before {
              transform: rotate(151deg);
              left: 404px;
            }
          }
        }
      }

      .form-hint {
        max-width: 415px;
        background-color: #ffffff;
      }
    }

    .pm-social-icons-empty {
      div[ng-transclude] {
        &:before {
          font-family: 'partnermarketing';
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1.714rem;
          font-size: 0.857rem;
          vertical-align: middle;
          text-align: center;
          display: inline-block;
          position: relative;
          z-index: 1;
          width: 1.714rem;
          height: 1.714rem;
          content: "\e60a";
          border-radius: 50%;
          background: $colour-white;
          color: $colour-gray-dusty;
          border: 2px solid $colour-gray-dusty;
          cursor: pointer;
        }
      }
    }
  }
}

.template-edit-page #angular-root {
  [src="getView()"] {
    height: 100%;
    min-height: 100%;
  }
  #template {
    width: auto;
    position: absolute;
    top: $templateEditPageHeaderHeight;
    right: 0;
    min-height: calc(100vh - #{$templateEditPageHeaderHeight} - 1px);
    margin-left: auto;
    margin-right: auto;

    &.staticbannertemplate {
      height: calc(100% - #{$templateEditPageHeaderHeight});

      #fakeTemplateHtmlElement {
        margin-left: 60px;
        margin-top: 60px;
      }
    }

    // Add a 25px padding around PDFs on the editing screen so it is easier to see the template correctly.
    &.pdftemplate {
      padding-top: 25px;
      padding-left: 25px;
      background: $colour-concrete;

      #fakeTemplateBodyElement {
        background: $colour-white;
      }
    }

    &.animatedbannertemplate {
      height: calc(100% - #{$templateEditPageHeaderHeight});

      #fakeTemplateHtmlElement {
        height: 100%;

        #fakeTemplateBodyElement {
          height: 100%;

          .button {
            @extend %button;
            font-size: 1rem;
            background: $colour-fern;
            color: $colour-white;
            text-decoration: none;
            cursor: pointer;
            @include borderRadius(4px);
          }
        }
      }
    }
  }


  // Must override "height: 100%;" on #pmContent so that the white background covers the div
  // with [ui-view="template"] (which is outside of #pmContent).
  height: 100%;

  #templatePositionElement {
    position: relative;
    min-height: calc(100vh - #{$templateEditPageHeaderHeight});
    height: calc(100vh - #{$templateEditPageHeaderHeight});

    .loading-container {
      height: 100%;
      min-height: 100%;

      .loading {
        z-index: 5;
      }

      &.active {
        margin-left: 248px;
        height: 100%;
        min-height: 100%;
      }
    }

    .template-left-panel {
      #multi-page-notification {
        position: absolute;
        z-index: 100;
        display: block;
        opacity: 1;
        left: 215px;
        background-color: #333333;
        width: 290px;
        color: #cccccc;
        font-size: 1.071rem;
        line-height: 1.429rem;
        font-weight: normal;
        margin: 0;
        padding: 20px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background-clip: padding-box;
        top: 0;
        pointer-events: auto;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -20px;
          top: 45%;
          border: 10px outset transparent;
          border-right: 10px solid #333333;
        }

        h1 {
          font-size: 1.429rem;
          line-height: 1.429rem;
          padding: 5px 0 0 0;
          margin: 0;
          color: $colour-white;
        }

        p {
          margin-bottom: 10px;
        }

        .icon-holder {
          float: left;
          color: $colour-white;
          margin: 0 10px 10px 0;

          .icon-info {
            position: absolute;
            top: 0px;
          }
        }

        a {
          float: right;
          cursor: pointer;
        }

        .checkbox {
          margin-top: 15px;
          margin-bottom: 10px;
        }

        .with-checkbox {
          margin-left: 20px;
          font-size: 0.857rem;
          text-decoration: underline;
        }
      }
    }
  }

  .template-left-panel {
    position: absolute;
    z-index: 2;
    padding: 0 24px;
    width: 200px;
    bottom: 0;
    top: 0;
    background: $colour-white;
    box-shadow: 0 0 10px #ebecf0;
    color: $colour-tundora;

    h3 {
      font-weight: normal !important;
      background: #f4f5f7 !important;
      padding: 15px 24px !important;
      margin: 0 -24px !important;
      border: none !important;
      clear: both !important;
      color: #172b4d !important;

      i {
        margin-right: 5px;
      }

      &.need-help {
        margin-bottom: -1px !important;
        padding: 15px 14px 15px 24px !important;
      }
    }

    h4 {
      margin-bottom: 10px !important;
      margin-top: 5px !important;
    }

    .need-help-block {
      font-size: 0.857rem;
      line-height: 1.5;
      padding: 15px 0 10px 0;

      h1 {
        display: none;
      }

      h4 {
        font-size: 12px !important;
        font-weight: bold !important;
        line-height: 1.5 !important;
        margin: 0 0 5px 0 !important;
        border: 0px !important;
      }

      p {
        font-size: 0.857rem;
        line-height: 1.5;
        margin: 0 0 5px 0;
      }

      hr.dotted {
        border-top: 2px dotted $colour-gray;
      }
    }

    .pages-container {
      margin: 20px 0 20px -25px;
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        top: 20px;
        bottom: 7px;
        left: 30px;
        width: 0;
        border-left: 2px dashed #979797;
      }
      li {
        position: relative;

        &.first:before {
          border: 0;
        }

        &:before {
          content: ' ';
          top: 14px;
          left: 5px;
          width: 16px;
          height: 0;
          position: absolute;
          border-top: 2px dashed #979797;
        }

        line-height: 1.571rem;
        margin-top: 14px;
        list-style: none;
        padding-left: 25px;

        i {
          margin-right: 5px;
        }

        a {
          text-decoration: none !important;
          color: $colour-tundora !important;
          @extend .graceful-transition;
          &:hover {
            @extend .graceful-transition;
            color: lighten($colour-tundora, 25%) !important;
          }
        }

        &.active {
          > a, > a:active, > a:hover {
            text-decoration: underline;
            font-weight: bold;
          }
        }

        &.optional {
          display: flex;
          align-items: center;
        }

        &.diabled {
          a {
            color: lighten($colour-tundora, 52%) !important;
            &:hover {
              color: lighten($colour-tundora, 50%) !important;
            }
          }
        }

        &.highlight-error {
          > a, > a:active, > a:hover {
            color: $colour-red !important;
          }
        }

        ul {
          margin-left: 0px;

          li {
            a {
              text-decoration: none;
            }

            &.active {
              > a, > a:active, > a:hover {
                text-decoration: underline;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    h4 {
      margin: 20px 0 13px;
      font-weight: bold !important;
    }

    .redirect-link-container {
      margin-bottom: 20px;

      h4 {
        border-bottom: none;
        margin-bottom: 10px !important;
        line-height: 1.571rem !important;
      }

      .radio-wrapper {
        margin-left: 20px;

        input[type="radio"] {
          float: left;
          margin-top: 4px;
          margin-left: -20px;
          margin-right: 10px;
        }
      }

      .input {
        input {
          margin-top: 10px;
        }
      }

      label span {
        font-size: 1rem !important;
        color: $colour-gray-dark !important;
        font-style: normal !important;
      }
    }

    .empty-text {
      margin-top: 20px;
      font-weight: bold;
    }

    .left-sidebar-container {
      float: left;
      min-width: 100%;
      margin-right: -3px;
      margin-bottom: 25px;
      margin-top: 15px;

      ul {
        margin: 0;
      }

      > div:last-of-type li.option {
        margin-bottom: 0;
      }

      .colorSwatches,
      .categoryHeader {
        h4 {
          position: relative;
        }

        i {
          float: right;
        }
      }

      .colorSwatches {
        .colorSwatchOverlay {
          background: $colour-white-transparent-50;
          position: absolute;
          top: 40px;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 4;
        }

        input:checked + .colorSwatchOverlay {
          display: none;
        }

        .colorSwatchGroup {
          position: relative;
        }

        & + .colorSwatches {
          margin-top: 15px;
        }

        h4 {
          border-bottom: 1px solid $colour-gray;
          padding-bottom: 5px;
        }
      }
    }

    li.option {
      width: 28px;
      height: 28px;
      list-style: none;
      float: left;
      margin: 3px 3px 3px 0;

      div.colour {
        width: 100%;
        height: 100%;
        -webkit-transition: border 0.2s ease-in-out;
        transition: border 0.2s ease-in-out;
        border: 1px solid rgba(255,255,255,0);
        box-sizing: border-box;
      }

      &:hover, &.selected {
        box-sizing: border-box;
        padding: 0;
        border: 1px solid $colour-blue-oxford;

        .colour {
          box-sizing: border-box;
          border: 1px solid $colour-white;
          -webkit-transition: border 0.1s ease-in-out;
          transition: border 0.1s ease-in-out;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    pm-pagination-compressed {
      margin-top: 16px;
      display: block;

      > div {
        float: none;
      }
    }
  }

  .modal-body {
    .navigation-border {
      background-color: $colour-gray-shuttle !important;
      border-color: 1px solid $colour-gray-chateau !important;
    }

    ul.tab-navigation {
      li {
        border-color: $colour-gray-88 !important;

        a {
          color: $colour-white !important;
        }
      }

      li.selected-tab {
        border: 1px solid $colour-gray-chateau !important;
        border-bottom: none !important;
        background-color: $colour-gray-shuttle !important;
      }
    }

    pm-file-uploader {
      margin: 0 0 10px 0 !important;
    }

    .preview-image {
      .input {
        padding-top: 20px;
        .checkbox {
          margin-bottom: 0;
          input {
            position: static;
          }
        }
      }
    }

    .icon.icon-disabled {
      position: relative;
      top: -32px;
      right: 8px;
      float: right;
    }

    label.radio {
      display: inline-block;
      line-height: 1.786rem;
      padding-right: 10px;
      float: none !important;
      width: auto !important;
      .check {
        display: inline-block;
        border: 1px solid $colour-gray;
        border-radius: 100%;
        height: 15px;
        width: 15px;
        transition: border .15s linear;
        -webkit-transition: border .15s linear;
        position: relative;
        margin: 0 5px -3px;

        &::before {
          display: block;
          position: absolute;
          content: '';
          border-radius: 100%;
          height: 9px;
          width: 9px;
          top: 3px;
          left: 3px;
          margin: auto;
          transition: background 0.15s linear;
          -webkit-transition: background 0.15s linear;
        }
      }

      input[type=radio]:checked ~ .check::before {
        background: $colour-green;
      }

      input[type="radio"] {
        visibility: hidden;
        position: absolute;
      }

      &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
      }
    }
  }

  [ng-controller="exception"] {
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1001;
  }

  .social-icons-modal-form {
    .error-message {
      margin-bottom: 10px;
      margin-top: -10px;
    }

    .social-icons-modal-head {
      padding-bottom: 15px;

      .social-icons-modal-buttons {
        text-align: right;
      }
    }

    .social-icons-modal-option {
      padding: 15px;
      border-radius: 4px;
      background: $colour-gray-light;
      margin-bottom: 10px;
      vertical-align: middle;

      .social-icons-modal-link {
        background: $colour-gray-funnel;
        border: 1px solid $colour-gray-88;
        border-right: none;
        padding: 7px 10px;
        margin-right: -15px;
        color: $colour-boulder;
        border-radius: 4px 0 0 4px;
      }

      .social-icons-modal-col-checkbox {
        position: absolute;
        width: 30px;
        z-index: 1;
      }

      .social-icons-modal-col-icon {
        padding-left: 40px;
        width: 11%;
      }

      .col-md-6 {
        width: 55.333333%;
      }

      .col-md-10 {
        width: 88.666666%;
      }

      .social-icons-modal-col-checkbox,
      .social-icons-modal-col-icon {
        line-height: 2.571rem;

        input {
          vertical-align: middle;
        }
      }

      .social-icons-modal-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        color: $colour-white;
        text-align: center;
        border-radius: 50%;
        vertical-align: middle;
        line-height: 2.143rem;
        font-size: 1.357rem;

        &.icon-twitter {
          background: #000000;
        }

        &.icon-linkedin {
          background: #007ab6;
        }

        &.icon-facebook {
          background: #4866a8;
        }

        &.icon-youtube {
          background: #ff3333;
        }

        &.icon-googleplus {
          background: #c73d2d;
        }

        &.icon-xing {
          background: #005a5f;
        }

        &.icon-instagram {
          background: #517ea3;
          font-size: 1.143rem;
        }

        &.icon-vimeo {
          background: #00aced;
        }

        &.icon-pinterest {
          background: #c91f27;
          font-size: 1.643rem;
        }

        &.icon-blog {
          background: #4f4f4f;
        }

        &.icon-wechat {
          background: #51c332;
        }

        &.icon-weibo {
          background: #e1162c;
        }

        &.icon-youku {
          background: #00a8fe;
        }

        &.social-icons-modal-icon-disabled {
          background: #b1b4b3;
        }
      }

      .input {
        clear: none;
        padding-right: 0;

        input {
          border-radius: 0 4px 4px 0;
          border: 1px solid $colour-gray-88 !important;
          box-shadow: none;
        }
      }

      .social-icons-modal-col-icon + .input {
        padding-left: 0;
        padding-right: 15px;

        input {
          border-radius: 4px;
        }
      }
    }
  }

  #editCoBrandedPdfEditor,
  #editAnimatedBannerEditor {
    float: left;
    padding: 30px 30px 0 30px;
    width: 250px;
    min-height: calc(100% - 29px);
    background: #FFFFFF;
    box-shadow: 0 2px 10px #c1c4d2;

    a.update-preview {
      float: right;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

  #editCoBrandedPdfEditor {
    overflow-y: auto;
    padding-right: 50px;

    h2 + .hr {
      display: none;
    }

    .hr {
      margin: 24px 0 19px;
      border: solid 1px #c1c4d2;
    }

    .pm-image-option-container {
      height: auto !important;
    }

    .update-wrapper {
      position: absolute;
      left: -250px;
      bottom: 0;
      width: 550px;
      padding: 15px;
      background-color: #f4f5f7;
      box-shadow: 0 -2px 5px 0 rgba(193, 196, 210, 0.5);
      z-index: 3;

      .button {
        margin: 0;
      }
    }
  }

  #editAnimatedBannerPreview {
    float: left;
    margin-top: 30px;
    padding: 0 0 0 30px;
    width: calc(100% - 341px);

    iframe {
      overflow: hidden;
    }
  }

  #editCoBrandedPdfPreview {
    float: left;
    margin-top: 30px;
    width: 100%;
    height: calc(100% - 70px);
    padding: 0;

    h2 {
      padding-left: 30px;
    }
    iframe {
      overflow: auto;
      width: 100%;
      height: 100%;
    }
    .loading-container, .loading-content-wrapper {
      width: 100%;
      height: 100%;

      .loading {
        background: transparent;
      }
    }
  }

  #editCoBrandedPdfEditor + #editCoBrandedPdfPreview {
    width: calc(100% - 331px);
  }

  #editCoBrandedPdfEditor > h2,
  #editCoBrandedPdfPreview > h2,
  #editAnimatedBannerEditor > h2,
  #editAnimatedBannerPreview > h2 {
    font-size: 1.214rem !important;
    margin: 0 0 10px 0 !important;
    font-weight: bold;
  }
}

#angular-root {
    .accented-box {
        background: $colour-concrete;
        padding: 15px 0;
        margin: 40px 0;

        & > div > div.input {
            padding: 5px 15px 5px 0;
            width: 200px;
            display: inline-block;
            vertical-align: middle;
        }

        .updated-info {
            display: inline-block;
            vertical-align: middle;
            padding: 11px 0;
            font-size: 0.929rem;
        }

        .button {
            margin: 0;
            margin-bottom: 0 !important;
        }
    }

    template-thumbnail {
        display: block;
        margin: 0 20px 0 0;
        float: left;
        border: 5px solid $colour-gray;
        background: $colour-gray;

        [ng-if="url.small"] {
            font: 0/0 serif !important;
            text-shadow: none !important;
            color: $colour-transparent !important;
            height: 145px !important;
            line-height: 145px !important;
            text-align: center !important;
            background: $colour-white !important;

            img {
                vertical-align: middle !important;
                max-height: 145px !important;
                max-width: 120px !important;
            }
        }

        .modal-template-thumbnail-large {
            max-width: 480px !important;
            max-height: 360px !important;
        }

        .loading-container {
            min-height: 145px !important;
            min-width: 120px !important;

            .button {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 10px;
            }
        }
    }

    .no-padding {
        padding: 0 !important;

        &-left {
            padding-left: 0 !important;
        }

        &-right {
            padding-right: 0 !important;
        }

        &-top {
            padding-top: 0 !important;
        }

        &-bottom {
            padding-bottom: 0 !important;
        }
    }

    form.base {
        .created-updated {
            position: absolute;
            right: 0;
        }
    }

    campaigns-list, .campaigns-list {
        display: block;

        .list-subheader {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.43;
            padding: 12px 15px;
            margin-bottom: 10px;
            background: $colour-gray-light-1;
        }

        .campaign-group {
            margin-bottom: 10px;

            &.has-sub-campaigns {
                .subs-and-templates {
                    border: 1px solid $colour-gray-light-1;
                    border-top: none;
                    padding: 20px 20px 0;
                    margin: 0;
                }
            }

            &.sv-helper, &.sv-placeholder {
                height: auto !important;

                .subs-and-templates {
                    display: none;
                }
            }

            &.sv-placeholder .campaign {
                margin: -1px 0;
            }
        }

        &.activities-list {
            .campaign {
                .top-line {
                    & > .actions {
                        width: auto;
                    }
                }
            }
        }

        .campaign {
            .top-line {
                height: 45px;
                background-color: $colour-concrete;

                &.no-drag {
                    padding-left: 20px;
                }

                & > .actions {
                    width: 135px;

                    &.sub-campaing-actions {
                        width: 162px;
                    }
                }
            }

            .drag-spot {
                float: left;
            }

            h2 {
                float: left;
                margin: 0 0 0 10px;
                line-height: 3.214rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 500px;
            }

            .checkbox-input label {
              line-height: 3.214rem;
              min-width: 30px;
            }

            .option1 {
                float: left;
                margin-top: 6px;
                width: 85px;
                text-align: right;
            }

            .option2 {
                float: left;
                margin: 6px 15px 0 0;
            }

            .elements {
                float: right;
                border-left: 10px solid #fff;
                height: 45px;
                padding: 0 11px;
                width: 270px;

                .button-wrapper {
                    margin-top: 6px;
                }

                .actions .actions-divider {
                    margin-top: -6px;
                }

                .pill-buttons {
                    margin-top: 9px !important;

                    li a {
                        padding-right: 15px !important;
                        font-size: 0.857rem;
                    }
                }
            }

            .actions {
                float: right;
                white-space: nowrap;

                a.stack.arrow-down,
                a.stack.arrow-up {
                    margin-left: 7px;
                }

                a.button {
                    margin: 10px 0 0;
                }

                .actions-divider {
                    border-right: 1px $colour-gray solid;
                    margin: 0 10px;
                    float: left;
                    height: 45px;
                }
            }
        }

        .subs-and-templates {
            margin-top: 15px;
            border-bottom: 1px solid $colour-gray-light-1;

            .top-line {
                height: 38px;
                background-color: $colour-iron;
                border: 1px solid $colour-gray-light-1;
                border-bottom: none;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 20px;
                }

                .drag-spot {
                    float: left;
                    background: $colour-gray-light-1;
                    line-height: 2.714rem;
                }
            }

            a.sub-name {
                float: left !important;
                margin-left: 15px !important;
                line-height: 2.714rem !important;
                color: $colour-gray-dark !important;
                text-decoration: none !important;
            }

            .actions {
                float: right;

                a.button {
                    margin: 4px 6px 0 0;
                }

                a.stack.x1 {
                    margin: 6px 0 0 6px;
                }

                a.stack.arrow-down {
                    display: inline-block;
                    height: 38px;
                    width: 38px;
                    text-align: center;
                    background-color: $colour-gray;
                    margin-left: 6px;

                    span, i {
                        top: 8px;
                    }
                }
            }
        }
    }

    templates-list, campaigns-list, .campaigns-list, .templates-list {
        .drag-spot {
            display: inline-block;
            height: 100%;
            width: 20px;
            font-size: 0.714rem;
            background-color: $colour-gray;
            position: relative;
            text-align: center;
            line-height: 3.214rem;
            cursor: -webkit-grab;
            cursor: -moz-grab;
            margin-right: 15px;

            &:after {
                content: "\e622";
                font-family: 'partnermarketing';
                display: inline-block;
                vertical-align: middle;
            }
        }

        .sv-helper, .sv-placeholder {
            height: auto !important;

            templates-list, .campaigns-list {
                display: none;
            }
        }

        .sv-placeholder {
            background-color: $colour-white !important;
            border: 1px dashed $colour-gray-light-1 !important;
            opacity: 1 !important;
            visibility: visible !important;
            margin-bottom: 10px;

            > * {
                visibility: hidden !important;
            }

            .top-line {
                margin: -1px 0;
            }
        }
    }

    templates-list, activities-list {
        display: block;
        margin-bottom: 9px;

        table {
            margin-bottom: 0;
        }

        .filter-wrapper {
            background: $colour-concrete;
            border: solid 1px $colour-gray-light-1;

            .float-input {
                width: 50%;
            }
        }

        .no-items-placeholder {
            background: url("/images/asset-library/document.svg") 0 50% no-repeat $colour-transparent;
            background-size: 34px;
            padding-left: 50px;
        }

        .language-header {
            padding: 10px;
            background: $colour-gray-light-1;
            margin: 10px 0;
        }

        .templates-header {
            margin-bottom: 5px !important;
        }

        .templates-list {
            margin-bottom: 20px;

            ul {
                margin: 0;
                font-size: 0.929rem;
            }

            a {
                color: $colour-gray-dark !important;
                text-decoration: none;
                font-weight: bold;

                &.button {
                    color: $colour-white !important;
                }
            }

            li {
                list-style: none;
                margin: 5px 0;
                border-top: 1px solid $colour-gray-light-1;
                border-bottom: 1px solid $colour-gray-light-1;
                background-color: $colour-concrete;
                box-sizing: border-box;

                .table {
                    display: table;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 0 !important;
                }

                .table-row {
                    display: table-row;
                }

                .table-row > div {
                    vertical-align: middle;
                    display: table-cell;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    border-right: 1px solid $colour-gray-light-1;
                    height: 100%;
                    line-height: 1.2;

                    &.drag-spot-holder {
                        border-left: 1px solid $colour-gray-light-1;
                        position: relative;
                        height: 3.214rem;
                        padding-left: 30px !important;

                        .drag-spot {
                          position: absolute;
                          top: 0;
                          left: 0;
                          line-height: 1;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }

                        &.col-checkbox {
                            padding: 5px 0;
                            .drag-spot {
                                margin-right: 4px;
                            }
                        }
                    }

                    .has-updates {
                      position: absolute;
                      width: 10px;
                      height: 10px;
                      background: #dd8524;
                      border-radius: 5px;
                      margin-left: 3px;
                    }
                }
            }

            .sv-placeholder {
                margin: 3px 0;
            }
        }

        .col-checkbox {
            width: 5%;

            input[type="checkbox"] {
                margin-left: 0;
            }
        }

        .col-id {
            width: 8%;
        }

        th.col-checkbox {
            padding-left: 30px;
        }

        .col-version {
            width: 7%;
        }

        .col-name {
            width: 38%;
        }

        .col-type {
            width: 10%;
        }

        .col-status {
            width: 10%;
        }

        .col-label {
            width: 17%;
        }

        .col-actions {
            width: 5% !important;
            text-align: center;
        }
    }

    activities-list {
        .col-name {
            padding-left: 30px;
            width: 65%;
        }

        .col-version {
            width: 12%;
        }

        .col-status {
            width: 18%;
            text-transform: capitalize;
        }

        .col-actions {
            width: 5% !important;
        }

        h4 {
            font-weight: bold !important;
            line-height: 1.43;
        }
    }

    form.campaign-form,
    form.template-form {
        .versions-panel {
            .buttons-section {
                padding-top: 5px;

                a {
                    margin-left: 10px;
                }
            }
        }

        .input {
            .status-wrapper {
                .status-select {
                    padding: 10px 15px;
                    margin-left: 100px;
                    border-radius: 5px;

                    .float-input {
                        width: 340px;
                    }
                }

                label {
                    &.short {
                        width: 100px;
                        line-height: 3.214rem;
                    }
                }
            }
        }
        .disabled {
            background: $colour-white-transparent-50;
        }

        .info-block {
            background: $colour-black-transparent-80;
            padding: 15px 20px;
            margin: -20px 0 20px;

            p {
                line-height: 1.5rem;
                margin-bottom: 0;
                margin-left: 45px;
                color: $colour-white;
            }
        }

        .lock-container {
            position: relative;

            .overlay {
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: $colour-white-transparent-50;
            }

            .asset-label {
                z-index: 100;
            }

            .divider {
                margin-bottom: 30px;
            }

            h2 {
                font-weight: bold;
            }

            .input.time {
                select {
                    width: 15%;
                    margin: 0 5% 0 3%;
                    float: left;
                }

                label {
                    width: auto;
                    min-width: unset;
                }
            }
        }

        hr {
            border-width: 0 0 1px 0;
            border-bottom: 1px solid $colour-gray;
        }

        .relative {
            position: relative;

            .default-image-row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                img {
                    width: auto;
                    max-height: 300px;
                }
            }

            img {
                margin-bottom: 20px;
                width: 95%;
            }
        }
    }

    form.campaign-form {
        .additional-languages {
            margin-top: 15px;
        }
    }

    template-uploader {
        display: block;

        h2 {
            font-weight: bold;
        }

        hr {
            margin-bottom: 25px;
        }

        .template-upload-mode {
            display: flex;
            margin-bottom: 30px;

          .upload-mode-item {
            width: 50%;
            border: 1px solid #cccccc;
            height: 50px;
            padding: 5px;
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
            position: relative;

            .icon-holder {
              margin-bottom: 5px;
            }

            &:after, &:before {
              top: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }

            &.active:after {
              border-width: 10px;
              margin-left: -10px;
            }

            &.active:before {
              border-width: 11px;
              margin-left: -11px;
            }
          }

          .upload-files {
            border-right-color: #ffffff;
          }

          .use-default {
            border-left-color: #ffffff;
          }
        }

        .default-template-items {
            border-top: 0;
            padding-top: 10px;

            & > p {
                margin-bottom: 25px;
            }

            .default-template-item {
                position: relative;
                border: solid 1px #ebecf0;
                margin-bottom: 30px;
                padding-bottom: 40px;
                overflow: hidden;
                min-height: 390px;

                .thumbnail {
                    border-bottom: solid 1px #ebecf0;
                    background-color: #f4f5f7;
                    padding: 28px;
                    height: 160px;
                    text-align: center;

                    img {
                        max-height: 100%;
                    }
                }

                .name {
                    padding: 25px 20px 15px 30px;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 1.43;
                    color: #6b778d;
                }

                .description {
                    padding: 0 20px 15px 30px;
                    font-size: 0.929rem;
                    line-height: 1.38;
                    color: #6b778c;
                }

                .btns {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    .button {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .thumbnail-preview {
            max-width: 538px;
            max-height: 157px;
        }

        .accented-box {
            margin: 0;
            padding: 15px;
        }

        pm-file-uploader {
            p[ng-bind="message"] {
                margin-top: 25px;
            }

            p[ng-show="isDragAndDropSupported"] {
                display: none;
            }
        }

        pm-file-uploader + div {
            margin-top: 15px;
        }

        .files-list {
            li {
                padding-left: 25px;
            }
        }
    }

    .legend {
        padding: 30px 0;
        overflow: hidden;
        clear: both;

        span, i {
            &.icon {
                color: $colour-gray-2;
            }
        }

        .legenedItem {
            margin-right: 15px;
        }
    }

    .campaign-form {
        h2 {
            font-size: 120% !important;
            margin: 15px 0 !important;
            font-weight: bold;
        }

        h3 {
            margin: 20px 0 5px !important;
            padding: 0 !important;
            font-size: 1rem !important;
            font-weight: bold;
            line-height: 1.43;
            background: transparent !important;
            border: none !important;
        }

        h4 {
            margin: 0 0 20px !important;
            line-height: 1.4 !important;
            max-width: 80%;
        }

        div.input {
            padding-bottom: 0;
        }

        .form-hint {
            margin: 15px 0;
        }

        .float-input {
            margin-bottom: 5px !important;
        }

        .add-language-area {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .add-language {
                background-color: $colour-concrete;
                padding: 20px 25px 0;
                width: 425px;
                min-height: 340px;
                position: relative;

                h3 {
                    margin-top: 0 !important;
                }

                .button {
                    &.secondary {
                        margin: 3px 0;
                        margin-bottom: 0 !important;
                        position: absolute;
                        bottom: 20px;
                        right: 25px;
                    }
                }

                .no-languages {
                    padding: 5px 10px;
                }
            }
        }

        .add-language {
            .button {
                margin-top: 8px !important;
            }
            .float-input {
                margin: 5px 0 !important;
            }
        }


        label {
            display: inline-block;
            margin: 5px 0;

            input[type="radio"] {
                margin-top: -2px;
                margin-left: 1px;
            }
        }

        .radio-group label {
            margin-right: 20px;

            input[type="radio"] {
                margin-top: 4px;
            }
        }

        hr {
            padding-top: 0 !important;
            margin: 30px 0 !important;
        }

        .add-language {
            padding: 10px;
            background-color: $colour-concrete;

            .input {
                padding: 0;

                .button {
                    margin: 3px 0;
                    margin-bottom: 0 !important;
                }
            }

            .no-languages {
                padding: 5px 10px;
            }
        }

        .additional-languages {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $colour-gray;
        }

        &.activity-form {
            .half-width {
                width: 50%;
            }
        }

        .insert-link-form {
            label {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .translations-list {
        h2 {
            margin-top: 30px !important;
            font-size: 1.143rem !important;
            font-weight: bold;
        }

        .row {
            label.image {
                padding-left: 15px;
                width: 145px;
            }
        }

        input[type="checkbox"] {
            margin-top: 4px;
        }

        .strong {
            font-weight: bold;
            margin-bottom: 25px;
        }

        .error-message {
            margin-bottom: 30px;
        }

        div.input {
            label.wide-label {
                width: auto;
            }
        }

        .translations {
            label {
                input {
                    margin-right: 10px;
                }
            }
        }

        .nested-under {
            padding: 25px 0 0 0;

            label {
                display: block;
                width: 100%;
                float: none;
                padding: 0 0 10px 0;
            }
        }

        .input-validated.col-md-4, .input-validated.col-md-7 {
            width: 310px !important;
        }

        &.col-md-7 {
            .input-validated.col-md-4, .input-validated.col-md-7 {
                width: 372px !important;
            }
        }
    }

    .template-setup-group {
        .content-header {
            margin-bottom: 30px;
        }

        .title {
            margin-bottom: 30px;
        }

        form {
            p {
                padding-left: 0;
            }
        }
    }

    .template-list-group, .template-setup-group {
        .content-underheader {
            &.col-md-8 {
                padding-left: 0;
                float: none;
            }
        }
    }

    default-template-colour {
        form {
            padding: 20px 30px 5px 30px !important;

            .one-option {
                padding-bottom: 15px !important;

                label {
                    min-width: 30px !important;
                }

                .button {
                    margin: 5px 0 0 5px !important;
                }
            }
        }
    }

    default-template-image,
    default-template-link,
    default-template-text,
    default-template-colour,
    default-template-toggle {
        h4 {
            .stack {
                margin: 10px 5px 7px 0;
                background: #ffffff;
                text-align: center;
            }
        }

        .top-line.is-deleted {
            opacity: 0.5;
        }

        .list-table {
            label {
                min-width: 85px;
            }
        }
    }

    language-form, instructional-copy, .editable-language-form, .default-template-data {
        display: block;

        .top-line {
            height: 3.571rem;;
            background-color: $colour-concrete;
            border: 1px solid $colour-gray;
            box-sizing: border-box;

            &.disabled {
                opacity: 0.5;
            }

            h4 {
                float: left !important;
                margin: 0 0 0 15px !important;
                line-height: 3.571rem !important;
            }

            .dates {
                float: right !important;
                margin: 0 40px 0 15px !important;
                line-height: 3.571rem !important;
            }

            .checkbox-wrapper {
                float: right;
                margin-right: 10px;
                margin-top: 17px;
            }

            .actions {
                float: right;

                a.remove {
                    margin: 15px;
                }

                a.stack.arrow-down {
                    display: inline-block;
                    height: 3.571rem;
                    width: 3.571rem;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    box-sizing: border-box;
                    margin: -1px -1px 0 0;

                    .icon {
                        top: 14px;
                    }
                }
            }

            &.hidecaption {
                height: auto;
                background-color: transparent;
                border: none;
                box-sizing: unset;

                h4 {
                    font-weight: bold !important;
                    margin: 0 !important;
                }
            }
        }

        .top-line.closed {
            margin-bottom: 15px;
        }

        .form-content {
            padding: 20px 30px;
            border: 1px solid $colour-gray;
            border-top: none;
            margin-bottom: 15px;

            .input-status {
                label {
                    margin-top: 15px;
                }

                .float-input {
                    padding: 8px;
                    margin-bottom: 30px !important;
                }

                select {
                    width: 45%;
                }
            }

            cover-image-uploader {
                margin-top: 0;
            }

            activity-icon-uploader {
                action-panel {
                    margin-top: 0;
                }

                pm-file-uploader {
                    width: auto !important;
                }

                img {
                    float: left;
                    max-width: 70px;
                    max-height: 70px;
                    margin-right: 25px;
                }
            }

            activity-image-uploader {
                action-panel {
                    margin-top: 0;
                }

                pm-file-uploader {
                    width: auto !important;
                }

                img {
                    float: left;
                    max-width: 70px;
                    max-height: 70px;
                    margin-right: 25px;
                }
            }
        }
    }

    .default-template-data {
        label {
            margin-right: 50px;
            min-width: auto;
        }
    }

    #pmContent table td.default-template-link-row {
        padding: 5px 10px;
        height: 38px;
    }

    .instructional-copy {
        h2 {
            font-weight: bold;
            margin-top: 30px;
        }

        hr {
            margin-bottom: 25px;
        }

        .pull-right {
            .input {
                line-height: 2.857rem;
                margin-right: 20px;
                padding-bottom: 0;
                clear: none;

                input {
                    margin-right: 5px;
                    margin-left: 10px;
                    margin-top: 4px !important;
                }

                label {
                    line-height: 1.786rem;
                    width: auto;
                    min-width: 0 !important;
                    margin-top: 10px !important;
                    padding: 0 !important;
                    vertical-align: middle;
                }
            }
        }

        .ta-editor {
            padding-bottom: 43px !important;
        }
    }

    form.campaign-form workflow-rule {
        display: block;
        padding: $smallPad;
        border: 1px solid $colour-gray;
        margin: $smallPad 0;

        & [ng-form].ng-invalid {
            border: none !important;
        }

        .rule-footer {
            padding: $xSmallPad;
            margin: -$smallPad;
            margin-top: 0;
            background: $colour-concrete;
            text-align: right;
        }

        .rule-label {
            font-weight: bold;
        }

        .rule-actions {
            margin-bottom: $xSmallPad;
        }
    }

    workflow-rule-action {
        display: block;
        margin-bottom: $xSmallPad;
    }

    .template-edit-panel-warning {
        z-index: 2;
        height: 20px;
        padding: 20px 25px;
        color: $colour-white;
        border-top: 1px solid $colour-white;

        &.icon-disabled:before {
            position: relative;
            bottom: -1px;
            left: -2px;
        }
    }

    .template-edit-panel {
        padding: 15px 24px 15px;
        position: sticky;
        top: 0;
        z-index: 12;
        background-color: #f4f5f7;
        box-shadow: 0 0 10px $colour-boulder;


        > .wrapper-inner-margin {
            margin-bottom: 0 !important;
            min-width: 0;
            max-width: 100%;

            .actions {
                margin: 7px 0;

                a.save-review {
                    margin-right: 10px;
                    text-decoration: underline;
                }

                .stats {
                    margin: 0 5px 0 10px;
                }
            }

            .title {
                margin-left: 6px;

                .campaign {
                    display: block;
                    font-size: 1rem;
                    color: #6b778c;
                }
                .asset {
                    display: block;
                    font-size: 1.143rem;
                    font-weight: bold;
                    color: #172b4d;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .loading-container {
                height: 42px;

                .loading {
                    background: transparent;
                }
            }
        }

        a.button {
            margin-bottom: 0 !important;
        }
    }

    .template {
        background: $colour-wild-sand;
    }

    .template-fields {
        padding: 11px 25px;
        border-bottom: 2px solid $colour-gray-88;

        form.email-template-fields {
            div.input {
                padding-bottom: 0;

                label {
                    margin-top: 7px;
                    padding-right: 20px;
                    width: auto;
                }

                .float-input {
                    width: 650px;
                }
            }
        }
    }
    .campaigns-list .campaign, campaigns-list .campaign {
        h2 {
            font-size: 1rem !important;
            margin-bottom: 0 !important;
        }
        activity-icon {
            margin-left: 10px;
        }
    }

    .manage-templates-list {
        b {
            font-weight: bold;
        }

        .filtering-results {
            font-size: 1.429rem;
            margin-bottom: $mediumPad;

            .button.tertiary {
                margin: 0 0 0 $smallPad !important;
                vertical-align: middle;
            }
        }

        .download-csv-button {
            position: relative;
            top: -55px;
        }
    }

    .assets-filters-container {
        padding: $smallPad $mediumPad;
        background: $colour-concrete;
        border: 1px solid $colour-gray-light-1;
        margin-bottom: $mediumPad;

        .input {
            padding-bottom: $smallPad !important;
        }

        .assets-filters-search {
            overflow: hidden;

            form.list-search-widget-without-button {
                float: none !important;

                input[type="text"] {
                    width: 100% !important;
                }
            }
        }

        hr.dotted {
            background: transparent;
            border-top-color: $colour-gray-dusty;
            margin: $smallPad 0;
        }
    }

    .status-bubble {
        line-height: 3.571rem;
        margin: 0 15px 0 0;

        > span {
            border-radius: 100px;
            display: inline-block;
            height: 12px;
            line-height: 1;
            padding: 5px 12px;
            font-size: 0.857rem;
            color: $colour-white;

            &.tertiary {
                background: $colour-gray;
                color: $colour-tundora;
            }

            &.warning {
                background: $colour-yellow;
            }

            &.success {
                background: $colour-fern-2;
            }
        }
    }
}

#pmContent {
    language-form {
        .form-content {
            .input-status {
                .float-input {
                    margin-bottom: 15px !important;
                }
            }

            .time-and-date {
                .icon-calendar {
                    margin: 7px 5px;
                    float: left;
                }

                .time select {
                    width: 79px;
                    margin: 0 3% 0 0;

                    &:last-child {
                        margin: 0;
                    }
                }

                &.end-date {
                    padding: 0 0 10px 0;
                }

                &.start-date,
                &.end-date {
                    label {
                        display: inline-block;
                        width: 160px;
                        padding: 0 0 5px;

                        &.ratio {
                            float: left;
                            min-width: 90px;
                            width: 90px;

                            input[type="radio"] {
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .status-bubble {
            text-align: right;
        }
    }
}

.show-multi-page-notice {
    .template-left-panel > *,
    .template-edit-panel,
    [ui-view="template"] {
        opacity: 0.3;
        pointer-events: none;
    }

    .template-left-panel {
        box-shadow: none;
    }
}
