.spin:before {
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    text-rendering: auto;
    display: inline-block;
}

.state-loading {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: $colour-tundora-transparent-70;
    z-index: 1001;

    &.hidden-content {
        background: $colour-white;
    }

    .spinner-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: $colour-tundora-transparent-70;
    }

    .spinner {
        position: fixed;
        width: 30px;
        height: 30px;
        padding: 25px;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        z-index: 1001;
        background-color: $colour-white;

        @include borderRadius(4px);

        .spin {
            display: block;
            margin-top: -8px;
        }
    }
}

#pmContent-loading > .state-loading .spinner .spin,
#pmContent > .state-loading .spinner .spin {
    margin-top: 0;
    line-height: 30px;
}

.loading-container {
    position: relative;

    &.active {
      &.transparent {
        .loading {
          background-color: transparent;
        }
        .loading-content-wrapper {
          opacity: 0.2;
        }
      }
    }
    .loading {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 50px;
        min-width: 50px;
        background-color: $colour-white;
        z-index: 999;
    }

    &.static-loader .loading {
      position: static;
      background-color: transparent;
    }

    .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;
        z-index: 999;
    }

    &.active + .loading-content-wrapper {
      visibility: hidden;
    }
}
