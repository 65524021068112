/**
 * Main layout styles
 */
html, body {
    margin: 0;
    padding: 0;
    min-width: 970px;
    background-color: $colour-concrete;
    min-height: 100%;
}

.container {
  width: 940px;
  max-width: none !important;
}

.content-section {
    border-top: 1px solid $colour-gray;
    padding-top: 30px;
    margin-top: 30px;

    > .section-header {
        font-size: 1.286rem;
        padding-bottom: 20px;
        font-weight: bold;
    }

    .form-hint {
        background: $colour-gallery-2;
    }
}

p {
    margin: 0 0 10px 0;
}

strong, b {
    font-weight: bold;
}

i:not(.fa):not(.icon):not(.stack) {
    font-style: italic;
}

ul {
    margin: 0 0 0 15px;
}

li {
    list-style: disc outside;
    margin: 0 0 5px 0;
}

html.draganddrop-active {
    height: auto;
}

body.lockBody {
    overflow: hidden;
}

#pmContent.deactivated {
    background-color: inherit;
}

.view {
    min-height: 300px;
}
.react-shown .view {
  min-height: 0;
}

hr {
    border: 0;
    border-top: 1px solid $colour-white;

    &.thin {
        border: 0px;
        border-top: 1px solid $colour-white;
    }

    &.dotted {
        background: $colour-gray-light;
        border-style: dotted none none;
        border-top-width: 2px;
    }

    &.dark {
        border-color: $colour-gray;
    }

    &.gray {
        border-color: $colour-gray-light-1;
    }

    &.dropdown-divider {
        display: inline-block;
        width: 94%;
        float: left;
    }
}

#pmContent {
    background-color: $colour-white;

    .wrapper {
        width: 100%;

        &.progress-bar {
            background-color: $colour-concrete;
        }
    }

    .wrapper-margin {
        margin: 0 20px 0 20px;
    }

    .wrapper-inner {
        margin: auto;
    }

    .wrapper-inner-margin {
        margin: 0 auto 40px auto;
    }

    .wrapper-transitional-text {
        margin: -5px 0 25px;
    }

    .col-content-wrapper {
        margin: 0 15px 40px;
    }

    .content-wrapper {
        h3 {
            background-color: $colour-gray-lighter;
            border: 1px solid $colour-gray-light-1;
            color: $colour-gray-dark;
            padding: 10px;
            font-size: 1.143rem;
            margin-bottom: 30px;
        }
    }

    .pagination-wrapper {
        clear: both;
        overflow: hidden;
        padding-bottom: 20px;

        ul {
            margin: 0 auto;

            li {
                float: left;
                list-style: none;

                a {
                    display: block;
                }
            }
        }
    }

    .compressed-pagination-wrapper {
        .pagination-page-number input {
            position: relative;
            top: 3px;
            padding: 4px 4px 3px;
            border-radius: 3px;
            border: 1px solid $colour-gray;
            width: 1.6em;
            margin-right: 3px;
            margin-left: 3px;
            text-align: center;
        }

        .pagination-total-records {
            position: relative;
            top: 5px;
            margin-right: 3px;
        }
    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .worker-results {
        .assets {
            border: 1px solid $colour-gray-light-1;
            border-radius: 10px;
            margin-bottom: 30px;

            &.good-assets {
                h2 {
                    background-color: $colour-green-light !important;
                }
            }

            &.warning-assets {
                h2 {
                    background-color: $colour-yellow-light !important;
                }

                p {
                    margin-bottom: 20px;
                }
            }

            h2 {
                line-height: 2.143rem;
                height: 30px;
                padding: 10px 20px;
                margin: 0;
                font-size: 1rem !important;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;

                .stack {
                    margin-right: 15px;
                    line-height: 2.143rem;
                }
            }

            .list {
                margin: 20px 30px;

                .asset {
                    border: 1px solid $colour-gray-light-1;
                    background-color: $colour-gray-lighter;
                    margin-bottom: 10px;
                    padding: 10px;

                    .item-name {
                        a, span {
                            color: $colour-gray-dark;
                            text-decoration: none;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}

.deactivated {

    #pmContent {
        background-color: inherit;

        .alert-dialog,
        .alert-notice {
            display: none;
        }

        header#globalHeader {
            border-bottom: none;
            margin: 0;

            .container {
                width: auto;
                padding: 0;

                #globalLogo {
                    #logoWrapper {
                        margin: 45px 0 35px;
                    }

                    .dashboard-logo {
                        display: block;
                    }
                }
            }
        }

        .view {
            min-height: 120px;

            #mainContent {
                text-align: center;
                background-color: $colour-white;
                width: 470px;
                display: inline-block;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                padding: 30px;
                border-radius: 7px;
                min-width: 470px;

                p {
                    color: $colour-tundora;
                    font-size: 1rem;
                    margin: 0;
                }

                a {
                    text-decoration: none;
                }

                h2 {
                    font-size: 1.143rem;
                    margin-bottom: 15px;
                    font-weight: bold;
                }

                b {
                    font-weight: bold;
                }

                i {
                    font-style: italic;
                }
            }
        }

        footer {
            border-top: none;
            text-align: center;
            margin: 40px 0 0px;
            padding: 0;

            .container {
                display: inline-block;
                width: auto;
                padding: 0;

                p {
                    font-size: 0.929rem;
                }
            }
        }
    }
}

[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}

.feedbackModal {
    font-size: 1rem;

    &.survey {
        margin-top: 25% !important;

        .range {
            padding-bottom: 10px;
        }
    }

    .textarea {
        margin-top: 22px;
    }

    .modal-footer {
        border: none !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .description {
        padding: 0 0 13px 0;
        p {
            margin: 0;
        }
    }

    .row {
        margin-bottom: 25px;
    }

    h4 {
        line-height: 1.43 !important;
        font-weight: bold !important;
        margin-bottom: 15px !important;
        max-width: 440px;
    }

    .radio-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label.radio {
            line-height: 1.429rem;
            padding-right: 10px;
            margin: 0;

            input[type="radio"] {
                position: static;
            }

            .check {
                display: none;
                border: 1px solid #cccccc;
                border-radius: 100%;
                height: 15px;
                width: 15px;
                transition: border .15s linear;
                -webkit-transition: border .15s linear;
                position: relative;
                margin: 0 5px -3px;
            }
        }
    }

    .range {
        display: flex;
        justify-content: space-between;
        position: relative;

        &.scale {
            padding: 0 5px 0 3px;
            font-size: 5px;
        }

        hr {
            position: absolute;
            border-radius: 6px;
            border-top: 6px solid #ffffff;
            top: -2px;
            width: 100%;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            z-index: 1;
            min-width: 15px;

            .dot {
                width: 15px;
                height: 15px;
                display: none;
                border-radius: 15px;

                &.active {
                    display: block;
                }
            }
        }
    }

    .slidecontainer {
        width: 100%; /* Width of the outside container */
    }

    /* The slider itself */
    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #ffffff;
        outline: none;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #3e8ddd;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #3e8ddd;
        cursor: pointer;
    }
}

.right {
    float: right;
}
