
.identifier-options {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

.identifier-option {
    display: inline-block;
    padding: 20px;
    border: 3px solid #f2f2f2;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;
    flex-shrink: 0;

    &.selected {
        border: 3px solid #e5bd00;
    }
}

.identifier-table {
    th, td, tr {
        border: 0 !important;
        cursor: default !important;
        vertical-align: top !important;
        height: 35px !important;
    }

    td:first-of-type {
        width: 300px;
    }

    th {
        font-weight: bold;
        width: 145px;
    }
}

.certificate-preview {
    border: 4px solid #f2f2f2;
    width: 100%;
}

#identifier-builder {
    .box-steps {
        label {
            span.ignore-span {
                display: inline;
            }
        }
    }
}