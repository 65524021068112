html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* SOURCE: https://github.com/ixkaito/unreset-css/blob/master/_unreset.scss */
.pm-ignore-styles {
    ul, ol {
        ul {
            list-style-type: circle;
        }

        ul, ol {

            ul {
                list-style-type: square;
            }
        }
    }

    thead,
    tbody,
    tfoot,
    tr {
        border-color: inherit;
        vertical-align: middle;
    }

    td, th {
        padding: 1px;
        vertical-align: inherit;
    }

    th {
        font-weight: bold
    }

    caption {
        text-align: center;
    }

    ul, menu {
        list-style-type: disc;
        margin: 1em 0;
        padding: 0 0 0 40px;
        &.tab-navigation {
            padding: 0;
        }
    }

    big {
        font-size: larger;
        font-weight: inherit;
    }

    small {
        font-size: smaller;
        font-weight: inherit;
    }

    sub, sup {
        font-weight: inherit;
        line-height: inherit;
        position: static;
    }

    sub {
        font-size: smaller;
        bottom: 0;
        vertical-align: sub;
    }

    sup {
        font-size: smaller;
        top: 0;
        vertical-align: super;
    }
}
