@import "../../../../vendor/font-awesome/scss/font-awesome";

@font-face {
    font-family: 'partnermarketing';
    src: url("/fonts/partnermarketing.eot?-rj7f3t");
    src: url("/fonts/partnermarketing.eot?#iefix-rj7f3t") format("embedded-opentype"), url("/fonts/partnermarketing.woff?-rj7f3t") format("woff"), url("/fonts/partnermarketing.ttf?-rj7f3t") format("truetype"), url("/fonts/partnermarketing.svg?-rj7f3t#partnermarketing") format("svg");
    font-weight: normal;
    font-style: normal;
}

// Font awesome overwrites/improvements
.stack {
    .fa {
        &.lg, &.x075, &.x1, &.x2, &.x2, &.x3, &.x4, &.x5 {
            line-height: inherit;
        }

        &.lg {
            font-size: (4em / 3);
            line-height: (3em / 4);
            vertical-align: -15%;
        }

        &.x075 {
            font-size: 0.75em !important;
        }

        &.x1 {
            font-size: 1.5em !important;
        }

        &.x2 {
            font-size: 2em !important;
        }

        &.x3 {
            font-size: 3em !important;
        }

        &.x4 {
            font-size: 4em !important;
        }

        &.x5 {
            font-size: 5em !important;
        }

        &.dark {
            color: $colour-mine-shaft;
        }

        &.light {
            color: $colour-white;
        }

        &.gray {
            color: $colour-gray;
        }

        &.warning {
            color: $colour-yellow;
        }

        &.success {
            color: $colour-fern-2;
        }

        &.error {
            color: $colour-valencia;
        }

        &.margin-right {
            margin-right: 5px;
        }

        &.margin-left {
            margin-left: 5px;
        }
    }
}

[class^="fa-icon-"]:before, [class*=" fa-icon-"]:before {
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1rem;
    vertical-align: middle;
}

.fa-icon-sign-in:before {
    content: "\f090";
}

.fa-icon-refresh:before {
    content: "\f021";
}
