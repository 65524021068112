#pmContent {
    .emulate-datepicker {
        width: 150px;
    }

    div[datepicker] {
        display: block;

        // Any 'width' or 'max-width' for this should be set in a specific element's use CSS.
        // There are additional colours set on this element and it's children in the client-specific CSS.

        table {
            background-color: $colour-white;
            padding-bottom: 0;
            margin-bottom: 0;
            table-layout: auto;

            td, th {
                text-align: center;
                padding: 0;

                button {
                    background-color: $colour-white;
                    border: none;
                    font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;
                    cursor: pointer;
                    width: 100%;
                    height: 37px;
                    line-height: 2.643rem;
                    padding: 0;

                    &:not(:disabled) > span {
                        &:not(.text-muted) {
                            color: $colour-gray-dark;
                        }

                        &.text-muted {
                            color: $colour-gray-dusty
                        }
                    }

                    &:disabled > span {
                        color: $colour-gray-dusty
                    }
                }

                button.selected {
                    color: $colour-white;

                    > span {
                        color: $colour-white;
                    }
                }

                .text-muted {
                    // color: $colour-nobel-2;
                }
            }

            td {
                width: 45px;
            }

            th {
                border: none;
                padding: 10px;

                tr {
                    border: none;
                }

                small {
                    color: $colour-white;
                }

                button {
                    color: $colour-white;
                    padding: 0;

                    strong {
                        color: $colour-white;
                    }
                }
            }
        }
    }
}
