#pmContent {

    #usersTable {
        .col-email {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .col-login {
            min-width: 100px;
        }

        .col-company {
            min-width: 100px;
            max-width: 130px;
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    #userListPage {
        .filters {
            padding: 1.1em 1.5em;
            margin-bottom: 2em;
            background-color: #f6f5f8;
            border: 1px solid #dddddd;

            form {
                line-height: 1;
            }

            form > div {
                display: inline-block;
                width: 45%;
                margin-right: 4%;
                padding-bottom: 0;
            }

            form label {
                width: 130px;
                float: left;
            }

            form select {
                width: 260px;
                float: right;
            }

            hr {
                margin-top: 1.5em;
            }
        }

        b {
            font-weight: bold;
        }
    }

    #rejectedUsersTable {
        thead th.col-actions {
            width: 49px;
        }
    }

    #pendingUsersTable {
        // Don't use table-layout: fixed; like on all other tables.
        table-layout: auto;
        margin-bottom: -30px;

        thead {
            display: none;
        }

        tbody td:last-of-type {
            width: 150px;
        }

        select {
            width: 100%;
            padding: 4px 7px;
        }

        input,
        .user-country-name {
            width: 100%;
            padding: 4px 7px;
        }

        .user-company-info {
            padding: 0;

            .stack {
                width: 20px;
                font-size: 1.143rem;

                .stack-inner {
                    line-height: 1.286rem;

                    &:before {
                        font-size: 0.571rem;
                    }
                }
            }

            .user-company-name {
                padding: 0 0 0 24px;
            }
        }

        .company-select-wrapper {
            display: inline-block;
            width: calc(100% - 100px);
        }

        .multiSelect > button {
            display: block;
            padding-left: 7px;
        }

        tbody tr {
            border-color: $colour-gray;
            border-style: solid;
        }

        .pending-users-table__email-address {
            border-width: 1px 1px 0 1px;

            .requested_date {
                float: right;

                span {
                    font-weight: bold;
                }
            }
        }

        .pending-users-table__actions {
            background-color: #f6f5f8;

            td {
                vertical-align: top;
                padding-right: 5px;
            }

            td:last-child {
                white-space: nowrap;
                font-size: 0;

                .button {
                    margin-right: 5px;
                    float: right;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .pending-users-table__spacer {
            border-width: 0;
            height: 30px;
        }

        // Undo the default tr background-color, and change it so the top row in each tbody is grey.
        tr:nth-child(even) {
            background-color: $colour-white;
        }

        .pending-users-table__email-address {
            background-color: $colour-table-row-odd;
        }

        tbody td {
            border-width: 0;
            height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        a.button {
            margin-bottom: 0;
        }

        company-select {
            .input {
                padding-bottom: 0;
            }
        }
    }

    .divider {
        border-bottom: 1px solid $colour-gray;
        margin: 10px 0 40px 0;
    }

    company-select {
        display: block;

        li {
            list-style-type: none;
        }
    }

    [company-logo-uploader] {
        margin-right: 30px;
        margin-bottom: 20px;
    }

    form.companyLogo [company-logo-uploader] {
        margin-right: 0;
    }

    #companyLogoRequirements {
        float: left;
    }

    #companyLogoAboveUploader,
    #companyLogoAboveUploader img {
        max-width: 538px;
        max-height: 157px;
    }

    .terms-and-conditions {
      .content-header {
        border-bottom: 0;
        margin-bottom: 0;
      }
      h4 {
        font-weight: bold;
        font-size: 100%;
      }
      .form-hint {
        margin: 24px 0;
      }
      .content-spacer {
        padding: 24px 0;
        margin: 0 0 24px 0;
        border-top: 1px solid $colour-gray;
        border-bottom: 1px solid $colour-gray;

        h4 {
          margin-bottom: 0;
        }

        .error-message {
          max-width: 550px;
        }
      }

      .content-wrapper {
        border: 1px solid $colour-gray;
        padding: 25px;
        margin-bottom: 20px;

        input[type="radio"] {
            margin-top: 3px;
        }

        h4 {
          margin-bottom: 10px;
        }
      }
    }

    .social-notice {
        background: $colour-concrete;
        border: 1px solid $colour-mercury-4;
        padding: 15px;
        margin: 26px 0;
    }

    .social {
        margin-top: 20px;

        .header, .status, .detail {
            padding: 10px;
        }

        .header {
            border: 1px solid $colour-mercury-4;
            .social-network-name {
                line-height: 2em;
                text-transform: capitalize;
                font-weight: bold;
            }

            .pull-right.button {
                margin: 0;
            }

            .stack {
                margin-right: 10px;

                .fa-twitter {
                    top: 1px;
                }

                .fa-facebook {
                    top: 1px;
                }
            }
        }

        .content {
            border: 1px solid $colour-mercury-4;
            border-top: none;
            background: $colour-concrete;

            .status {
                display: inline-block;
                width: 190px;
                border-right: 1px solid $colour-mercury-4;

                .stack {
                    margin-right: 18px;
                }
            }

            .detail {
                display: inline-block;
                width: 733px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
                padding: 0 0 0 10px;

                a {
                    color: inherit;
                    text-decoration: none;
                }

                .posting-to {
                    font-weight: bold;
                }

                .warning-box {
                    margin-bottom: 0;
                }
            }
        }

        .stack-outer {
            &.twitter {
                color: $colour-black;
            }

            &.linkedin {
                color: $colour-cerulean-deep;
            }

            &.facebook {
                color: $colour-san-marino;
            }
        }

        &.activate-social {
            padding-top: 10px;

            .button {
                margin: 7px 0 0 0;
                font-weight: bold;
            }

            input[type="checkbox"] {
                margin-top: 12px;
                width: 20px;
                height: 20px;
            }

            .warning-box {
                margin: 0 5px;
            }
        }
    }
    .social-network-parameters {
        i {
            font-style: italic;
        }

        .marketing-box-package {
            &:hover:not(.no-effect) {
                -webkit-transform: scale(1);
                transform: scale(1);
                border: solid 1px #d8d8d8;
            }

            .marketing-box-package-header,
            .marketing-box-package-body {
                background-color: #f2f2f2;
            }

            .marketing-box-package-header {
                border: none;
                padding-bottom: 0;

                &:after {
                    width: 100%;
                    content: '';
                    display: block;
                    border-bottom: 2px solid #cccccc;
                    margin-top: 5px;
                }
            }

            .marketing-box-package-body {
                font-style: italic;

                .form-hint {
                    padding-left: 1px;
                    .copy {
                        width: 93%;
                    }
                }
            }
        }
    }

    #addSocialAccountModal {
        div.input {
            padding-bottom: 25px;
        }
    }

    div[show-modal="showSocialConnectModal"] {
        h4 {
            font-weight: bold;
            margin: 0;
        }
    }

    form[name="userSettingsForm"] {
        h2 {
            font-weight: bold;
            font-size: 1.143rem;
        }
    }

    .multiple-helper {
        position: relative;
        padding-right: 25px;
        display: inline-block;

        span.stack {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -9px;
        }

        .tooltip {
            width: 150px;
        }
    }

    form.account {
        .info-block {
            .notice-message {
                font-style: normal;
            }
        }
    }

    .company-details-section {
        #limitedTextarea {
            height: 300px;
        }
    }

    .time-and-date {
        .input {
            padding-bottom: 25px;

            label {
                display: block;
                width: 100%;
                padding: 0 0 5px;
            }

            &.time select {
                width: 93px;
                margin: 0 3% 0 0;

                &:last-child {
                    margin: 0;
                }
            }

            &.time.small select {
                width: 26%;
            }
        }
    }

    .user-invite-multiple {
        h5 {
            font-size: 1.143rem;
            margin-bottom: 10px;
        }

        table {
            margin-bottom: 30px;

            tr {
                border: none;
                font-size: 1rem;
            }
        }

        &__spacer {
            width: 21px;
            padding: 0;
        }

        &__title {
            height: 41px;
            padding: 17px 20px;
            border: 1px solid $colour-gray-light-1;
        }

        &__list {
            border: 1px solid $colour-gray-light-1;
            border-top: none;
            padding: 17px 20px;
            vertical-align: top;

            li {
                margin-bottom: 2px;
            }
        }

        &__results {
            table {
                table-layout: auto;

                td.disabled {
                    opacity: 0.5;
                }
            }

            &__action {
                width: 100px;
            }
        }
    }
}
