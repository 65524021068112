@import "../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_button-groups";
@import "../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_buttons";

// Constructors
%button {
    padding: 10px 14px;
    margin: 0 0 20px 5px;
    display: inline-block;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25 ease-in-out;
}

.global-admin-wrapper .button {
    @include borderRadius(4px);

    &.large {
        @include borderRadius(5px);
    }

    &.small {
        @include borderRadius(3px);
    }

    &.mini {
        @include borderRadius(3px);
    }
}

/**
 * General button styling
 */
#pmContent, pm-tour {
    .button {
        @extend %button;

        line-height: 1.79;
        background: $colour-fern;
        color: $colour-white;
        text-decoration: none;
        cursor: pointer;
        outline: none;

        &-block {
            display: block;
        }

        &.align-center {
            text-align: center;
        }

        &.large {
            padding: 14px 18px;
        }

        &.small {
            padding: 6px 11px;
        }

        &.mini {
            padding: 1px 7px;
            line-height: 24px;
            min-height: 24px;
        }

        &.tertiary {
            background: $colour-gray;
            color: $colour-tundora;
        }

        &.white {
            background: $colour-white;
            color: $colour-tundora;
        }

        &.pm {
            background: $colour-fern;
        }

        &.danger {
            background: $colour-valencia;
        }

        &.warning {
            background: $colour-yellow;
        }

        &.success {
            background: $colour-fern-2;
        }

        &.info {
            background: $colour-green-persian;
            color: $colour-white;
        }

        &.error {
            background: $colour-valencia-3;
        }

        &.disabled {
            opacity: 0.3;
            filter: Alpha(opacity=30);
            cursor: default;
        }

        &.pull-left {
            margin-right: 12px;
        }

        &.pull-right {
            margin-left: 12px;

            div[class*="span"] > & {
                margin: 20px 20px 20px 0;
            }
        }

        &.no-margin {
            margin: 0;
        }

        &.no-margin-bottom {
            margin-bottom: 0px;
        }

        &.margin-left {
            margin: 0 0 0 5%;
        }

        &.margin-right {
            margin: 0 5% 0 0;
        }

        &.margin-right-px {
            margin: 0 5px 0 0;
        }

        &.margin-top {
            margin: 10px 0 0 0;
        }

        &.drop-down-left {
            @include border-left-radius(4px);
            @include border-right-radius(0);

            //Has to be included to override .button style
            min-width: 100px;
        }

        &.drop-down {
            margin-left: 1px;

            @include border-left-radius(0);

            //Has to be included to override .button style
            @include border-right-radius(4px);

            margin-bottom: 0;
        }

        /**
         * Pagination
         */
        &.pagination {
            padding: 3px 8px;
            font-size: 0.786rem;
            line-height: 1.429rem;
            color: $colour-gray-dark;
            background: $colour-gray-lighter;
            border: $colour-gray 1px solid;
            font-weight: bold;

            @include borderRadius(4px);

            margin: 3px;

            &.selected {
                background: $colour-gray;
            }
        }

        &.inner-border-grey {
            box-shadow: inset 0 0 0 1px #d8d8d8;
        }
    }

    /* Filter buttons */
    .filter-buttons {
        margin: 20px 0 0 0;
        list-style: none;

        li {
            float: left;
            margin-right: 10px;
            list-style: none;

            a {
                @extend .button;

                background: $colour-mako;

                @include borderRadius(15px);

                padding: 4px 15px;

                &.highlight {
                    background: $colour-green-persian;
                }
            }
        }
    }

    /* Pill buttons */
    .pill-buttons {
        margin: 0;

        li {
            list-style: none;
            float: left;
            margin: 0;
            margin-right: 10px;

            a {
                background: $colour-gray;
                padding: 3px 4px 3px 15px;

                @include borderRadius(15px);

                display: block;
                clear: both;
                overflow: hidden;
                color: $colour-gray-dark;
                cursor: text;

                span {
                    float: left;
                    display: block;
                    padding: 0 10px 0 0;
                }

                i {
                    float: left;
                    text-align: center;
                    position: relative;

                    @include circleButtonInner($colour-white, 1.429rem, 1.429rem);

                    b {
                        position: absolute;

                        @include innerCircleButtonWithText($colour-green-persian, 1.143rem, 1.143rem, 2px, $colour-white);
                    }
                }
            }

            a[ng-click], a[href] {
                cursor: pointer;
            }

            &.type-pill {
                a {
                    padding: 3px 6px 3px 12px;
                }

                span {
                    font-weight: normal;
                    padding-right: 5px;
                    font-size: 0.857rem;
                    line-height: 1.5rem;
                }

                i {
                    color: $colour-white;
                    border: 1px solid $colour-white;
                    background-color: $colour-gray !important;

                    &:before {
                        @extend .icon-cross, :before;

                        font-size: 0.714rem;
                        display: block;
                        line-height: 1.429rem;
                        width: 1.429rem;
                        height: 1.429rem;
                        text-align: center;
                    }

                    &.contain-elements {
                        &:before {
                            @extend .icon-tick, :before;

                            font-size: 1em;
                        }

                        background-color: $colour-fern-2 !important;
                    }
                }
            }
        }
    }

    [ui-sref] {
        cursor: pointer;
    }

    button[type="submit"],
    input[type="submit"] {
        border: none;
        color: $colour-white;
        cursor: pointer;
        font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;

        @include borderRadius(4px);

        &.secondary-button {
            background: $colour-tundora;
        }

        &.search {
            @include borderRadius(0 1.571rem 1.571rem 0);

            float: none;
            margin: 0 0 0 -5px;
            padding: 10px 14px 10px 10px;

            &.margin-right {
                margin: 0 5% 0 0;
            }
        }
    }

    button[type="submit"] {
        .icon {
            margin-right: 3px;
        }
    }

    .button-group {
        position: relative;
        display: inline-block;
        vertical-align: middle;

        & > .button {
            position: relative;
            float: left;
            margin-bottom: 0;
        }

        & > .button:hover,
        & > .button:focus,
        & > .button:active,
        & > .button.active {
            z-index: 2;
        }

        & > .button:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        & > .button:first-child {
            margin-left: 0;
        }

        & > .button:first-child:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & > .button:last-child:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .delete-asset {
        float: left !important;
        padding: 2px 4px !important;
        line-height: 1.5rem !important;
        margin-left: -30px !important;
        width: auto !important;
    }

    .info-link {
        padding: 10px 23px;
        display: inline-block;
        border-radius: 30px;
        border: 1px solid #E0E0E0;
    }
}
