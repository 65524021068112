/**
 * Box Model Helpers
 *
 * .vertical-padding
 * .vertical-padding-top
 * .vertical-padding-small-top
 * .horizontal-padding
 * .horizontal-padding-bottom
 * .horizontal-padding-large-bottom
 * .vertical-margin
 * .no-padding
 * .no-margin-top
 *
 * ....etc
 */
.vertical-padding {
    padding-top: $mediumPad !important;
    padding-bottom: $mediumPad !important;

    &-top {
        padding-top: $mediumPad !important;
    }

    &-bottom {
        padding-bottom: $mediumPad !important;
    }

    &-small {
        padding-top: $smallPad !important;
        padding-bottom: $smallPad !important;

        &-top {
            padding-top: $smallPad !important;
        }

        &-bottom {
            padding-bottom: $smallPad !important;
        }
    }

    &-large {
        padding-top: $largePad !important;
        padding-bottom: $largePad !important;

        &-top {
            padding-top: $largePad !important;
        }

        &-bottom {
            padding-bottom: $largePad !important;
        }
    }
}

.horizontal-padding {
    padding-left: $mediumPad !important;
    padding-right: $mediumPad !important;

    &-left {
        padding-left: $mediumPad !important;
    }

    &-right {
        padding-right: $mediumPad !important;
    }

    &-small {
        padding-left: $smallPad !important;
        padding-right: $smallPad !important;

        &-left {
            padding-left: $smallPad !important;
        }

        &-right {
            padding-right: $smallPad !important;
        }
    }

    &-large {
        padding-left: $largePad !important;
        padding-right: $largePad !important;

        &-left {
            padding-left: $largePad !important;
        }

        &-right {
            padding-right: $largePad !important;
        }
    }
}

.no-padding {
    padding: 0 !important;

    &-top {
        padding-top: 0 !important;
    }

    &-bottom {
        padding-bottom: 0 !important;
    }

    &-left {
        padding-left: 0 !important;
    }

    &-right {
        padding-right: 0 !important;
    }
}

.vertical-margin {
    margin-top: $mediumPad !important;
    margin-bottom: $mediumPad !important;

    &-top {
        margin-top: $mediumPad !important;
    }

    &-bottom {
        margin-bottom: $mediumPad !important;
    }

    &-small {
        margin-top: $smallPad !important;
        margin-bottom: $smallPad !important;

        &-top {
            margin-top: $smallPad !important;
        }

        &-bottom {
            margin-bottom: $smallPad !important;
        }
    }

    &-xsmall {
        margin-top: $xSmallPad !important;
        margin-bottom: $xSmallPad !important;

        &-top {
            margin-top: $xSmallPad !important;
        }

        &-bottom {
            margin-bottom: $xSmallPad !important;
        }
    }


    &-large {
        margin-top: $largePad !important;
        margin-bottom: $largePad !important;

        &-top {
            margin-top: $largePad !important;
        }

        &-bottom {
            margin-bottom: $largePad !important;
        }
    }
}

.horizontal-margin {
    margin-left: $mediumPad !important;
    margin-right: $mediumPad !important;

    &-left {
        margin-left: $mediumPad !important;
    }

    &-right {
        margin-right: $mediumPad !important;
    }

    &-small {
        margin-left: $smallPad !important;
        margin-right: $smallPad !important;

        &-left {
            margin-left: $smallPad !important;
        }

        &-right {
            margin-right: $smallPad !important;
        }
    }

    &-xsmall {
        margin-left: $xSmallPad !important;
        margin-right: $xSmallPad !important;

        &-left {
            margin-left: $xSmallPad !important;
        }

        &-right {
            margin-right: $xSmallPad !important;
        }
    }

    &-large {
        margin-left: $largePad !important;
        margin-right: $largePad !important;

        &-left {
            margin-left: $largePad !important;
        }

        &-right {
            margin-right: $largePad !important;
        }
    }

    &-xlarge {
        margin-left: $xLargePad !important;
        margin-right: $xLargePad !important;

        &-left {
            margin-left: $xLargePad !important;
        }

        &-right {
            margin-right: $xLargePad !important;
        }
    }
}

.no-margin {
    margin: 0 !important;

    &-top {
        margin-top: 0 !important;
    }

    &-bottom {
        margin-bottom: 0 !important;
    }

    &-left {
        margin-left: 0 !important;
    }

    &-right {
        margin-right: 0 !important;
    }
}

/**
 * Alignment helpers
 *
 * .align-left
 * .align-right
 * .pull-left
 *
 * ....etc
 */
.align {
    &-left {
        text-align: left !important;
    }

    &-right {
        text-align: right !important;
    }

    &-center {
        text-align: center !important;
    }
}

.pull {
    &-left {
        float: left !important;
    }

    &-right {
        float: right !important;
    }
}

.no-float {
    float: none !important;
}

/**
 * Border
 */
.border-all {
  border: 1px solid #d8d8d8;
}

/**
 * Display helpers
 *
 * .hide
 * .show
 * .invisible
 * .visible
 *
 * .....etc
 */
.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden !important;
}

.visible {
    visibility: visible !important;
}

/**
 * Background helpers
 *
 * .background-dark
 * .background-light
 * .background-white
 *
 * ....etc
 */
.background {
    &-dark {
        background: $colour-black !important;
    }

    &-light {
        background: $colour-concrete !important;
    }

    &-white {
        background: $colour-white !important;
    }

    &-transparent {
        background: $colour-transparent !important;
    }
}

/**
 * Text helpers
 *
 * .text-underline
 * .text-bold
 * .text-italic
 *
 * ....etc
 */
.text {
    &-underline {
        text-decoration: underline;
    }

    &-bold {
        font-weight: bold;
    }

    &-bold-important {
        font-weight: bold !important;
    }

    &-no-bold {
        font-weight: normal;
    }

    &-hide {
        font: 0/0 a;
        color: $colour-transparent;
        text-shadow: none;
        background-color: $colour-transparent;
        border: 0;
    }

    &-italic {
        font-style: italic;
    }
}

/**
 * Clearfix helper
 */
.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.horizontal-margin-no-left {
    margin-left: 0 !important;
}

.dark-heading {
    background: $colour-gray !important;
}

.no-border {
    border: none !important;
}

.no-border-top {
    border-top: none !important;
}

.no-border-bottom {
    border-bottom: none !important;
}

.flex-start-center {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}
