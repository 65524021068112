/**
 * Page header styles
 */
#pmContent {
    header#globalHeader {
        #topHav {
            background-color: #f4f5f7;
            .container {
                display: block;
                padding: 0;
                color: $colour-white;

                .dropdown.dropdown-top {
                    position: relative;
                    margin-top: 0;
                    float: right;

                    .dropdown-top-element {
                        margin-left: 0;
                        margin-right: -1px;

                        &.dropdown-account {
                            margin-right: 0;
                        }
                    }

                    .button {
                        border-radius: 0;
                        background: transparent;
                        box-shadow: unset !important;
                        border: 1px solid #c1c4d2;
                        border-bottom: 0;
                        border-top: 0;
                        margin: 0 !important;
                        font-weight: bold;

                        &.sso-back {
                            border-right: 0;
                        }

                        span {
                            box-shadow: unset !important;
                            display: inline-block;

                            &:first-letter {
                                text-transform: capitalize;
                            }
                        }
                    }

                    ul.dropdown-menu {
                        margin: 0;
                        border-radius: 0;
                        right: 0;
                        min-width: calc(100% - 2px);
                        box-shadow: 0 2px 4px 0 rgba(204, 204, 204, 0.5);
                        border: 1px solid #c1c4d2;

                        & > li > a {
                            padding: 3px 10px 0 10px !important;

                            &:first-letter {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }

        #globalLogo {
            margin: auto;
            min-height: 130px;

            .dashboard-logo {
                display: block;
            }

            #logoWrapper {
                display: table;
                width: 100%;
                text-align: center;
                margin: 65px 0 25px 0;
                float: left;

                a {
                    display: table-cell;
                    vertical-align: middle;
                    height: 100%;
                }
            }

            a {
                display: table-cell;
                vertical-align: middle;
                height: 130px;

                &.sage-logo-link {
                    vertical-align: top;
                    padding-top: 30px;
                    width: 115px;
                    box-sizing: border-box;
                }
            }
        }

        #topBar {
            background-color: $colour-ebony-clay;
            .container {
                display: flex;
                align-items: center;
                padding: 9px 0;
                color: $colour-white;

                .left {
                    .stack {
                        margin-right: 13px;
                    }
                }
                .right {
                    margin-left: auto;
                }

                .button {
                    margin: 0;
                }
            }
        }

        h1 {
            height: 130px;

            a {
                background: $colour-transparent url("/images/partnermarketing.png") no-repeat center left;
                line-height: 130px;
                width: 228px;
                text-indent: -999999px;
                overflow: hidden;
                display: inline-block;
            }
        }

        .basket {
            text-decoration: none;
            text-align: center;
            position: relative;
            display: inline-block;
            margin: 2px 0 0 10px;

            .icon {
                font-size: 2.214rem;
                display: inline-block;
            }

            .number {
                color: black;
                position: absolute;
                bottom: 1px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }

    /**
     * Main content area header
     */
    .content-header {
        clear: both;
        overflow: hidden;
        border-bottom: 1px solid $colour-gray;
        margin: 40px 0;

        h1 {
            margin: 0;
            height: 3.929rem;
            line-height: 3.929rem;
        }

        h2 {
            color: $colour-gray-dark;
            font-size: 1.643rem;
        }

        form, a {
            margin-bottom: 8px;
        }

        .button {
            margin-top: 3px;
            margin-left: 15px;
            &.mini {
                margin-top: 15px;
            }
        }

        a.delete-link {
            line-height: 2.714rem;
        }

        a.rename-link {
            font-size: 0.857rem;
            line-height: 3.929rem;
            color: $colour-gray-dark;
            padding-left: 30px;
        }
    }

    .content-underheader {
        margin: -20px 0 20px;
        &.no-margin-bottom {
            margin-bottom: 0;
        }
    }

    .client-navigation-colour-background {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
}
