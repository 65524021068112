[ui-view="tour"] {
    display: block;
    height: 0;
    overflow: hidden;
    pm-tour-step {
        position: absolute;
        z-index: 100;
        display: none;
        opacity: 0;
        transition: opacity .6s ease-in-out;
        -moz-transition: opacity .6s ease-in-out;
        -webkit-transition: opacity .6s ease-in-out;
    }
}

// These opacity statements are used with the tour.
.position {
    &-relative {
        position: relative !important;
    }
}
