#pmContent {
    .pm-date-input,
    pm-date-range {
        display: block;

        input {
            margin-left: 10px;
        }

        .icon {
            &.icon-calendar {
                position: relative;
                top: 4px;
                margin: 0 18px 0 -33px;
            }
        }

        ul.dropdown-menu {
            padding: 20px;
            margin: 0;

            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            li[ng-if="showButtonBar"] {
                padding: 15px 0;
            }

            button {
                padding: 10px;
                border: none;
                color: $colour-white;
                width: 100%;
            }

            .button-bar-left {
                float: left;
                width: 48%;
            }

            .button-bar-right {
                float: right;
                width: 48%;
            }
        }

        div[datepicker] {
            max-width: 390px;
        }
    }
}