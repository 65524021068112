#pmContent {
    form.list-search-widget-with-button {
        float: right;

        input {
            outline: none;
        }

        input[type="text"] {
            background: url(/images/icons/icon-search.png) no-repeat 12px 12px $colour-alabaster-2;
            padding: 10px 10px 10px 37px;
            width: 250px;
            margin-right: 0;

            @include borderRadius(1.571rem 0 0 1.571rem);

            margin: 0;
            line-height: 1.5rem;
            background-position: 12px 10px !important;
            min-height: 41px;
            padding-top: 9px;
            padding-bottom: 9px;
        }

        input[type="text"]:focus {
            border-color: $colour-mariner;
        }

        input[type="submit"] {
            border: none;
            color: $colour-white;
            font-size: 1rem;
            cursor: pointer;
            font: 14px/1 Arial, "Helvetica Neue", Helvetica, sans-serif;

            @include borderRadius(0 1.571rem 1.571rem 0);

            margin: 0 0 0 -5px;
            padding: 12px 14px 12px 10px;
            min-height: 41px;
        }
    }

    form.list-search-widget-without-button {
        float: right;

        input[type="text"] {
            outline: none;
            background: url(/images/icons/icon-search.png) no-repeat 12px 12px $colour-alabaster-2;
            padding: 10px 10px 10px 37px;
            width: 300px;
            margin-right: 0;

            @include borderRadius(1.571rem);

            margin: 0;
        }

        input[type="text"]:focus {
            border-color: $colour-mariner;
        }

        &.small input[type="text"] {
            background: url(/images/icons/icon-search.png) no-repeat 12px 12px $colour-alabaster-2;
            padding: 10px 10px 10px 37px;
            width: 250px;
            margin-right: 0;

            @include borderRadius(1.571rem);

            padding-top: 4px;
            padding-bottom: 4px;
            background-position: 12px 5px;
        }
    }
}
