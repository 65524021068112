/**
* Stylesheet created to override global styles with ones specially for the
* notification part of the site, hopefully will be fairly small!
*/

#pmContent {
  .notification-form {
    h2 {
      font-size: 1.143rem;
      font-weight: bold;
      margin: 0 0 20px 0;
    }

    input[type="radio"] {
      margin-top: 0;
    }

    .float-input {
      input[type="radio"] {
        margin-top: 2px;
      }
    }

    label.long-label {
      font-size: 1rem;
      font-weight: bold !important;
    }

    select.short {
      width: 60px !important;
    }

    hr {
      margin: 5px 0 30px 0px;
      border-top: 2px dotted #cccccc;
    }

    .time-and-date {
      .input {
        padding-bottom: 25px;

        label {
          display: block;
          width: 100%;
          padding: 0 0 5px;
        }

        &.time select {
          width: 90px;
          margin: 0 3% 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    custom-language-notification-form {
      display: block;

      .top-line {
        height: 3.571rem;
        background-color: $colour-concrete;
        border: 1px solid $colour-gray;
        box-sizing: border-box;

        &.disabled {
          opacity: 0.5;
        }

        h4 {
          float: left !important;
          margin: 0 0 0 15px !important;
          line-height: 3.571rem !important;
        }

        .actions {
          float: right;

          a.remove {
            margin: 15px;
          }

          a.stack.arrow-down {
            display: inline-block;
            height: 3.571rem;
            width: 3.571rem;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            margin: -1px -1px 0 0;

            .icon {
              top: 14px;
            }
          }
        }
      }

      .top-line.closed {
        margin-bottom: 15px;
      }

      .form-content {
        padding: 20px 30px;
        border: 1px solid $colour-gray;
        border-top: none;
        margin-bottom: 15px;

        .button-link {
          margin-bottom: 5px;
          padding: 0 10px 10px;
          background-color: $colour-concrete;
          div {
            display: block;
            position: relative;
            padding-right: 20px;
          }
          label {
            float: none;
          }
          input {
            float: none;
          }
        }

        .small-height textarea {
          height: 45px;
        }
      }
    }
  }
}
