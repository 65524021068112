#pmContent {
    img {
        &.rounded {
            border-radius: 50%;
        }

        &.contain {
            min-width: 100%;
            max-width: 100%;
            height: auto;
        }

        &.identifier-preview-colours, &.identifier-preview-black {
            margin: 15px 0 5px;
            box-sizing: border-box;
            display: block;
            background: $colour-white;

            &.identifier-preview-black {
                background: $colour-black;
            }
        }
    }
}

img.forced-width {
    min-width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
}