#pmContent {
    /*
    To change this license header, choose License Headers in Project Properties.
    To change this template file, choose Tools | Templates
    and open the template in the editor.
    */
    /*
        Created on : 13-Jan-2014, 14:40:54
        Author     : Seb Dangerfield <seb.dangerfield@wearetwogether.com>
    */

    // Include the bootstrap base styling for this component

    .dropdown.pull-right .dropdown-menu {
        right: 0;
        left: inherit;
    }

    .dropdown li {
        list-style: none;
    }

    .dropdown-toggle {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        white-space: nowrap;
    }

    .dropdown-menu > li > a {
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15 ease-in-out;
        text-decoration: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        font-weight: normal;
    }

    .dropdown-menu > li > a:hover {
        color: $colour-white;
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15 ease-in-out;
    }

    .dropdown.with-line > a.dropdown-toggle.button.small {
        position: relative;
        z-index: 1;

        > span {
            margin: -6px 21px -6px -11px;
            padding: 6px 10px 6px 11px;
            display: block;
            border-right: 1px solid $colour-white;

            @include borderRadiusPartial(0, 0, 3px, 3px);
        }

        > i.icon {
            padding: 7px 0 5px 10px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1.571rem;

            @include borderRadiusPartial(3px, 3px, 0, 0);
        }
    }

    .dropdown.dropdown-top {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -100px;

        ul {
            text-align: right;

            &.dropdown-menu {
                min-width: auto;
                left: auto;
                right: 0;

                > li > a {
                    background-color: $colour-white !important;
                    color: $colour-tundora;
                }

                > li > a:hover {
                    background-color: #ededed !important;
                }
            }
        }

        .dropdown-top-element {
            display: inline-block;
            position: relative;
            float: left;
            margin-left: 10px;
        }
    }
}
