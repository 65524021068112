#pmContent {
    /**
     * Page footer styles
     */
    footer {
        &:not(.no-margin) {
            margin-top: 40px;
        }

        border-top: 3px solid $colour-gray;
        padding: 30px 0 15px;
        background: $colour-concrete;

        #globalFooter {
            margin: 0 auto;
        }

        h4 {
            margin: 0 0 4px 0;
            font-size: 0.929rem;
            font-weight: bold;
            clear: right;
        }

        .right-column-item {
            clear: right;
            color: $colour-gray-2;

            a {
                font-size: 0.929rem;
                text-decoration: none;
                color: $colour-gray-2;
            }
        }

        p {
            font-size: 0.857rem;
            color: $colour-gray-2;
            vertical-align: middle;
            line-height: 1.714rem;
            margin: 0;

            span {
                background: $colour-transparent url("/images/logos/footer-logo.png") no-repeat 0 0;
                margin-left: 3px;
                width: 148px;
                height: 24px;
                vertical-align: middle;
                text-indent: -999999px;
                overflow: hidden;
                display: inline-block;
                position: relative;
                bottom: 2px;
            }

            &.underline {
                padding-left: 2px;
                display: block;
                font-size: 11px;
                line-height: 1;
            }
        }

        ul {
            float: right;
            margin: 0;
            clear: right;

            li {
                float: left;
                font-size: 0.929rem;
                color: $colour-gray-2;
                padding: 0 10px;
                border-right: 1px solid $colour-gray-2;
                list-style: none;

                a {
                    color: $colour-gray-2;
                    text-decoration: none;
                }

                &.last {
                    border: none;
                    padding-right: 0;
                }
            }
        }

        &.sage-advance {
            h4 {
              color: #ffffff;
              font-size: 16px;
              margin-bottom: 15px;
            }

            p span {
                background: $colour-transparent url("/images/logos/footer-logo-pm.png") no-repeat 0 0;
                background-size: contain;
                width: 225px;
                height: 50px;
            }

            p,
            ul li,
            ul li a {
                color: #ffffff;
                font-size: 11px;
            }

            ul li {
                padding: 0 0 0 25px;
                border: none;
            }
        }
    }

    /**
     * Main content area footer
     */
    .content-footer {
        padding: 30px 0 0 0;
        overflow: hidden;
        clear: both;
        border-top: 1px solid $colour-gray;
        margin: 30px 0 0 0;
    }

    /**
     * The notification message above the content area footer
     */
    .content-footer-notice {
        h2 {
            color: $colour-fern;
            margin-top: 0;
        }

        padding: 30px 0 0 0;
        overflow: hidden;
        clear: both;
        border-top: 1px solid $colour-gray;
        margin: 30px 0 0 0;
        text-align: center;
    }
}
