// Constructors
%box {
    padding: 15px;
    margin-bottom: 30px;
}

%arrow-box {
    position: relative;
    margin-right: 25px;
    min-height: 150px;
    display: table;
    width: 31.82% !important;
    @extend .clearfix;
}

.list-box {
    .box-header {
        background: $colour-concrete;
        padding: 15px;
        margin: 0;

        .button {
            margin-bottom: 0 !important;
        }
    }

    .button {
        margin-bottom: 0 !important;
        display: inline-block !important;
    }

    .toggle-dropdown {
        text-align: right;
        padding: 2px 15px;
        cursor: pointer;
        color: $colour-outer-space;
    }

    ul {
        display: block;
        border: 1px solid $colour-gray;
        border-top: none;
        padding: 15px;
        margin: 0;

        li {
            list-style: none;

            a {
                display: block;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    & + .list-box {
        margin-top: 20px;
    }

    &.error-box {
        .box-header {
            background: $colour-outer-space;
            color: $colour-gray;
        }

        ul li {
            list-style: disc;
            margin-left: 15px;
        }
    }

    &.margin-top {
        margin-top: 20px;
    }

    &.margin-bottom {
        margin-bottom: 20px;
    }
}

.square-box {
    background: $colour-concrete;
    border: 1px solid $colour-gray-light;
    padding: 10px;

    ul {
        list-style: disc inside none;

        li {
            padding: 3px 0;
        }
    }
}

.box-icon {
    padding-right: 10px;
}

.box-button {
    position: relative;
    bottom: 2px;
}

.rounded-box {
    background: $colour-concrete;
    border: 1px solid $colour-gray-light;

    @include borderRadius(8px);

    padding: 10px;

    .loading-container .loading {
        background: $colour-concrete;
    }

    .response-error-text br {
        height: 8px;
        display: block;
        content: ' ';
    }

    ul {
        list-style: disc inside none;

        li {
            padding: 3px 0;
        }
    }
}

.info-box {
    padding: 15px;
    padding-left: 50px;

    i {
        position: absolute;
        left: 15px;
        top: 10px;
    }
}

.info-col {
    .info-col-header {
        border: 1px solid #E0E0E0;
        min-height: 50px;
        line-height: 3.571rem;
        vertical-align: middle;
        padding: 10px;
    }

    .info-col-body {
        border: solid #E0E0E0;
        border-width: 0 1px 1px 1px;
        padding: 10px;
    }
}

#pmContent {
    // Selectors
    .box {
        @extend %box;

        &-grey {
            @extend %box;

            background-color: $colour-concrete;
        }

        &-steps {
            @extend %box;

            > h4 > span {
                display: inline-block;
                color: $colour-white;
                margin-right: 10px;
                border-radius: 20px;
                padding: 0px 10px;
                line-height: 1.643rem;
            }

            margin-top: 30px;
            margin-bottom: 0;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .box-content {
            background: $colour-white;
            padding: 7px;
            margin: 15px 0;
        }

        h4 {
            font-size: 0.929rem;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .arrow-boxes {
        margin: 5px auto;

        .arrow-box {
            @extend %arrow-box;

            transform-origin: 50% 50%;
            transform: scale(0);
            -webkit-animation-iteration-count: 1;
            -webkit-animation: growIn 0.4s forwards;
            animation-iteration-count: 1;
            animation: growIn 0.4s forwards;

            @for $item from 2 through 40 {
                &:nth-child(#{$item}) {
                    -webkit-animation-delay: #{0.1 * $item}s;
                    animation-delay: #{0.1 * $item}s;
                }
            }

            > div {
                height: 150px;
                padding: 0;

                @extend .clearfix;
            }

            &:before, &:after {
                border-style: solid;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                top: 0;
                z-index: 10;
            }

            &:after {
                border-width: 75px 0 75px 33.75px;
                right: 0;
                z-index: 11;
            }

            h3 {
                color: $colour-white;
                background: 0 !important;
                border: 0 !important;
                padding: 0 !important;
                margin: 0 !important;
                width: 100%;
                font-size: 0.929rem;
            }

            img {
                display: inline-block;
                margin-bottom: 10px;
            }

            > div {
                text-align: center;
                vertical-align: center;
                display: table-cell;
                vertical-align: middle;
                margin-left: -20px;
                margin-right: -20px;
            }

            &:nth-child(1) {
                &:before {
                    display: none;
                }

                &:after {
                    border-right-color: #fff !important;
                    border-top-color: #fff !important;
                    border-bottom-color: #fff !important;
                }
            }

            &:nth-child(2) {
                > * {
                    padding-left: 22.5px !important;
                    padding-right: 22.5px !important;
                }

                &:after {
                    border-right-color: #fff !important;
                    border-top-color: #fff !important;
                    border-bottom-color: #fff !important;
                }

                &:before {
                    border-left-color: #ffffff !important;
                    border-width: 75px 0 75px 33.75px;
                    right: 100%;
                    left: 0;
                }
            }

            &:nth-child(3) {
                margin-right: 0;
                border-right: 0 !important;
                width: 31% !important;

                &:after {
                    content: none;
                }

                &:before {
                    border-left-color: #ffffff !important;
                    border-width: 75px 0 75px 33.75px;
                    right: 100%;
                    left: 0;
                }
            }
        }
    }

    .rounded-box {
        &.info-list {
            background-color: $colour-white;
            padding: 0;
            border-radius: 4px;

            h4 {
                padding: 15px;
                font-weight: bold;

                .stack {
                    vertical-align: middle;
                }

                &.success {
                    background-color: $colour-green-light;
                    color: $colour-green;
                }

                &.danger {
                    background-color: $colour-red-light;
                    color: $colour-red;
                }
            }

            .description {
                padding: 20px;
            }
        }
    }
}
