@import "globals/reset";
@import "globals/variables";
@import "globals/mixins";
@import 'partials/singletons/template';

#angular-root {
    @import '_map/globals'; // Global styles
    @import '_map/partials'; // Template styles
    @import '_map/directives'; // Directive styles
    @import 'partials/singletons/authentication';
    @import 'partials/singletons/assetLibrary';
    @import 'partials/singletons/campaign';
    @import 'partials/singletons/client';
    @import 'partials/singletons/company';
    @import 'partials/singletons/font';
    @import 'partials/singletons/lead';
    @import 'partials/singletons/region';
    @import 'partials/singletons/reporting';
    @import 'partials/singletons/styleguide';
    @import 'partials/singletons/tier';
    @import 'partials/singletons/user';
    @import 'partials/singletons/marketingStore';
    @import 'partials/singletons/identifier';
    @import 'partials/singletons/notification';
}
@import 'globals/top_level';
